import React, { Component } from "react";
import { Navbar, NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";

export default class Header extends Component {
  changeScreen(op) {
    this.props.option(op);
  }

  render() {
    return (
      <>
        <Navbar fluid>
          <Navbar.Header>
            <Navbar.Brand>
              <a href="#pablo" style={{textTransform: 'uppercase'}}>Relatório Dimensional</a>
            </Navbar.Brand>
            <Navbar.Toggle onClick={this.mobileSidebarToggle} />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <NavDropdown
                eventKey={2}
                title="Ações"
                id="basic-nav-dropdown-right"
              >
                <MenuItem onClick={() => this.changeScreen('LIST')} eventKey={2.1}>
                  Consultar Relatórios
                </MenuItem>
                <MenuItem onClick={() => this.changeScreen('EDIT')} eventKey={2.1}>
                  Editar
                </MenuItem>
                <MenuItem onClick={this.props.onEnableUnion} eventKey={2.1}>
                  Mesclar Relatório
                </MenuItem>
              </NavDropdown>
              <NavItem eventKey={3} href="#" onClick={() => this.sair()}>
                Sair
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
