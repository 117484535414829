import { css } from '@emotion/core';
import BarChart from 'components/Grafico_Comparativo/barChart';
import EditarRecuperacao from 'components/Modais/EditarRecuperacao';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
    CSVExport, Search
} from 'react-bootstrap-table2-toolkit';
import { BarLoader } from 'react-spinners';
import { Progress } from 'reactstrap';
import { recuperacaoService } from 'services/recuperacao';
import api from '../../services/api';
import ModalGraficoRelatorio from '../Relatorio/Modais/ModalGraficoRelatorio';
import './dashboard.css';
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;


const arrayEtapas = [
    "Soldar Ponteira",
    "Alinhamento",
    "Usinagem Desbaste",
    "Solda Almofada",
    "Usinagem Almofada",
    "Solda Revestimento",
    "Usinagem Revestimento",
    "Lixamento",
    "Desbaste Lateral Filetes",
    "Polimento",
    "Usinagem Final",
    "Retrabalho"
];

class Dashboard extends Component {
    state = {
        checkedA: true,
        checkedB: false,
        //Define como está organizada funcionalmente a tabela "Recuperação"
        arrayConsultarRecuperacao: '',
        //Mostra as etapas da OS
        arrayConsultarRecuperacaoEtapas: '',
        load: false,
        tempos: 0,
        idsRecuperacoes: [],
        arrayDataPre: [],
        arrayDataPos: [],
        tempoTotalPos: '',
        temposPre: [],
        temposPos: [],
        temposPreRoscaDupla: [],
        temposPosRoscaDupla: [],
        temposPosFiltrado: '',
        loadTempos: false,
        loadArray: false,
        id_cadastro: null,
        showModalDados: false,
        modalCompare: false,
        arrayChartComparativo: [],
        id_recuperacao_editar: '',
        showModalEditar: false,
        arrayEditar: '',
        idsRoscaDupla: [],
        arrayDataPreRoscaDupla: [],
        arrayDataPosRoscaDupla: [],
        arrayRoscaDuplaRecuperacao: [],
        idsLadoEsquerdo: [],
        tipoRosca: null,
        idsRoscaDuplaFinalizada: [],
        idsLadoEsquerdoComOS: [],
        idsLadoDireitoComOS: []
    }

    async pegarIds(recuperacoes) {
        recuperacoes.map(recup => {
            if (recup.tipo !== "Rosca Dupla")
                this.setState({ idsRecuperacoes: [...this.state.idsRecuperacoes, recup.idrecuperacao] })
        })

    }
    //Estrutura para executar o metodo de consultas de OS
    async consultarRecuperacao() {
        const responseRecuperacaoEmAndamaneto = await api.get('/get/consultarRecuperacaoStatus1');
        let recuperacaoAndamentoGroup = responseRecuperacaoEmAndamaneto.data;
        
        //Função para verificação e filtragem de OS finalizada no dashboard
        recuperacaoAndamentoGroup.forEach(async (item, index) => {
            const { data } = await api.post('/posts/consultarRecuperacaoOS', { ordem_servico: item.ordem_servico });

            if (data.length > 1) {
                let roscaDupla = data.filter(item => item.status_fim_etapa === 1);

                if (roscaDupla.length === 1) {
                    this.setState({
                        idsRoscaDuplaFinalizada: [...this.state.idsRoscaDuplaFinalizada, {
                            idrecuperacao: roscaDupla[0].idrecuperacao,
                            lado: roscaDupla[0].lado
                        }
                        ]
                    })
                }
            }

        })

        // trás somente todas as OS que não estão concluídas
        const responseRecuperacao = await api.get('/get/consultarRecuperacaoDashboard'); // trás tudo
        this.pegarIds(responseRecuperacao.data);
        const recuperacaoTipoMono = responseRecuperacao.data.filter(item => item.tipo !== 'Rosca Dupla');

        recuperacaoAndamentoGroup = recuperacaoAndamentoGroup.filter(item =>
            item.tipo !== 'Mono Rosca' && item.tipo === 'Rosca Dupla');

        let idsRoscaDupla = [];
        let statusFimEtapa = [];

        recuperacaoAndamentoGroup.forEach((el, index) => {
            responseRecuperacao.data.forEach(item => {
                if (item.ordem_servico === el.ordem_servico && item.lado === 'Direito') {
                    idsRoscaDupla.push(item.idrecuperacao);
                    this.setState({ idsLadoDireitoComOS: [...this.state.idsLadoDireitoComOS, { id: item.idrecuperacao, ordem_servico: item.ordem_servico }] })
                    statusFimEtapa.push({ id: item.idrecuperacao, statusFimEtapa: item.status_fim_etapa });
                    recuperacaoAndamentoGroup[index] = { ...recuperacaoAndamentoGroup[index], statusFimEtapa: statusFimEtapa };
                }
                if ((item.ordem_servico === el.ordem_servico && item.lado === 'Esquerdo' && item.tipo === 'Mono Rosca')) {
                    this.setState(
                        {
                            idsRecuperacoes: [...this.state.idsRecuperacoes, item.idrecuperacao],
                            idsLadoEsquerdoComOS: [...this.state.idsLadoEsquerdoComOS, { id: item.idrecuperacao, ordem_servico: item.ordem_servico }]
                        }
                    );
                }
                if (item.ordem_servico === el.ordem_servico && item.lado === 'Esquerdo' && item.tipo === 'Rosca Dupla') {
                    this.setState({
                        idsLadoEsquerdo: [...this.state.idsLadoEsquerdo, item.idrecuperacao],
                        idsLadoEsquerdoComOS: [...this.state.idsLadoEsquerdoComOS, { id: item.idrecuperacao, ordem_servico: item.ordem_servico }]
                    }
                    );
                    this.setState({ idsLadoDireitoComOS: [...this.state.idsLadoDireitoComOS, { id: item.idrecuperacao, ordem_servico: item.ordem_servico }] })
                    idsRoscaDupla.push(item.idrecuperacao);
                    statusFimEtapa.push({ id: item.idrecuperacao, statusFimEtapa: item.status_fim_etapa });
                    recuperacaoAndamentoGroup[index] = { ...recuperacaoAndamentoGroup[index], statusFimEtapa: statusFimEtapa };
                }
            })
        });

        this.setState({ idsRoscaDupla: idsRoscaDupla, arrayRoscaDuplaRecuperacao: recuperacaoAndamentoGroup })
        if (responseRecuperacao.data != 0)
            this.setState({ arrayConsultarRecuperacao: recuperacaoTipoMono, load: true })
        else
            alert("Nenhum cadastro foi efetuado!");

    }


    async setarTempos(id, temposPre, temposPos) {
        await this.setState({ arrayDataPre: [...this.state.arrayDataPre, { id: id, tempos: temposPre }] });
        await this.setState({ arrayDataPos: [...this.state.arrayDataPos, { id: id, tempos: temposPos }] });
    }


    setarTemposRoscaDupla(id, temposPre, temposPos) {
        this.setState({ arrayDataPreRoscaDupla: [...this.state.arrayDataPreRoscaDupla, { id: id, tempos: temposPre }] });
        this.setState({ arrayDataPosRoscaDupla: [...this.state.arrayDataPosRoscaDupla, { id: id, tempos: temposPos }] });
    }

    async buscarTemposRoscaDupla() {
        await this.state.idsRoscaDupla.forEach(async (id) => {
            const tempoPre = await api.post('/recuperacao/buscarTemposPre', {
                idrecuperacao: id
            })

            const tempoPos = await api.post('/recuperacao/buscarTempoTotal', {
                idrecuperacao: id
            })


            this.setarTemposRoscaDupla(id, tempoPre.data, tempoPos.data);

        })
    }

    async buscarTempos() {
        let ids = [...new Set(this.state.idsRecuperacoes)];

        ids.map(async (id) => {
            const tempoPre = await api.post('/recuperacao/buscarTemposPre', {
                idrecuperacao: id
            })

            const tempoPos = await api.post('/recuperacao/buscarTempoTotal', {
                idrecuperacao: id
            });

            await this.setarTempos(id, tempoPre.data, tempoPos.data);

        })
    }

    async organizarTemposPreRoscaDupla() {

        var groupByTemposPre = this.groupBy(this.state.temposPreRoscaDupla, 'id');
        var arrayAuxTempoPreAux = [];
        this.state.idsRoscaDupla.forEach((id, index) => {
            if (groupByTemposPre[id]) {
                groupByTemposPre[id].forEach(tempo => {
                    var soma = 0;
                    if (tempo.id == id) {
                        arrayAuxTempoPreAux.push({ id: id, etapa: tempo.etapa, tempo: tempo.tempo })
                    }
                })
            }
        })

        var tempos = {};

        arrayAuxTempoPreAux.map(item => {

            if (!tempos.hasOwnProperty(item.id)) {
                tempos[item.id] = 0;
            }

            tempos[item.id] += parseFloat(item.tempo)
        })

        var convertArray = Object.keys(tempos).map(function (key) {
            return [{ id: Number(key), tempo: tempos[key] }];
        });

        await this.setState({ temposPreRoscaDupla: convertArray })
    }

    async organizarTemposPre() {
        var groupByTemposPre = this.groupBy(this.state.temposPre, 'id');

        var arrayAuxTempoPreAux = [];

        this.state.idsRecuperacoes.map((id, index) => {
            groupByTemposPre[id].map(tempo => {
                var soma = 0;
                if (tempo.id == id) {
                    arrayAuxTempoPreAux.push({ id: id, etapa: tempo.etapa, tempo: tempo.tempo })
                }
            })
        })

        var tempos = {};

        arrayAuxTempoPreAux.map(item => {

            if (!tempos.hasOwnProperty(item.id)) {
                tempos[item.id] = 0;
            }

            tempos[item.id] += parseFloat(item.tempo)
        })

        var convertArray = Object.keys(tempos).map(function (key) {
            return [{ id: Number(key), tempo: tempos[key] }];
        });

        await this.setState({ temposPre: convertArray })
    }

    async preencherGrafico() {
        this.organizarTemposPre();
        this.state.arrayDataPos.map(pos => {
            pos.tempos.forEach(obj => {
                for (let i = 0; i < 12; i++) {
                    if (obj[`t${i}`] != null)
                        this.preencherTempos(obj[`t${i}`], i, pos.id, 'pos', obj[`s${i}`])
                }
            })
        })
    }

    async preencherGraficoRoscaDupla() {
        this.organizarTemposPreRoscaDupla();
        this.state.arrayDataPosRoscaDupla.map(pos => {
            pos.tempos.forEach(obj => {
                for (let i = 0; i < 12; i++) {
                    if (obj[`t${i}`] != null)
                        this.preencherTemposRoscaDupla(obj[`t${i}`], i, pos.id, 'pos', obj[`s${i}`])
                }
            })
        })
    }

    groupBy(data, key) {
        var groups = {};
        for (var i in data) {
            if (!groups.hasOwnProperty(data[i][key])) groups[data[i][key]] = [];
            groups[data[i][key]].push(data[i]);
        }
        return groups;
    }

    groupByRoscaDupla(data, key) {
        var groups = {};
        for (var i in data) {
            if (!groups.hasOwnProperty(data[i][key])) groups[data[i][key]] = [];
            groups[data[i][key]].push(data[i]);
        }
        return groups;
    }

    somarTempos(tempos) {
        var tempoTotal = 0;
        if (tempos) {
            tempos.map(tempo => {
                if (tempo.tempoPos !== "") {
                    tempoTotal += parseFloat(tempo.tempoPos)
                }
            })
        }
        return tempoTotal;

    }

    preencherTemposPre() {
        this.state.arrayDataPre.map(pre => {
            pre.tempos.forEach(obj => {
                for (let i = 0; i < 12; i++) {
                    if (obj[`t${i}`] != null)
                        this.preencherTempos(obj[`t${i}`], i, pre.id, 'pre')
                }
            })
        })
    }

    preencherTemposPreRoscaDupla() {
        this.state.arrayDataPreRoscaDupla.forEach(item => {
            item.tempos.forEach(obj => {
                for (let i = 0; i < 12; i++) {
                    if (obj[`t${i}`] != null)
                        this.preencherTemposRoscaDupla(obj[`t${i}`], i, item.id, 'pre')
                }
            })
        })
    }


    async componentDidMount() {
        var arrayRec = [];
        await this.consultarRecuperacao();

        if (this.state.idsRoscaDupla.length) {
            await this.buscarTemposRoscaDupla();
        }
        await this.buscarTempos();
        //Projeta o tempo limite para carregamento da função assíncrona do gráfico das OS
        setTimeout(async () => {
            await this.preencherTemposPre();
            await this.preencherGrafico();
            await this.preencherTemposPreRoscaDupla();
            await this.preencherGraficoRoscaDupla();
        }, 13500);

        setTimeout(async () => {
            var groupById = this.groupBy(this.state.temposPos, 'id');
            var groupByIdRoscaDupla = this.groupByRoscaDupla(this.state.temposPosRoscaDupla, 'id');
            await this.setState({ temposPos: groupById });
            await this.setState({ temposPosRoscaDupla: groupByIdRoscaDupla });

            this.state.arrayConsultarRecuperacao.map(recuperacoes => {
                arrayRec.push(recuperacoes);
            })

            let arrayRecuperacaoDupla = this.state.arrayRoscaDuplaRecuperacao;

            arrayRecuperacaoDupla.forEach(async (item, index) => {

                let verificarStatusRoscaDupla = item.idLadoDireito ? this.filtrarTempoPreRoscaDupla(this.state.temposPosRoscaDupla[item.idLadoDireito]) : null;
                let verificarStatusRoscaEsquerda = item.idLadoEsquerdo ? this.filtrarTempoPreRoscaDupla(this.state.temposPosRoscaDupla[item.idLadoEsquerdo]) : null;
                let etapasEsquerda = null;
                if (item.idLadoEsquerdo) {
                    etapasEsquerda = { etapas: verificarStatusRoscaEsquerda };
                    Object.assign(arrayRecuperacaoDupla[index], etapasEsquerda);
                }
                if (item.idLadoDireito) {
                    let etapasDireita = { etapasDupla: verificarStatusRoscaDupla }
                    Object.assign(arrayRecuperacaoDupla[index], etapasDireita);
                }
            })

            for (let i = 0; i < arrayRec.length; i++) {

                let verificarStatus = await this.filtrarTempoPre(this.state.temposPos[this.state.idsRecuperacoes[i]]);

                let embarcar = { etapas: verificarStatus };
                let somarTempos = { tempoTotal: this.somarTempos(this.state.temposPos[this.state.idsRecuperacoes[i]]) };
                Object.assign(arrayRec[i], embarcar);
                Object.assign(arrayRec[i], somarTempos);
            }

            let arrayConcat = arrayRec.concat(arrayRecuperacaoDupla);
            let newArray = [];
            newArray = await recuperacaoService.montarTemplateTipo(arrayConcat);

            console.log(newArray, 'newArray')
            const sortedRec = newArray.sort(function (a, b) {
                var aa = b.date.split('/').reverse().join(),
                    bb = a.date.split('/').reverse().join();
                return aa < bb ? -1 : (aa > bb ? 1 : 0);
            });
            await this.setState({ arrayConsultarRecuperacaoEtapas: sortedRec, loadArray: true });
        }, 13500);

    }

    filtrarTempoPreRoscaDupla(tempoPos) {

        var arrayTempoPosNovo = [];
        const { arrayDataPreRoscaDupla, temposPreRoscaDupla } = this.state;

        if (tempoPos) {
            tempoPos.map(tempoPos => {
                arrayDataPreRoscaDupla.map(tempo => {
                    if (tempo.id == tempoPos.id) {
                        if (tempoPos.status === 1) {
                            temposPreRoscaDupla.map(tempoPre => {
                                if (tempoPre[0].id == tempoPos.id) {
                                    let inserir = { tempo: (parseFloat(tempo.tempos[0][`t${tempoPos.nEtapa}`]) * 100) / tempoPre[0].tempo };
                                    Object.assign(tempoPos, inserir);
                                    arrayTempoPosNovo.push(tempoPos);
                                }

                            })
                        }
                        else {
                            arrayTempoPosNovo.push(tempoPos);
                        }
                    }
                })
            })
        }
        return arrayTempoPosNovo
    }
    filtrarTempoPre(tempoPos) {
        var arrayTempoPosNovo = [];
        const { arrayDataPre, temposPre } = this.state;
        let inserir;

        if (tempoPos) {
            tempoPos.map(tempoPos => {
                arrayDataPre.map(tempo => {
                    if (tempo.id == tempoPos.id) {
                        if (tempoPos.status === 1) {
                            temposPre.map(tempoPre => {
                                if (tempoPre[0].id == tempoPos.id) {
                                    inserir = { tempo: (parseFloat(tempo.tempos[0][`t${tempoPos.nEtapa}`]) * 100) / tempoPre[0].tempo };
                                    Object.assign(tempoPos, inserir);
                                    arrayTempoPosNovo.push(tempoPos);
                                }

                            })
                        }
                        else {
                            arrayTempoPosNovo.push(tempoPos);
                        }
                    }
                })
            })
        }
        return arrayTempoPosNovo
    }

    calcPercentual(tempo, id) {
        if (tempo == "")
            tempo = 0;
        var convertTempo = parseFloat(tempo);
        if (tempo != '') {
            this.state.temposPre.map(tempo => {
                if (tempo[0].id === id) {
                    let tempoPercentual = ((convertTempo * 100) / tempo[0].tempo);
                    return tempoPercentual
                }
            })
        }
    }

    async showRelatorio(id) {
        const r = await api.get(`/relatorio/buscarOs/${id}`);
        if (r.data != 0) {
            let id_cadastro = r.data[0].id_cadastro;
            this.setState({ id_cadastro: id_cadastro, showModalDados: true });
        }
    }

    async preencherTempos(tempo, pos, id, tipo, status) {
        if (tipo === 'pos') {
            for (let i = 0; i < 12; i++) {
                if (i == pos) {
                    this.state.temposPre.map(t => {
                        if (t[0].id === id) {
                            if (tempo == "")
                                tempo = 0;
                            this.setState({
                                temposPos: [...this.state.temposPos,
                                { id: id, etapa: arrayEtapas[i], status: parseInt(status), nEtapa: (i), tempo: ((parseFloat(tempo) * 100) / t[0].tempo) }]
                            })
                        }
                    })
                }
            }
        }


        if (tipo === 'pre') {
            for (let i = 0; i < 12; i++) {
                if (i == pos) {
                    if (this.state.idsLadoEsquerdo.includes(id)) {
                        this.setState({
                            temposPre: [...this.state.temposPre, { id: id, etapa: arrayEtapas[i], tempo: parseFloat(tempo.replace(',', '.').replace(' ', '')) / 2 }]
                        })
                    }
                    else {
                        this.setState({
                            temposPre: [...this.state.temposPre, { id: id, etapa: arrayEtapas[i], tempo: tempo }]
                        })
                    }
                }
            }
        }

    }

    async preencherTemposRoscaDupla(tempo, pos, id, tipo, status) {

        if (tipo === 'pos') {
            for (let i = 0; i < 12; i++) {
                if (i == pos) {
                    this.state.temposPreRoscaDupla.forEach(t => {
                        if (t[0].id === id) {
                            if (tempo == "")
                                tempo = 0;
                            this.setState({
                                temposPosRoscaDupla: [...this.state.temposPosRoscaDupla,
                                { id: id, etapa: arrayEtapas[i], status: parseInt(status), nEtapa: (i), tempo: ((parseFloat(tempo) * 100) / t[0].tempo) }]
                            })
                        }
                    })
                }
            }
        }


        if (tipo === 'pre') {
            for (let i = 0; i < 12; i++) {
                if (i == pos) {
                    this.setState({
                        temposPreRoscaDupla: [...this.state.temposPreRoscaDupla, { id: id, etapa: arrayEtapas[i], tempo: tempo }]
                    })
                }
            }
        }
    }


    async showModal(r, i) {
        if (i == 1)
            await this.setState({ show: true, idCadastro: r.id_cadastro });
        if (i == 2)
            await this.setState({ showModalDados: true, idCadastro: r.id_cadastro });
    }

    close = async (op) => {
        if (op == 1)
            await this.setState({ showModalDados: false });
        if (op == 2)
            await this.setState({ modalCompare: false });
        if (op == 3)
            await this.setState({ showModalDados: false });
    }

    async graficoComparativo(ordem_servico) {
        this.setState({ tipoRosca: null });
        const { data } = await api.post('/posts/consultarRecuperacaoOS', { ordem_servico });

        if (data.length === 1) {
            this.setState({
                modalCompare: true,
                arrayChartComparativo: data[0],
                showModalConsultaRecuperacao: false,
                id_recuperacao_editar: data[0].idrecuperacao,
                arrayEditar: data[0],
            })
        } else if (data.length === 2) {
            this.setState({
                arrayEditar: data[0],
                modalCompare: true,
                arrayRoscaDupla: data,
                arrayChartComparativo: data[0],
                showModalConsultaRecuperacao: false,
                id_recuperacao_editar: data[0].idrecuperacao,
                tipoRosca: 'dupla'
            })
        }
    }

    changeModal = () => {
        this.setState({ showModalEditar: !this.state.showModalEditar });
    }

    async editarRecuperacao(id) {
        const response = await api.post('/posts/consultarRecuperacao', { idrecuperacao: id });
        this.changeModal();
        this.setState({ arrayEditar: response.data[0], idEditar: id });
    }

    handleItemSelecionado = (itemSelecionado) => {
        this.setState({ arrayEditar: itemSelecionado })
    }
//Esta função renderiza todos os dados da API para uma tabela no dashboard chamada "Recuperação", caso as informações sejam true, a tag <Progress> será chamada, caso false, trará uma <div> mostrando um círculo (classe fa fa-check-circle) de check simbolizando que a OS está finalizada.
    render() {
        const botoes = (cell, row, rowIndex) => {

            if (this.state.loadArray && row.hasOwnProperty("etapas")) {
                if (row.etapas.length) {
                    return (
                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <Progress multi style={{ width: 200 }}>
                                {row.etapas.length > 0 ?
                                    <>
                                        {row.etapas.map((etapa, index) => 
                                            <Progress title={getTitleEtapa(etapa)} bar color={'etapa-'+index} value={etapa.tempo}>{parseInt(etapa.tempo)}%</Progress>
                                        )}
                                        {getProgressBarRestante(row.etapas)}
                                    </>
                                    : null}
                            </Progress>
                        </div>
                    );
                } else {
                    return (
                        //Esta div trata do símbolo de check no lado Esquerdo da tabela Recuperação em dashboard caso ocorra a conclusão das Etapas
                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <i
                                className="fa fa-check-circle"
                                style={{
                                    color: 'green',
                                    fontSize: 22,
                                    marginLeft: 10,
                                    marginTop: 4
                                }}
                            />
                        </div>
                    )
                }
            }
            else {
                return (
                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        -
                    </div>
                )
            }
        };

        const getTitleEtapa = (etapa) => {

            return `Etapa: ${etapa.etapa}\nPorcentagem ${etapa.tempo.toFixed(2)}%`
        }

        const getProgressBarRestante = (etapas) => {
            const totalPorcentagemEtapas = etapas.map(etapa => etapa.tempo).reduce((a, b) => a + b, 0);
            const etapasRestantes = etapas.filter(etapa => etapa.tempo === 0)
            const porcentagemRestante = 100 - totalPorcentagemEtapas
            console.log(porcentagemRestante, 'aki', totalPorcentagemEtapas, etapas)
            if(porcentagemRestante){
                const title = 'Etapas restantes:\n\n'+
                    `${etapasRestantes.map(etapa => etapa.etapa).join('\n')}\n\n`+
                    `Total Porcentagem: ${porcentagemRestante.toFixed(2)}%`


                return <Progress title={title} bar color={'etapa-empty'} value={porcentagemRestante}>{parseInt(porcentagemRestante)}%</Progress>

            }

        }

        const etapasDupla = (cell, row, rowIndex) => {
            if (this.state.loadArray && row.hasOwnProperty("etapasDupla")) {
                if (row.etapasDupla.length) {
                    return (
                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <Progress multi style={{ width: 200 }}>
                                {row.etapasDupla.length > 0 ?
                                    <>
                                        {row.etapasDupla.map((etapa, index) =>
                                            <Progress title={getTitleEtapa(etapa)} bar color={'etapa-'+index} value={etapa.tempo}>{parseInt(etapa.tempo)}%</Progress>
                                        )}
                                        {getProgressBarRestante(row.etapasDupla)}
                                    </>
                                    : null}
                            </Progress>
                        </div>
                    );
                } else {
                    return (
                        //Esta div trata do símbolo de check no lado direito da tabela Recuperação em Dashboard
                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <i
                                className="fa fa-check-circle"
                                style={{
                                    color: 'green',
                                    fontSize: 22,
                                    marginLeft: 10,
                                    marginTop: 4
                                }}
                            />
                        </div>
                    )
                }
            }
            else {
                return (
                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        -
                    </div>
                )
            }
        };

        const botaoRecuperacao = (cell, row, rowIndex) => {
            return (
                <div style={{ display: 'inline-flex' }}>
                    <i
                        title='Gráfico Comparativo'
                        onClick={() =>
                            this.graficoComparativo(
                                row.ordem_servico
                            )
                        }
                        className="fa fa-bar-chart"
                        style={{
                            color: "rgb(31, 77, 255)",
                            cursor: 'pointer',
                            fontSize: 20,
                            marginLeft: 10,
                            marginTop: 4
                        }}
                    />
                    {row.nitretacao === 1 ?
                        <p
                            // onClick={() =>
                            //     this.editarRecuperacao(
                            //         JSON.stringify(row.idrecuperacao),
                            //     )
                            // }
                            title='Nitretação'
                            style={{
                                fontSize: 20,
                                marginLeft: 10,
                            }}
                        >N</p>
                        : null
                    }
                     {row.desempenamento === 1 ?
                        <p
                            // onClick={() =>
                            //     this.editarRecuperacao(
                            //         JSON.stringify(row.idrecuperacao),
                            //     )
                            // }
                            title='Desempenamento'
                            style={{
                                fontSize: 20,
                                marginLeft: 10,
                            }}
                        >D</p>
                        : null
                    }
                    {row.brunimento === 1 ?
                        <p
                            // onClick={() =>
                            //     this.editarRecuperacao(
                            //         JSON.stringify(row.idrecuperacao),
                            //     )
                            // }
                            title='Brunimento'
                            style={{  fontSize: 20, marginLeft: 10, marginBottom: 5 }}
                        >B</p>
                        : null}
                    {row.emenda === 1 ?
                        <p
                            // onClick={() =>
                            //     this.editarRecuperacao(
                            //         JSON.stringify(row.idrecuperacao),
                            //     )
                            // }
                            title='Terceirizado'
                            style={{  fontSize: 20, marginLeft: 10, marginBottom: 5 }}
                        >T</p>
                        : null
                    }
                </div>
            )
        };
        const botaoRelatorio = (cell, row, rowIndex) => {
            return (
                <div>
                    <i
                        title='Relatório Dimensional'
                        onClick={() =>
                            this.showRelatorio(row.ordem_servico)
                        }
                        className="fa fa-area-chart"
                        style={{  fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
                    />

                </div>
            )
        };

        //Essa função altera o visual da tabela Recuperação no dashboard, como nome do título.
                const columns = [
            {
                dataField: 'ordem_servico',
                text: 'OS',
                headerAlign: 'center'
            },
            {
                dataField: 'cliente',
                text: 'Cliente',
                headerAlign: 'center'

            },
            {
                dataField: 'tipo_template',
                text: 'Tipo',
                headerAlign: 'center'
            },
            {
                dataField: 'date',
                text: 'Data',
                headerAlign: 'center'

            },
            {
                dataField: 'button',
                text: 'Esquerdo',
                textAlign: 'center',
                formatter: botoes,
                headerAlign: 'center'

            },
            {
                dataField: 'button',
                text: 'Direito',
                textAlign: 'center',
                formatter: etapasDupla,
                headerAlign: 'center'

            },
            {
                dataField: 'button',
                text: 'Recuperação',
                textAlign: 'center',
                formatter: botaoRecuperacao,
                headerAlign: 'center'

            },
            {
                dataField: 'button',
                text: 'Relatório',
                textAlign: 'center',
                formatter: botaoRelatorio,
                headerAlign: 'center'

            },
        ];

        return (
            <div>
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.loadArray ?
                        <ToolkitProvider
                            keyField="id"
                            data={this.state.arrayConsultarRecuperacaoEtapas}
                            columns={columns}
                            search
                        >
                            {props => (
                                <div style={{ textAlign: 'center' }}>
                                    <SearchBar
                                        placeholder="Pesquisar"
                                        id="search_txt"
                                        style={{ width: 470, marginRight: 10 }}
                                        {...props.searchProps}
                                    />
                                    <ClearSearchButton text="Limpar" {...props.searchProps} />
                                    <BootstrapTable
                                        pagination={paginator()}
                                        {...props.baseProps}
                                    />
                                    {/* Botão que realiza função de exportar para Excel*/}
                                    <ExportCSVButton {...props.csvProps}>
                                        Exportar Excel
                                </ExportCSVButton>
                                </div>
                            )}
                        </ToolkitProvider>
                        :
                        <BarLoader
                            css={override}
                            sizeUnit={'px'}
                            size={60}
                            color={'#123b7a'}
                            loading={!this.state.loadArray}
                        />
                    }
                    {this.state.showModalDados ?
                        <ModalGraficoRelatorio show={this.state.showModalDados} close={this.close} id={this.state.id_cadastro}
                        backdrop="static"
                        keyboard={false} />
                        : null}
                </div>
                {this.state.modalCompare ?
                    <BarChart
                        open={this.state.modalCompare}
                        itemSelecionado={this.state.arrayEditar}
                        close={this.close}
                        id_recuperacao={this.state.id_recuperacao_editar}
                        tipoRosca={this.state.tipoRosca}
                        arrayRoscaDupla={this.state.arrayRoscaDupla}
                        handleItemSelecionado={this.handleItemSelecionado}
                    />
                    : null}

                <EditarRecuperacao
                    itemSelecionado={this.state.arrayEditar}
                    open={this.state.showModalEditar}
                    close={this.changeModal}
                    tipoRosca={this.state.tipoRosca}
                    arrayRoscaDupla={this.state.arrayRoscaDupla}
                    handleItemSelecionado={this.handleItemSelecionado}
                />
            </div>
        )
    }
}
export default Dashboard;