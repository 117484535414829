import { css } from '@emotion/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Card } from 'components/Card/Card.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import BarChart from 'components/Grafico_Comparativo/barChart';
//Editar Recuperação está associada com o script visual de formulários no caminho Recuperação > Ações > Consulta Recuperação > Editar Recuperação, está localizado como editarRecuperação.js
import EditarRecuperacao from 'components/Modais/EditarRecuperacao';
import moment from 'moment';
import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import {
  Col,
  ControlLabel, FormGroup, Grid,
  MenuItem,
  Modal, Nav, Navbar,
  NavDropdown, NavItem, Row
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  CSVExport, Search
} from 'react-bootstrap-table2-toolkit';
import { Redirect } from "react-router-dom";
import { BounceLoader } from 'react-spinners';
import { Checkbox } from 'semantic-ui-react';
import { clienteService } from 'services/clientes';
import '../assets/css/recuperacao.css';
import api from '../services/api';
import CheckBox from '@material-ui/core/Checkbox';
import Spinner from 'reactstrap/lib/Spinner';
import ModalRelatorio from 'components/ModalRelatorio/Index';
import ModalAlert from 'components/ModalAlert/Index';
import Select from 'components/Select';
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


class Recuperacao extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  constructor(props) {
    super(props);

    this.updateInput = this.updateInput.bind(this);
    this.ordem_servico = this.ordem_servico.bind(this);
    this.cliente = this.cliente.bind(this);
    this.tipo = this.tipo.bind(this);
    this.data_inicial = this.data_inicial.bind(this);
    this.previsao = this.previsao.bind(this);
    this.data_final = this.data_final.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      orcamento: '',
      pedido: '',
      tipo: '',
      formSubmitted: false, // Indicates submit status of login form
      loading: false, // Indicates in progress state of login form
      selectedOption: null,
      habilitarBtnCadastrar: true,
      esOrcamento: false,
      escPedido: false,
      escCliente: false,
      clientes: [],
      clienteSelecionado: '',
      escTipo: false,
      arrayPreCadastro: '',
      carregou: false,
      os: null,
      data: '',
      arrayEtapas: [],
      etapa0: false,
      etapa1: false,
      etapa2: false,
      etapa3: false,
      etapa4: false,
      etapa5: false,
      etapa6: false,
      etapa7: false,
      etapa8: false,
      etapa9: false,
      id_recuperacao: '',
      tempo_soldar_ponteira: '',
      tempo_camada_solda: '',
      tempo_camada_solda2: '',
      tempo_camada_solda3: '',
      tempo_usinagem_desbaste: '',
      tempo_usinagem_desbaste2: '',
      tempo_usinagem_desbaste3: '',
      tempo_desbaste_lixamento: '',
      tempo_usinagem_final: '',
      tempo_desbaste_lixadeira: '',
      showLoading: false,
      loading: true,
      showModalConfirmar: false,
      habilitarInput: [true, true, true, true, true, true, true, true, true, true, true, true],
      ordem_servico: '',
      data_inicial: '',
      previsao: '',
      nf_entrada: '',
      nf_saida: '',
      clienteEditar: '',
      tipoEditar: '',
      buscouRecuperacao: false,
      arrayConsultarRecuperacao: [],
      showModalConsultaRecuperacao: false,
      os_deletar: '',
      idEtapa_validacao: [],
      options: {
        labels: [],
        colors: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series: [],
      tempoTotal: '',
      tempo_id_zero_etapa: '',
      tempo_id_primeira_etapa: '',
      tempo_id_segunda_etapa: '',
      tempo_id_terceira_etapa: '',
      tempo_id_quarta_etapa: '',
      tempo_id_quinta_etapa: '',
      tempo_id_sexta_etapa: '',
      tempo_id_setima_etapa: '',
      tempo_id_oitava_etapa: '',
      tempo_id_nona_etapa: '',
      tempo_id_decima_etapa: '',
      tempo_id_decima_primeira_etapa: '',
      modalCompare: false,
      id_recuperacao_editar: '',
      infoGeral: '',
      infoBanco: 'Recuperação dos filetes ao longo de (...) com diâmetro nominal (...)',
      ordem_servico_consulta: '',
      tipo_consulta: '',
      cliente_consulta: '',
      arrayChartComparativo: '',
      idrecuperacao_deletar: '',
      arrayEditar: '',
      checkedA: true,
      checkedB: false,
      trabalho: '',
      brunimento: false,
      nitretacao: false,
      emenda: false,
      nano_ceramica: false,
      cromagem_descromagem: false,
      desempenamento: false,
      conica: 0,
      previsao: null,
      data_final: null,
      diametro: 0,
      diametro_menor: null,
      diametro_maior: null,
      codigo: null,
      comprimento_recuperado: 0,
      folga: 0,
      tempo_alinhamento: null,
      tipoRosca: null,
      arrayRoscaDupla: [],
      showModalChart: false,
      comprimento_total: 0,
      ultimaOS: 0,
      ultimaData: null,
      loadingCadastroRecuperacao: true,
      almofada: [],
      almofadaSelecionada: null,
      revestimentoDuro: [],
      revestimentoDuroSelecionado: null,
      openModalTelaRelatorio: false,
      dadosOsRelatorio: null,
      modalChangeSide: false,
      dadosOsLadoEscolhido: null,
    }
  };

  async updateInput(event, i) {
    if (i == 0)
      await this.setState({ tempo_soldar_ponteira: event.target.value })
    if (i == 1)
      await this.setState({ tempo_alinhamento: event.target.value })
    if (i == 2)
      await this.setState({ tempo_desbaste_lixamento: event.target.value })
    if (i == 3)
      await this.setState({ tempo_camada_solda: event.target.value })
    if (i == 4)
      await this.setState({ tempo_usinagem_desbaste: event.target.value })
    if (i == 5)
      await this.setState({ tempo_camada_solda2: event.target.value })
    if (i == 6)
      await this.setState({ tempo_usinagem_desbaste2: event.target.value })
    if (i == 7)
      await this.setState({ tempo_camada_solda3: event.target.value })
    if (i == 8)
      await this.setState({ tempo_usinagem_desbaste3: event.target.value })
    if (i == 9)
      await this.setState({ tempo_desbaste_lixadeira: event.target.value })
    if (i == 10)
      await this.setState({ tempo_usinagem_final: event.target.value })
    if (i == 11)
      await this.setState({ retrabalho: event.target.value })
    if (i == 12) {
      await this.setState({ infoGeral: event.target.value })
    }
  }


  async componentDidMount() {
    const { data } = await api.get('/material/getMaterial/recuperacao');

    data.materialRecuperacao.forEach(item => {
      if (item.tipo === 'Almofada') {
        item.values.forEach(almofada => {
          this.setState({
            almofada: [...this.state.almofada, {
              value: almofada,
              label: almofada
            }]
          })
        })
      } else {
        item.values.forEach(revestimento => {
          this.setState({
            revestimentoDuro: [...this.state.revestimentoDuro, {
              value: revestimento,
              label: revestimento
            }]
          })
        })
      }
    })

    const response = await api.get('/get/consultarStatusRecuperacao');
    const ultimaOS = await api.get('/recuperacao/buscarUltimaOS');
    const clientes = await clienteService.get();
    const formatedClientes = await clientes.data.map(item => ({ value: item.id, label: item.nome }))
    if (response.data != 0) {
      this.setState({ arrayPreCadastro: response.data, carregou: true });
    }
    this.setState({
      clientes: formatedClientes,
    })

    if (ultimaOS.data) {
      this.setState({
        ultimaOS: parseInt(ultimaOS.data[0].ordem_servico) + 100,
        ultimaData: ultimaOS.data[0].date,
        os: parseInt(ultimaOS.data[0].ordem_servico) + 100
      })
    }
    this.setState({
      loadingCadastroRecuperacao: false
    })
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  async buscar(item) {
    var data = item.date.replace(/\//g, "-");
    await this.setState({
      id_recuperacao: item.idrecuperacao,
      os: item.ordem_servico,
      data: moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      clienteSelecionado: this.state.clientes.filter(e => item.clienteId == e.value)[0],
      tipo: { label: item.tipo, value: item.tipo },
      infoBanco: item.info,
      buscouRecuperacao: true,
    })

    this.forceUpdate();
  }

  async check(opcao) {
    let arrayAuxEtapas = this.state.arrayEtapas;
    let arrayHabilitarEtapasAux = this.state.habilitarInput;
    if (opcao === 0) {

      if (this.state.arrayEtapas[0] === 0) {
        arrayAuxEtapas.splice(0, 1);
        arrayHabilitarEtapasAux[0] = !arrayHabilitarEtapasAux[0];
      } else {
        arrayAuxEtapas[0] = opcao;
        arrayHabilitarEtapasAux[0] = false;
      }
      this.setState({
        arrayEtapas: arrayAuxEtapas,
        habitarInput: arrayHabilitarEtapasAux
      })
    }
    if (opcao == 1) {
      if (this.state.arrayEtapas[1] == 1) {
        this.state.arrayEtapas.splice(1, 1);
        this.state.habilitarInput[1] = !this.state.habilitarInput[1];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[1] = opcao;
        this.state.habilitarInput[1] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 2) {
      if (this.state.arrayEtapas[2] == 2) {
        this.state.arrayEtapas.splice(2, 1);
        this.state.habilitarInput[2] = !this.state.habilitarInput[2];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[2] = opcao;
        this.state.habilitarInput[2] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 3) {
      if (this.state.arrayEtapas[3] == 3) {
        this.state.arrayEtapas.splice(3, 1);
        this.state.habilitarInput[3] = !this.state.habilitarInput[3];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[3] = opcao;
        this.state.habilitarInput[3] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 4) {
      if (this.state.arrayEtapas[4] == 4) {
        this.state.arrayEtapas.splice(4, 1);
        this.state.habilitarInput[4] = !this.state.habilitarInput[4];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[4] = opcao;
        this.state.habilitarInput[4] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 5) {
      if (this.state.arrayEtapas[5] == 5) {
        this.state.arrayEtapas.splice(5, 1);
        this.state.habilitarInput[5] = !this.state.habilitarInput[5];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[5] = opcao;
        this.state.habilitarInput[5] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 6) {
      if (this.state.arrayEtapas[6] == 6) {
        this.state.arrayEtapas.splice(6, 1);
        this.state.habilitarInput[6] = !this.state.habilitarInput[6];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[6] = opcao;
        this.state.habilitarInput[6] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 7) {
      if (this.state.arrayEtapas[7] == 7) {
        this.state.arrayEtapas.splice(7, 1);
        this.state.habilitarInput[7] = !this.state.habilitarInput[7];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[7] = opcao;
        this.state.habilitarInput[7] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 8) {
      if (this.state.arrayEtapas[8] == 8) {
        this.state.arrayEtapas.splice(8, 1);
        this.state.habilitarInput[8] = !this.state.habilitarInput[8];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[8] = opcao;
        this.state.habilitarInput[8] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 9) {
      if (this.state.arrayEtapas[9] == 9) {
        this.state.arrayEtapas.splice(9, 1);
        this.state.habilitarInput[9] = !this.state.habilitarInput[9];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[9] = opcao;
        this.state.habilitarInput[9] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 10) {
      if (this.state.arrayEtapas[10] == 10) {
        this.state.arrayEtapas.splice(10, 1);
        this.state.habilitarInput[10] = !this.state.habilitarInput[10];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[10] = opcao;
        this.state.habilitarInput[10] = false;
        this.forceUpdate();
      }
    }
    if (opcao == 11) {
      if (this.state.arrayEtapas[11] == 11) {
        this.state.arrayEtapas.splice(11, 1);
        this.state.habilitarInput[11] = !this.state.habilitarInput[11];
        this.forceUpdate();
      } else {
        this.state.arrayEtapas[11] = opcao;
        this.state.habilitarInput[11] = false;
        this.forceUpdate();
      }
    }

    this.setState({ habilitarBtnCadastrar: false })
  }
  // mudar tete
  async verificarOrdem() {
    const r = await api.post('recuperacao/verificarOrdem', {
      ordem_servico: this.state.os
    })
    return r.data;

  }

  async verificarOrdem() {
    const r = await api.post('recuperacao/verificarOrdem', {
      ordem_servico: this.state.os
    })
    return r.data;
  }

  async cadastrarPreRelatorio(idrecuperacao = null) {
    let data_final = this.state.data_final; // Store the current value of data_final
    if (data_final === null) {
      data_final = this.state.previsao; // Set data_final to this.state.previsao if it's empty
    }
  
    await api.post('/posts/preCadastroRecuperacao', {
      idrecuperacao: idrecuperacao,
      mode: 'web',
      ordem_servico: this.state.os,
      cliente: this.clienteSelecionado,
      clienteId: this.state.clienteSelecionado.value,
      tipo: this.state.tipo.value,
      previsao: this.state.previsao,
      nf_entrada: this.state.nf_entrada,
      nf_saida: this.state.nf_saida,
      date: this.state.data,
      data_final: data_final, // Use the modified data_final value
      info: this.state.infoBanco,
      status: 1,
      comprimento_total: this.state.comprimento_total,
      nitretacao: this.state.nitretacao ? 1 : 0,
      brunimento: this.state.brunimento ? 1 : 0,
      emenda: this.state.emenda ? 1 : 0,
      nano_ceramica: this.state.nano_ceramica ? 1 : 0,
      cromagem_descromagem: this.state.cromagem_descromagem ? 1 : 0,
      conica: this.state.conica ? 1 : 0,
      desempenamento: this.state.desempenamento ? 1 : 0,
      codigo: this.state.codigo,
      diametro: this.state.diametro,
      diametro_menor: this.state.diametro_menor,
      diametro_maior: this.state.diametro_maior,
      folga: this.state.folga,
      comprimento_recuperado: this.state.comprimento_recuperado,
      revestimento_duro: this.state.revestimentoDuroSelecionado,
      almofada: this.state.almofadaSelecionada,
      lado: null,
      ArrayEtapas: this.state.arrayEtapas,
      ArrayTempos: [
        this.state.tempo_soldar_ponteira,
        this.state.tempo_alinhamento,
        this.state.tempo_desbaste_lixamento,
        this.state.tempo_camada_solda,
        this.state.tempo_usinagem_desbaste,
        this.state.tempo_camada_solda2,
        this.state.tempo_usinagem_desbaste2,
        this.state.tempo_camada_solda3,
        this.state.tempo_usinagem_desbaste3,
        this.state.tempo_desbaste_lixadeira,
        this.state.tempo_usinagem_final,
        this.state.retrabalho
      ]
    });
    const responseIdRecuperacao = await api.post('posts/pegarUltimoCadastroRecuperacao');
    await this.setState({ id_recuperacao: responseIdRecuperacao.data[0].idrecuperacao });
  }

  async cadastrar() {
    let arrayRoscaDupla = [];
    //se a OS não foi preenchida
    if (this.state.os == null) {
      alert("OS vazia, digite o número da ordem de serviço!");
      return 0;
    }
    //Se já houver uma OS cadastrada com mesmo numero
    let verify = await this.verificarOrdem();
    if (verify) {
      alert("Ordem de serviço já cadastrada!");
      return 1;
    }
    var bool = true

    this.state.habilitarInput.map(item => {
      if (item != true)
        bool = false;
    })

    if (bool) {
      alert("Deve ser cadastrado ao menos uma etapa!");
      return 1;
    }

    await this.setState({ showLoading: true });

    //se buscou recuperação, cadastrar a partir do que veio do app
    if (this.state.buscouRecuperacao) {
      await this.cadastrarPreRelatorio(this.state.id_recuperacao);
    } else {
      await this.cadastrarPreRelatorio();
    }
    this.myFormRef.reset();
    window.location.reload();
  }

  mudarPage() {
    this.props.history.push({ pathname: '/admin/importacao' })

  }

  async ordem_servico(event) {
    await this.setState({ os: event.target.value });
  }
  async data_inicial(event) {
    await this.setState({ data: event.target.value });

  }
  async data_final(event) {
    await this.setState({ data_final: event.target.value });

  }
  async cliente(event) {
    await this.setState({ cliente: event.target.value });
  }
  async previsao(event) {
    await this.setState({ previsao: event.target.value });

  }
  async tipo(event) {
    await this.setState({ tipo: event.target.value });
  }

  montarTemplateTipo(recuperacao) {
    let array = []
    recuperacao.map((item) => {
      let diametro_menor = item.diametro_menor === null ? "-" : item.diametro_menor;
      let diametro_maior = item.diametro_maior === null ? "-" : item.diametro_maior;
      let diametro = item.diametro === null ? "-" : item.diametro;
      let comprimento_total = item.comprimento_total === null ? "-" : item.comprimento_total;
      let conica = item.conica ? `Cônica ${diametro_menor} / ${diametro_maior}` : "";
      array.push(
        {
          ...item, tipo_template:
            item.tipo === "Rosca Dupla" ? conica ? `Dupla Rosca ${conica}` :
              `Dupla Rosca ${diametro} LD ${comprimento_total === '-' || diametro === '-' ? '-' : Math.round(parseFloat(comprimento_total) / parseFloat(diametro))}` :
              item.tipo === 'Mono Rosca' ? `Mono Rosca ${diametro} LD ${comprimento_total === '-' || diametro === '-' ? '-' : Math.round(parseFloat(comprimento_total) / parseFloat(diametro))}` : item.tipo,
        })
    })
    return array;
  }

  async consultarRecuperacao() {
    const responseRecuperacao = await api.get('/get/consultarRecuperacaoStatus1');

    if (responseRecuperacao.data != 0) {
      let newArray = []
      newArray = this.montarTemplateTipo(responseRecuperacao.data)
      this.setState({ arrayConsultarRecuperacao: newArray, showModalConsultaRecuperacao: true })
    } else
      alert("Nenhum cadastro foi efetuado!");
  }

  async validarEtapas() {
    const etapa = this.state.arrayEditar
    if (etapa.id_zero_etapa != null) {
      this.state.idEtapa_validacao[0] = true;
      this.forceUpdate();
    }
    if (etapa.id_primeira_etapa != null) {
      this.state.idEtapa_validacao[1] = true;
      this.forceUpdate();
    }
    if (etapa.id_segunda_etapa != null) {
      this.state.idEtapa_validacao[2] = true;
      this.forceUpdate();
    }
    if (etapa.id_terceira_etapa != null) {
      this.state.idEtapa_validacao[3] = true;
      this.forceUpdate();
    }
    if (etapa.id_quarta_etapa != null) {
      this.state.idEtapa_validacao[4] = true;
      this.forceUpdate();
    }
    if (etapa.id_quinta_etapa != null) {
      this.state.idEtapa_validacao[5] = true;
      this.forceUpdate();
    }
    if (etapa.id_sexta_etapa != null) {
      this.state.idEtapa_validacao[6] = true;
      this.forceUpdate();
    }
    if (etapa.id_setima_etapa != null) {
      this.state.idEtapa_validacao[7] = true;
      this.forceUpdate();
    }
    if (etapa.id_oitava_etapa != null) {
      this.state.idEtapa_validacao[8] = true;
      this.forceUpdate();
    }
    if (etapa.id_nona_etapa != null) {
      this.state.idEtapa_validacao[9] = true;
      this.forceUpdate();
    }
    if (etapa.id_decima_etapa != null) {
      this.state.idEtapa_validacao[10] = true;
      this.forceUpdate();
    }
    if (etapa.id_decima_primeira_etapa != null) {
      this.state.idEtapa_validacao[11] = true;
      this.forceUpdate();
    }

  }
  async buscarTempos() {
    const etapa = this.state.arrayEditar;

    if (this.state.idEtapa_validacao[0] == true) {
      const receberTempo0 = await api.post('/posts/buscarTempoPreDefinido', { id_zero_etapa: etapa.id_zero_etapa, etapa: 0 })
      if (receberTempo0.data[0].tempo_pre != null) {
        this.setState({
          tempo_id_zero_etapa: receberTempo0.data[0].tempo_pre
        })
      }
    }
    if (this.state.idEtapa_validacao[1] == true) {
      const receberTempo1 = await api.post('/posts/buscarTempoPreDefinido', { id_primeira_etapa: etapa.id_primeira_etapa, etapa: 1 })
      if (receberTempo1.data[0].tempo_pre != null) {
        this.setState({
          tempo_id_primeira_etapa: receberTempo1.data[0].tempo_pre
        })
      }
    }
    if (this.state.idEtapa_validacao[2] == true) {
      const receberTempo2 = await api.post('/posts/buscarTempoPreDefinido', { id_segunda_etapa: etapa.id_segunda_etapa, etapa: 2 })
      if (receberTempo2.data[0].tempo_pre != null) {
        this.setState({ tempo_id_segunda_etapa: receberTempo2.data[0].tempo_pre })
      }
    }
    if (this.state.idEtapa_validacao[3] == true) {
      const receberTempo3 = await api.post('/posts/buscarTempoPreDefinido', { id_terceira_etapa: etapa.id_terceira_etapa, etapa: 3 })
      if (receberTempo3.data[0].tempo_pre != null) {
        this.setState({ tempo_id_terceira_etapa: receberTempo3.data[0].tempo_pre })
      }
    }
    if (this.state.idEtapa_validacao[4] == true) {
      const receberTempo4 = await api.post('/posts/buscarTempoPreDefinido', { id_quarta_etapa: etapa.id_quarta_etapa, etapa: 4 })
      if (receberTempo4.data[0].tempo_pre != null) {
        this.setState({ tempo_id_quarta_etapa: receberTempo4.data[0].tempo_pre })
      }
    }

    if (this.state.idEtapa_validacao[5] == true) {
      const receberTempo5 = await api.post('/posts/buscarTempoPreDefinido', { id_quinta_etapa: etapa.id_quinta_etapa, etapa: 5 })
      if (receberTempo5.data[0].tempo_pre != null) {
        this.setState({ tempo_id_quinta_etapa: receberTempo5.data[0].tempo_pre })
      }
    }
    if (this.state.idEtapa_validacao[6] == true) {
      const receberTempo6 = await api.post('/posts/buscarTempoPreDefinido', { id_sexta_etapa: etapa.id_sexta_etapa, etapa: 6 })
      if (receberTempo6.data[0].tempo_pre != null) {
        this.setState({ tempo_id_sexta_etapa: receberTempo6.data[0].tempo_pre })
      }
    }
    if (this.state.idEtapa_validacao[7] == true) {
      const receberTempo7 = await api.post('/posts/buscarTempoPreDefinido', { id_setima_etapa: etapa.id_setima_etapa, etapa: 7 })
      if (receberTempo7.data[0].tempo_pre != null) {
        this.setState({ tempo_id_setima_etapa: receberTempo7.data[0].tempo_pre })
      }
    }
    if (this.state.idEtapa_validacao[8] == true) {
      const receberTempo8 = await api.post('/posts/buscarTempoPreDefinido', { id_oitava_etapa: etapa.id_oitava_etapa, etapa: 8 })
      if (receberTempo8.data[0].tempo_pre != null) {
        this.setState({ tempo_id_oitava_etapa: receberTempo8.data[0].tempo_pre })
      }
    }
    if (this.state.idEtapa_validacao[9] == true) {
      const receberTempo9 = await api.post('/posts/buscarTempoPreDefinido', { id_nona_etapa: etapa.id_nona_etapa, etapa: 9 })
      if (receberTempo9.data[0].tempo_pre != null) {
        this.setState({ tempo_id_nona_etapa: receberTempo9.data[0].tempo_pre })
      }
    }
    if (this.state.idEtapa_validacao[10] == true) {
      const receberTempo10 = await api.post('/posts/buscarTempoPreDefinido', { id_decima_etapa: etapa.id_decima_etapa, etapa: 10 })
      if (receberTempo10.data[0].tempo_pre != null) {
        this.setState({ tempo_id_decima_etapa: receberTempo10.data[0].tempo_pre })
      }
    }

    if (this.state.idEtapa_validacao[11] == true) {
      const receberTempo11 = await api.post('/posts/buscarTempoPreDefinido', { id_decima_primeira_etapa: etapa.id_decima_primeira_etapa, etapa: 11 })
      if (receberTempo11.data[0].tempo_pre != null) {
        this.setState({ tempo_id_decima_primeira_etapa: receberTempo11.data[0].tempo_pre })
      }
    }

    // await this.setState({ tempoTotal: parseInt(this.state.tempo_id_primeira_etapa) + parseInt(this.state.tempo_id_segunda_etapa) + parseInt(this.state.tempo_id_terceira_etapa) + parseInt(this.state.tempo_id_quarta_etapa) + parseInt(this.state.tempo_id_quinta_etapa) + parseInt(this.state.tempo_solda2) + parseInt(this.state.tempo_solda3) + parseInt(this.state.tempo_usinagem2) + parseInt(this.state.tempo_usinagem3) })
    // console.log("tempo total: ", parseInt(this.state.tempoTotal));

    this.setarValoresGrafico();
  }

  async editarTabela(ordem_servico) {
    const { data } = await api.post('/posts/consultarRecuperacaoOS', { ordem_servico });

    if (data.length === 1) {
      this.setState({
        etapasSetadas: data[0],
        arrayEditar: data[0],
        ordem_servico_consulta: data[0].ordem_servico,
        cliente_consulta: data[0].cliente,
        tipo_consulta: data[0].tipo,
      })
      this.validarEtapas();
      this.buscarTempos();
    } else if (data.length === 2) {
      this.setState({
        arrayEditar: data[0],
        arrayRoscaDupla: data,
        etapasSetadas: data[0],
        ordem_servico_consulta: data[0].ordem_servico,
        cliente_consulta: data[0].cliente,
        tipo_consulta: data[0].tipo,
        tipoRosca: 'dupla'
      })
      this.validarEtapas();
      this.buscarTempos();
    }

    this.setState({ showModalChart: true, showModalConsultaRecuperacao: false })
  }

  //Gráfico pizza de "Editar Recuperação"
  async setarValoresGrafico() {
    const optionsLabels = []
    const optionsColors = []
    const series = []

    //Define o estado da etapa, nome da label e cor no gráfico de pizza
    if (this.state.idEtapa_validacao[0] == true && this.state.tempo_id_zero_etapa != 0) {
      optionsLabels.push('Soldar Ponteira')
      optionsColors.push('#B0C4DE')
      series.push(moment.duration(this.state.tempo_id_zero_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[1] == true && this.state.tempo_id_primeira_etapa != 0) {
      optionsLabels.push('Alinhamento')
      optionsColors.push('#ff0000')
      series.push(moment.duration(this.state.tempo_id_primeira_etapa).asHours())

    }
    if (this.state.idEtapa_validacao[2] == true && this.state.tempo_id_segunda_etapa != 0) {
      optionsLabels.push('Usinagem Desbaste')
      optionsColors.push('#ff4000')
      series.push(moment.duration(this.state.tempo_id_segunda_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[3] == true && this.state.tempo_id_terceira_etapa != 0) {
      optionsLabels.push('Solda Almofada')
      optionsColors.push('#ff8000')
      series.push(moment.duration(this.state.tempo_id_terceira_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[4] == true && this.state.tempo_id_quarta_etapa != 0) {
      optionsLabels.push('Usinagem Almofada')
      optionsColors.push('#ffbf00')
      series.push(moment.duration(this.state.tempo_id_quarta_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[5] == true && this.state.tempo_id_quinta_etapa != 0) {
      optionsLabels.push('Solda Revestimento')
      optionsColors.push('#ffff00')
      series.push(moment.duration(this.state.tempo_id_quinta_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[6] == true && this.state.tempo_id_sexta_etapa != 0) {
      optionsLabels.push('Usinagem Revestimento')
      optionsColors.push('#80ff00')
      series.push(moment.duration(this.state.tempo_id_sexta_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[7] == true && this.state.tempo_id_setima_etapa != 0) {
      optionsLabels.push('Lixamento')
      optionsColors.push('#00ffff')
      series.push(moment.duration(this.state.tempo_id_setima_etapa).asHours())
    }

    if (this.state.idEtapa_validacao[8] == true && this.state.tempo_id_oitava_etapa != 0) {
      optionsLabels.push('Desbaste Lateral Filetes')
      optionsColors.push('#00bfff')
      series.push(moment.duration(this.state.tempo_id_oitava_etapa).asHours())

    }
    if (this.state.idEtapa_validacao[9] == true && this.state.tempo_id_nona_etapa != 0) {
      optionsLabels.push('Polimento')
      optionsColors.push('#0080ff')
      series.push(moment.duration(this.state.tempo_id_nona_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[10] == true && this.state.tempo_id_nona_etapa != 0) {
      optionsLabels.push('Usinagem Final')
      optionsColors.push('#8000ff')
      series.push(moment.duration(this.state.tempo_id_decima_etapa).asHours())
    }
    if (this.state.idEtapa_validacao[11] == true && this.state.tempo_id_decima_primeira_etapa != 0) {
      optionsLabels.push('Retrabalho')
      optionsColors.push('#008080')
      series.push(moment.duration(this.state.tempo_id_decima_primeira_etapa).asHours())
    }

    this.setState({
      options: {
        legend: { labels: { colors: ['#f0f0f0'] } },
        labels: optionsLabels,
        colors: optionsColors,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: { width: 350 },
              legend: { position: 'bottom' }
            }
          }
        ]
      },
      series
    })

  }

  async limparDados() {
    await this.setState({
      showModalChart: false, showModalConsultaRecuperacao: true,
      options: { labels: [], colors: [], responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }] },
      series: [], idEtapa_validacao: [], tempoTotal: '',
      tipoRosca: null
    })
  }

  //Seta o "Gráfico Comparativo" em "Ações"
  async graficoComparativo(ordem_servico) {
    const { data } = await api.post('/posts/consultarRecuperacaoOS', { ordem_servico });

    if (data.length === 1) {
      this.setState({
        modalCompare: true,
        arrayChartComparativo: data[0],
        showModalConsultaRecuperacao: false,
        id_recuperacao_editar: data[0].idrecuperacao,
        arrayEditar: data[0],
      })
    } else if (data.length === 2) {
      this.setState({
        arrayEditar: data[0],
        modalCompare: true,
        arrayRoscaDupla: data,
        arrayChartComparativo: data[0],
        showModalConsultaRecuperacao: false,
        id_recuperacao_editar: data[0].idrecuperacao,
        tipoRosca: 'dupla'
      })
    }
  }

  closeModal() {
    if (this.state.modalCompare)
      this.setState({ modalCompare: !this.state.modalCompare, showModalConsultaRecuperacao: true, tipoRosca: null })
    else
      this.setState({ showModalEditar: !this.state.showModalEditar, showModalConsultaRecuperacao: true, tipoRosca: null })
  }

  sair() {
    localStorage.removeItem('login');
    localStorage.removeItem('usuario');
    this.props.props.history.push({ pathname: '/login' });

  }

  async deletarOS(ordem_servico) {
    const { data } = await api.post('/posts/consultarRecuperacaoOS', { ordem_servico })

    this.setState({ showModalConsultaRecuperacao: false, showAlerta: true, idrecuperacao_deletar: data })
    // const response = await api.post('/posts/consultarRecuperacao', { idrecuperacao: id })
    // this.setState({ os_deletar: response.data[0].ordem_servico })
  }

  async deletar_OS() {
    this.state.idrecuperacao_deletar.forEach(async (item) => {
      await api.post('/recuperacao/deletarRecuperacao', {
        idrecuperacao: item.idrecuperacao
      });
    })

    alert("OS deletada com sucesso!")
  }

  async editarRecuperacao(ordem_servico) {
    const { data } = await api.post('/posts/consultarRecuperacaoOS', { ordem_servico })

    if (data.length === 1) {
      this.setState({ arrayEditar: data[0], showModalConsultaRecuperacao: false, showModalEditar: true, idEditar: data[0].idrecuperacao });
    } else if (data.length === 2) {
      this.setState({
        arrayEditar: data[0],
        showModalConsultaRecuperacao: false,
        arrayRoscaDupla: data,
        showModalEditar: true,
        idEditar: data[0].idrecuperacao,
        tipoRosca: 'dupla'
      });

    }
  }

  async buscarEmAndamento(op) {
    if (op == 1) {
      const andamento = await api.get('/get/consultarRecuperacaoStatus1');
      let newArrayAndamento = []
      newArrayAndamento = this.montarTemplateTipo(andamento.data)
      this.setState({ arrayConsultarRecuperacao: newArrayAndamento })
    }
    if (op == 2) {
      const concluidas = await api.post('/recuperacao/filtrarEtapasConcluidas');
      let newArrayConcluidas = []
      newArrayConcluidas = this.montarTemplateTipo(concluidas.data)
      this.setState({ arrayConsultarRecuperacao: newArrayConcluidas })
    }
  }

  async handleChange(op) {
    if (op == 1) {
      this.setState({ checkedA: !this.state.checkedA, checkedB: false, });
      this.buscarEmAndamento(1);
    }
    if (op == 2) {
      this.setState({ checkedA: false, checkedB: !this.state.checkedB });
      this.buscarEmAndamento(2);
    }

  }

  async handleChangeChartPie(op) {
    if (op == 1) {
      this.setState({ checkedA: !this.state.checkedA, checkedB: false, });
    }
    if (op == 2) {
      this.setState({ checkedA: false, checkedB: !this.state.checkedB });
    }

    this.setState({
      options:
      {
        labels: [], colors: [], responsive: [{
          breakpoint: 480,
          options: {
            chart: { width: 200 },
            legend: { position: 'bottom' }
          }
        }]
      },
      series: [], idEtapa_validacao: [], tempoTotal: ''
    })

    await this.setState({ arrayEditar: this.state.arrayRoscaDupla[op - 1] });
    this.validarEtapas();
    this.buscarTempos();
  }

  alterarTipoOpcao = (selectedOption) => {
    if (selectedOption && selectedOption.label === "Dupla Rosca Cônica") {
      // When "Dupla Rosca Cônica" is selected
      this.setState(
        {
          tipo: selectedOption,
          conica: 1,
        },
        () => {
          // Log the updated state after it's been set
          console.log("Tipo", this.state.tipo.label);
          console.log("Conica", this.state.conica);
        }
      );
    } else {
      // When other options are selected
      this.setState(
        {
          tipo: selectedOption ? selectedOption : "",
          conica: false,
        },
        () => {
          // Log the updated state after it's been set
          console.log("Tipo", this.state.tipo ? this.state.tipo.label : "");
          console.log("Conica", this.state.conica);
        }
      );
    }
  };

  handleDetalhes(label) {
    if (label === 'Brunimento') {
      if (!this.state.nitretacao) {
        this.setState({
          brunimento: !this.state.brunimento,
          nitretacao: !this.state.nitretacao
        })
      }
    }
    if (label === 'Nitretação') {
      this.setState({
        nitretacao: !this.state.nitretacao
      })
      if (this.state.brunimento) {
        this.setState({ brunimento: false });
      }
    }
    if (label === 'Emenda') {
      this.setState({
        emenda: !this.state.emenda
      })
    }
    if (label === 'Aplicação Nano Cerâmica') {
      this.setState({
        nano_ceramica: !this.state.nano_ceramica
      })
    }
    if (label === 'Cromagem/Descromagem') {
      this.setState({
        cromagem_descromagem: !this.state.cromagem_descromagem
      })
    }
    if (label === 'Conica') {
      this.setState({
        conica: !this.state.conica
      })
    }
    if (label === 'Desempenamento') {
      this.setState({
        desempenamento: !this.state.desempenamento
      })
    }
  }

  createInfo = () => {
    if (this.state.diametro && this.state.folga) {
      this.setState({
        infoBanco:
          `Recuperação dos filetes ao longo de ${this.state.comprimento_recuperado}mm com diâmetro nominal ${(parseFloat(this.state.diametro.replace(',', '.').replace(' ', '')).toFixed(2) - parseFloat(this.state.folga.replace(',', '.').replace(' ', ''))).toFixed(2)}mm`
      });
    }
    if (this.state.diametro_menor && this.state.diametro_maior) {
      this.setState({
        infoBanco:
          `Recuperação dos filetes com diametro: ${this.state.diametro_menor} / ${this.state.diametro_maior}`
      });
    }
  }

  handleInput = async (event) => {
    const { value, name } = event.target;
    await this.setState({ [name]: value });
    this.createInfo();
  }

  handleChangeInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  //Cria um filtro no Select "Tipo" a partir do value conica dentro da propriedade onChange() para que caso o tipo seja "Dupla Rosca Cônica, adicione o valor 1 na coluna 'conica"
  // handleChange(event) {
  //   this.setState({ tipo: event.value });
  //   if (event.value === "Dupla Rosca Cônica") {
  //     this.setState({ conica: 1 });
  //   } else {
  //    this.setState({ conica: 0 });
  //   }
  // }

  handleItemSelecionado = (itemSelecionado) => {
    this.setState({ arrayEditar: itemSelecionado })
  }

  handleChangeModalRelatorio = () => {
    this.setState({
      openModalTelaRelatorio: !this.state.openModalTelaRelatorio,
      showModalConsultaRecuperacao: !this.state.showModalConsultaRecuperacao
    });
  }

  handleModalChangeSideAndVerifyIsClose = () => {
    this.setState({ modalChangeSide: !this.state.modalChangeSide, showModalConsultaRecuperacao: !this.state.showModalConsultaRecuperacao });
  }
  //Toda esta função trata do modal "Consultar Recuperação", caminho: Recuperação > Ações > Consulta de Recuperação
  telaRelatorio = async (ordem_servico) => {
    const { data } = await api.post('/posts/consultarRecuperacaoOS', { ordem_servico });
    this.setState({ dadosOsRelatorio: data })
    if (data.length > 1) {
      this.setState({ dadosOsLadoEscolhido: data });
      this.handleModalChangeSideAndVerifyIsClose();
    }
    else {
      this.setState({ dadosOsLadoEscolhido: data[0] });
      this.handleChangeModalRelatorio();
    }
  }

  setarDadosRelatorioEAbrirModal = async (dados) => {
    this.setState({ dadosOsLadoEscolhido: dados });
    this.handleChangeModalRelatorio();
  }

  render() {
    const self = this;

    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">
          {this.state.arrayPreCadastro.length}
        </span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );

    //Seta os ícones e título dos botões da coluna "Ações"
    const botoes = (cell, row, rowIndex) => {
      return (
        <div>

          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Gráfico de Pizza"
            onClick={() =>
              this.editarTabela(row.ordem_servico)
            }
            className="fa fa-pie-chart"
            style={{ fontSize: 20, cursor: 'pointer' }}
          />

          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Gráfico Comparativo"
            onClick={() =>
              this.graficoComparativo(row.ordem_servico)
            }
            className="fa fa-bar-chart"
            style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
          />
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Editar Recuperação"
            onClick={() =>
              this.editarRecuperacao(
                row.ordem_servico
              )
            }
            className="fa fa-edit"
            style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
          />
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Relatório da Recuperação"
            onClick={() =>
              this.telaRelatorio(
                row.ordem_servico
              )
            }
            className="fa fa-file-text-o"
            style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
          />
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Excluir Recuperação"
            onClick={() =>
              this.deletarOS(
                row.ordem_servico
              )
            }
            className="fa fa-trash"
            style={{ color: '#c5152f', fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
          />
        </div>
      );
    };

    //Seta o nome das colunas da tabela Consulta de Recuperação
    const columns = [
      {
        dataField: 'ordem_servico',
        text: 'OS',
      },
      {
        dataField: 'cliente',
        text: 'Cliente',
      },
      {
        dataField: 'tipo_template',
        text: 'Tipo',
      },
      {
        dataField: 'date',
        text: 'Data',
      },
      {
        dataField: 'button',
        text: 'Ações',
        formatter: botoes,
      },
    ];
    if (localStorage.getItem('login') == 'on') {
      return (
        <div>
          <BarChart
            open={this.state.modalCompare}
            itemSelecionado={this.state.arrayEditar}
            close={this.closeModal}
            id_recuperacao={this.state.id_recuperacao_editar}
            tipoRosca={this.state.tipoRosca}
            arrayRoscaDupla={this.state.arrayRoscaDupla}
            handleItemSelecionado={this.handleItemSelecionado}
          />
          {this.state.showModalEditar ?
            <EditarRecuperacao
              itemSelecionado={this.state.arrayEditar}
              open={this.state.showModalEditar}
              close={this.closeModal}
              tipoRosca={this.state.tipoRosca}
              arrayRoscaDupla={this.state.arrayRoscaDupla}
              handleItemSelecionado={this.handleItemSelecionado}
            />
            : null}
          <Modal
            show={this.state.showAlerta}
            onHide={() => this.setState({ showAlerta: false, showModalConsultaRecuperacao: true })}
            size="sm"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>Alerta!</Modal.Header>
            <Modal.Body
              style={{
                // background: 'transparent',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                <p>Deseja deletar ordem de serviço {this.state.os_deletar}?</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setState({ showAlerta: false, showModalConsultaRecuperacao: true })} variant="primary">Cancelar</Button>
              <Button onClick={() => this.deletar_OS()} variant="primary">Sim</Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.showLoading}
            onHide={this.handleClose}
            size="sm"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>Carregando...</Modal.Header>
            <Modal.Body
              style={{
                // background: 'transparent',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                <BounceLoader
                  css={override}
                  sizeUnit={'px'}
                  size={60}
                  color={'#123b7a'}
                  loading={this.state.loading}
                />

              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showModalConfirmar}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Alerta</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Salvo com sucesso!</p>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={() => this.mudarPage()} variant="primary">Ok</Button>
            </Modal.Footer>
          </Modal>
          {/* Aqui acaba o Modal "Consulta Recuperação" */}
          <Navbar fluid>
            <Navbar.Header>
              {/* Para alterações no título "Recuperação", só alterar o Navbar */}
              <Navbar.Brand>
                <a href="#pablo" style={{textTransform: 'uppercase'}}>Recuperação de Rosca</a>
              </Navbar.Brand>
              <Navbar.Toggle onClick={this.mobileSidebarToggle} />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav>
                <NavDropdown
                  eventKey={2}
                  title={notification}
                  noCaret
                  id="basic-nav-dropdown"
                >
                  {this.state.carregou ?
                    this.state.arrayPreCadastro.map((item) => (
                      <MenuItem eventKey={2.1} onClick={() => self.buscar(item)}>Ordem de Serviço: {item.ordem_servico}</MenuItem>
                    ))
                    :
                    <MenuItem eventKey={2.1}>Nenhum Cadastro</MenuItem>
                  }
                </NavDropdown>
              </Nav>
              <Nav pullRight>
                <NavDropdown
                  eventKey={2}
                  title="Ações"
                  id="basic-nav-dropdown-right"
                >
                  <MenuItem
                    onClick={() =>
                      this.consultarRecuperacao()
                    }
                    eventKey={2.1}
                  >
                    Consultar Recuperações
                  </MenuItem>
                </NavDropdown>
                <NavItem eventKey={3} href="#" onClick={() => this.sair()} >
                  Sair
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {/* modal de gráfico de pizza*/}
          <Modal
            show={this.state.showModalChart}
            onHide={() => this.limparDados()}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title><p style={{ fontWeight: 'bold', marginBottom: 0 }}>Ordem de Serviço: {this.state.ordem_servico_consulta}</p>
                <p style={{ fontSize: 12, marginBottom: 0 }}>Cliente: {this.state.cliente_consulta} Tipo: {this.state.tipo_consulta}</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.tipoRosca === 'dupla' ?
                <>
                  <FormControlLabel
                    control={
                      <CheckBox
                        checked={this.state.checkedA}
                        onChange={() => this.handleChangeChartPie(1)}
                        value="checkedB"
                        color="primary"
                        type="radio"
                        id="checar"
                      />
                    }
                    label={<p style={{ fontSize: '14px', margin: '10px', fontWeight: 'bold' }}>Lado Esquerdo</p>}
                  />
                  <FormControlLabel
                    control={
                      <CheckBox
                        checked={this.state.checkedB}
                        onChange={() => this.handleChangeChartPie(2)}
                        value="checkedB"
                        color="primary"
                        type="radio"
                        id="checar"
                      />
                    }
                    label={<p style={{ fontSize: '14px', margin: '10px', fontWeight: 'bold' }}>Lado Direito</p>}
                  />
                </>
                : null}
              <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width="550" />
              {/* <p>Tempo total: {this.state.tempoTotal}</p> */}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  this.limparDados()}
              >
                Sair
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Modal consultar recuperação */}
          <Modal
            show={this.state.showModalConsultaRecuperacao}
            onHide={() => this.setState({ showModalConsultaRecuperacao: false, checkedB: false, checkedA: true })}
            className="modalRecuperacao"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Consulta de Recuperação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                <ToolkitProvider
                  keyField="id"
                  data={this.state.arrayConsultarRecuperacao}
                  columns={columns}
                  search
                >
                  {props => (
                    <div style={{ textAlign: 'center' }}>
                      <SearchBar
                        placeholder="Pesquisar"
                        id="search_txt"
                        style={{ width: 470, marginRight: 10 }}
                        {...props.searchProps}
                      />
                      <ClearSearchButton text="Limpar" {...props.searchProps} />
                      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                        <FormControlLabel
                          control={
                            <CheckBox
                              checked={this.state.checkedA}
                              onChange={() => this.handleChange(1)}
                              value="checkedB"
                              color="primary"
                              type="radio"
                              id="checar"
                            />
                          }
                          label="Em andamento"
                        />
                        <FormControlLabel
                          control={
                            <CheckBox
                              checked={this.state.checkedB}
                              onChange={() => this.handleChange(2)}
                              value="checkedB"
                              color="primary"
                              type="radio"
                              id="checar"
                            />
                          }
                          label="Concluídas"
                        />

                      </div>
                      <BootstrapTable
                        pagination={paginator()}
                        {...props.baseProps}
                      />
                      <ExportCSVButton {...props.csvProps}>
                        Exportar Excel
                      </ExportCSVButton>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({ showModalConsultaRecuperacao: false })
                }
              >
                Sair
              </Button>
            </Modal.Footer>
          </Modal>
          <ModalAlert
            show={this.state.modalChangeSide}
            handleModal={this.handleModalChangeSideAndVerifyIsClose}
            mainText="Qual lado deseja visualizar?"
            buttons={
              <>
                <Button onClick={() => this.setarDadosRelatorioEAbrirModal(this.state.dadosOsRelatorio[0])}>Esquerdo</Button>
                <Button onClick={() => this.setarDadosRelatorioEAbrirModal(this.state.dadosOsRelatorio[1])}>Direito</Button>
              </>
            }
          />
          {this.state.openModalTelaRelatorio ?
            <ModalRelatorio
              open={this.state.openModalTelaRelatorio}
              handleClose={this.handleChangeModalRelatorio}
              dadosOs={this.state.dadosOsLadoEscolhido}
            />
            : null}
          {!this.state.loadingCadastroRecuperacao ?
            <div className="content" style={{ marginTop: '3%' }}>
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    <Card
                      content={
                        <form ref={(el) => this.myFormRef = el}>
                          <div class="title">
                            <h3>Cadastro de Recuperação - Ordem de Serviço: <input type="text" readOnly value={self.state.ultimaOS} /></h3>
                          </div>
                          <Col md={3} style={{ paddingLeft: '0', width: '29.5%' }}>
                            <FormGroup>
                              <ControlLabel>Clientes</ControlLabel>
                              <Select
                                placeholder="Selecione o Cliente"
                                onChange={(event) => this.setState({ clienteSelecionado: event })}
                                value={this.state.clienteSelecionado}
                                options={this.state.clientes} />
                            </FormGroup>
                          </Col>
                          <div style={{ display: this.state.tipo.label === "Dupla Rosca Cônica" ? 'block' : 'none' }}>
                            <FormInputs
                              ncols={['col-md-2', 'col-md-2', 'col-md-3']}
                              properties={[
                                {
                                  label: 'Diâmetro Menor',
                                  type: 'text',
                                  bsClass: 'form-control',
                                  name: 'diametro_menor',
                                  onChange: self.handleInput,
                                  display: 'block',
                                  style: { width: '80%', marginLeft: '-1%' }
                                },
                                {
                                  label: 'Diâmetro Maior',
                                  type: 'text',
                                  bsClass: 'form-control',
                                  name: 'diametro_maior',
                                  onChange: self.handleInput,
                                  display: 'block',
                                  style: { width: '80%' }
                                },
                                {
                                  label: 'Nº do Orçamento',
                                  type: 'text',
                                  bsClass: 'form-control',
                                  name: 'codigo',
                                  onChange: self.handleInput,
                                  style: { marginLeft: '0.8%' }
                                }
                              ]}
                            />
                          </div>
                          <div style={{ display: this.state.tipo.label === "Dupla Rosca Cônica" ? 'none' : 'block' }}>
                            <FormInputs
                              ncols={['col-md-3', 'col-md-3']}
                              properties={[
                                {
                                  label: 'Diâmetro',
                                  type: 'text',
                                  bsClass: 'form-control',
                                  name: 'diametro',
                                  onChange: self.handleInput,
                                  defaultValue: this.state.tipo.label === "Dupla Rosca Cônica" ? 0 : self.handleInput,
                                },
                                {
                                  label: 'Nº do Orçamento',
                                  type: 'text',
                                  bsClass: 'form-control',
                                  name: 'codigo',
                                  onChange: self.handleInput
                                }
                              ]}
                            />
                          </div>
                          <FormInputs
                            ncols={['col-md-3', 'col-md-3', 'col-md-3']}
                            properties={[
                              {
                                label: 'Data Entrada',
                                type: 'date',
                                bsClass: 'form-control',
                                onChange: self.data_inicial,
                                defaultValue: self.state.data
                              },
                              {
                                label: 'Comprimento Total',
                                type: 'text',
                                bsClass: 'form-control',
                                name: 'comprimento_total',
                                onChange: self.handleInput,
                                defaultValue: self.comprimento_total
                              },
                              {
                                label: 'NF - Entrada',
                                type: 'text',
                                bsClass: 'form-control',
                                name: 'nf_entrada',
                                onChange: self.handleInput,
                                defaultValue: self.nf_entrada
                              }
                            ]}
                          />
                          <FormInputs
                            ncols={['col-md-3', 'col-md-3', 'col-md-3']}
                            properties={[
                              {
                                label: 'Previsão',
                                type: 'date',
                                bsClass: 'form-control',
                                name: 'previsao',
                                onChange: self.previsao,
                                defaultValue: self.state.previsao
                              },
                              {
                                label: 'Comprimento Recuperado',
                                type: 'text',
                                bsClass: 'form-control',
                                name: 'comprimento_recuperado',
                                onChange: self.handleInput,
                                defaultValue: self.comprimento_recuperado
                              },
                              {
                                label: 'NF - Saída',
                                type: 'text',
                                bsClass: 'form-control',
                                name: 'nf_saida',
                                onChange: self.handleInput,
                                defaultValue: self.nf_saida
                              }
                            ]}
                          />
                          <FormInputs
                            ncols={['col-md-3', 'col-md-3']}
                            properties={[
                              {
                                label: 'Data Saída',
                                type: 'date',
                                bsClass: 'form-control',
                                name: 'data_final',
                                onChange: self.data_final,
                                defaultValue: self.state.data_final
                              },
                              {
                                label: 'Folga',
                                type: 'text',
                                bsClass: 'form-control',
                                name: 'folga',
                                onChange: self.handleInput,
                                defaultValue: this.state.tipo.label === "Dupla Rosca Cônica" ? 0 : self.handleInput
                              }
                            ]}
                          />
                          <Col md={3} style={{ paddingLeft: '0px', width: '29.5%', textAlign: 'left' }}>
                            <FormGroup>
                              <ControlLabel>Tipo</ControlLabel>
                              <Select
                                style={{color: '#ffffff'}}
                                placeholder="Selecione o tipo de rosca"
                                value={this.state.tipo}
                                onChange={this.alterarTipoOpcao}
                                options={[
                                  { value: "Mono Rosca", label: "Mono rosca" },
                                  { value: "Rosca Dupla", label: "Dupla rosca" },
                                  { value: "Rosca Dupla", label: "Dupla Rosca Cônica" }
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3} style={{ marginLeft: '1px', width: '31%' }}>
                            <FormGroup>
                              <ControlLabel>Almofada</ControlLabel>
                              <Select
                                placeholder="Selecione a almofada"
                                onChange={(event) => this.setState({ almofadaSelecionada: event.value })}
                                options={this.state.almofada}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3} style={{ paddingLeft: '2px', width: '30%' }}>
                            <FormGroup>
                              <ControlLabel>Revestimento Duro</ControlLabel>
                              <Select
                                placeholder="Selecione o revestimento duro"
                                onChange={(event) => this.setState({ revestimentoDuroSelecionado: event.value })}
                                options={this.state.revestimentoDuro}
                              />
                            </FormGroup>
                          </Col>
                          <Row>
                            <Col md="12">
                              <label>Informações Gerais</label>
                              <textarea
                                class="form-control"
                                placeholder={`Recuperação dos filetes ao longo de ${this.state.comprimento_recuperado}mm com diâmetro nominal (${this.state.diametro - parseFloat(this.state.folga)})mm`}
                                style={{ width: '100%', resize: 'vertical', color: 'rgb(113,121,124)' }} value={this.state.infoBanco}
                                onChange={val => this.setState({ infoBanco: val.target.value })}></textarea>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div style={{ flexDirection: 'row' }}>
                                <FormGroup controlId="formControlsTextarea">
                                  <ControlLabel>Selecione a(s) etapa(s)</ControlLabel>
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'inline-block', width: '25%', borderRight: '#888888 solid' }}>
                                      <h3 style={{ textAlign: 'center' }}>SETOR SOLDA</h3>
                                      <div class="labelSoldarPonteira" style={{ display: 'inline-flex', width: '90%', marginLeft: '5%' }}>
                                        <Checkbox style={{ width: '60%', fontSize: '18px' }} label='1 - Soldar Ponteira' onClick={() => this.check(0)} />
                                        <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[0]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 0)} type="text" />
                                      </div>
                                      <div class="labelSoldaAlmofada" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                        <Checkbox style={{ width: '60%' }} label='4 - Solda Almofada' onClick={() => this.check(3)} />
                                        <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[3]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 3)} type="text" />
                                      </div>
                                      <div class="labelSoldaRevestimento" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                        <Checkbox style={{ width: '60%' }} label='6 - Solda Revestimento' onClick={() => this.check(5)} />
                                        <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[5]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 5)} type="text" />
                                      </div>

                                      <div class="labelRetrabalho" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                        <Checkbox style={{ width: '60%' }} label='Retrabalho' onClick={() => this.check(11)} />
                                        <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[11]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 11)} type="text" />
                                      </div>
                                    </div>
                                    <div style={{ width: '25%', borderRight: '#888888 solid' }}>
                                      <div>
                                        <h3 style={{ textAlign: 'center' }}>SETOR USINAGEM</h3>
                                        <div class="labelAlinhamento" style={{ display: 'inline-flex', width: '90%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='2 - Alinhamento' onClick={() => this.check(1)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[1]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 1)} type="text" />
                                        </div>
                                        <div class="labelUsinagemDesbaste" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='3 - Usinagem Desbaste' onClick={() => this.check(2)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[2]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 2)} type="text" />
                                        </div>
                                        <div class="labelUsinagemAlmofada" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='5 - Usinagem Almofada' onClick={() => this.check(4)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[4]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 4)} type="text" />
                                        </div>
                                        <div class="labelUsinagemRevestimento" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='7 - Usinagem Revestimento' onClick={() => this.check(6)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[6]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 6)} type="text" />
                                        </div>
                                        <div class="labelUsinagemFinal" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='10 - Usinagem Final' onClick={() => this.check(10)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[10]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 10)} type="text" />
                                        </div>
                                        <div class="labelRetrabalho2" style={{ display: 'inline-flex', width: '90%', marginTop: '12%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='Retrabalho' onClick={() => this.check(11)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[11]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 11)} type="text" />
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ width: '25%', borderRight: '#888888 solid' }}>
                                      <div>
                                        <h3 class="labelLixamento" style={{ textAlign: 'center' }}>SETOR DESBASTE</h3>
                                        <div class="labelDesbasteLateral" style={{ display: 'inline-flex', width: '90%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='8 - Desbaste Lateral Filetes' onClick={() => this.check(8)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[8]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 8)} type="text" />
                                        </div>

                                        <div style={{ display: 'inline-flex', width: '90%', marginLeft: '5%', marginTop: '10%' }}>
                                          <Checkbox style={{ width: '60%' }} label='9 - Lixamento' onClick={() => this.check(7)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[7]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 7)} type="text" />
                                        </div>

                                        <div class="labelPolimento" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='11 - Polimento' onClick={() => this.check(9)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[9]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 9)} type="text" />
                                        </div>

                                        <div class="labelRetrabalho3" style={{ display: 'inline-flex', width: '90%', marginTop: '10%', marginLeft: '5%' }}>
                                          <Checkbox style={{ width: '60%' }} label='Retrabalho' onClick={() => this.check(11)} />
                                          <input style={{ height: 35, width: '20%', padding: 0, marginTop: '1%' }} class="form-control" disabled={this.state.habilitarInput[11]} defaultValue={'00:00'} min="00:00:00" max="500:00:00" onChange={(e) => this.updateInput(e, 11)} type="text" />
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ width: '25%', borderRight: '#888888 solid' }}>
                                      <div>
                                        <h3 style={{ textAlign: 'center' }}>SERVIÇOS EXTERNOS</h3>
                                        <Checkbox
                                          style={{ display: 'inline-flex', width: '80%', marginLeft: '5%' }}
                                          label='Brunimento'
                                          onClick={() => this.handleDetalhes('Brunimento')}
                                          checked={this.state.brunimento}
                                        />
                                        <Checkbox
                                          style={{ display: 'inline-flex', width: '80%', marginTop: '10%', marginLeft: '5%' }}
                                          label='Nitretação'
                                          onClick={() => this.handleDetalhes('Nitretação')}
                                          checked={this.state.nitretacao}
                                        />
                                        <Checkbox
                                          style={{ display: 'inline-flex', width: '80%', marginTop: '10%', marginLeft: '5%' }}
                                          label='Emenda'
                                          onClick={() => this.handleDetalhes('Emenda')}
                                          checked={this.state.emenda}
                                        />
                                        <Checkbox
                                          style={{ display: 'inline-flex', width: '80%', marginTop: '10%', marginLeft: '5%' }}
                                          label='Desempenamento'
                                          onClick={() => this.handleDetalhes('Desempenamento')}
                                          checked={this.state.desempenamento}
                                        />
                                        <Checkbox
                                          style={{ display: 'inline-flex', width: '80%', marginTop: '10%', marginLeft: '5%' }}
                                          label='Aplicação Nano Cerâmica'
                                          onClick={() => this.handleDetalhes('Aplicação Nano Cerâmica')}
                                          checked={this.state.nano_ceramica}
                                        />
                                        <Checkbox
                                          style={{ display: 'inline-flex', width: '80%', marginTop: '10%', marginLeft: '5%' }}
                                          label='Cromagem/Descromagem'
                                          onClick={() => this.handleDetalhes('Cromagem/Descromagem')}
                                          checked={this.state.cromagem_descromagem}
                                        />
                                        </div>
                                    </div>
                                  </div>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                          <Button
                            bsStyle="info"
                            onClick={() => this.cadastrar()}
                            pullRight
                            fill
                            disabled={this.state.habilitarBtnCadastrar}
                          >
                            Cadastrar
                          </Button>
                          <div className="clearfix" />
                        </form>
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            </div>
            : <Spinner />}
        </div>
      );
    } else {
      return (
        <Redirect to="/" from="" />
      )
    }
  }
}

export default Recuperacao;
