/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import api from '../../services/api';


class AdminNavbarLinks extends Component {
  state = {
    dolar: 0,
    yuan: 0,
  }

  sair() {
    localStorage.removeItem('login');
    localStorage.removeItem('usuario');
    this.props.props.history.push({ pathname: '/login' });
  }

  async componentDidMount() {
    const dolar = await api.get("https://economia.awesomeapi.com.br/all/USD");
    const yuan = await api.get("https://economia.awesomeapi.com.br/json/all/CNY-BRL");

    let dol = dolar.data.USD.ask.toString();
    dol = dol.slice(0, (dol.indexOf(".")) + 3);
    dol = 'R$ ' + dol;  

    let yuanConverterReal = yuan.data.CNY.ask.toString();
    yuanConverterReal = yuanConverterReal.slice(0, (yuanConverterReal.indexOf(".")) + 3);
    yuanConverterReal = 'R$ ' + yuanConverterReal
 
    this.setState({ dolar: dol, yuan: yuanConverterReal })

  }

  
  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">5</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    return (
      <div>
        <Nav pullRight>
          <NavItem>
            <p style={{fontSize: 16}}>Dólar: <strong>{this.state.dolar}</strong></p>
          </NavItem>
          <NavItem>
            <p style={{fontSize: 16}}>Yuan: <strong>{this.state.yuan}</strong></p>
          </NavItem>
          <NavItem eventKey={3} href="#" onClick={() => this.sair()}>
            Sair
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
