export const setarValoresDuploPorLado = (v1, v2, v3, v4, op, min, max) => {
  if (op == 1) {
    var option = {
      animationEnabled: true,
      title: {
        text: "",
      },
      axisX: {
        includeZero: true,
      },
      axisY: {
        title: "",
        includeZero: false,
        minimum: min,
        maximum: max,
      },
      data: [
        {
          markerType: "none",
          type: "spline",
          name: "Curva de Desgaste Cilindro Esquerdo",
          showInLegend: true,
          color: "#eb8d1b",
          dataPoints: v1,
        },
        {
          markerType: "none",
          type: "spline",
          name: "Nominal Cilindro Esquerdo",
          color: "#eb8d1b",
          showInLegend: true,
          dataPoints: v2,
          lineDashType: "dash",
        },
        {
          markerType: "none",
          type: "spline",
          name: "Curva de Desgaste Rosca Esquerda",
          showInLegend: true,
          dataPoints: v3,
          color: "#0a3974",
        },
        {
          markerType: "none",
          type: "spline",
          name: "Nominal Rosca Esquerda",
          showInLegend: true,
          dataPoints: v4,
          lineDashType: "dash",
          color: "#0a3974",
        },
      ],
    };
    return option;
  }
  if (op == 2) {
    var option = {
      animationEnabled: true,
      title: {
        text: "",
      },
      axisX: {
        includeZero: true,
      },
      axisY: {
        title: "",
        includeZero: false,
        minimum: min,
        maximum: max,
      },
      data: [
        {
          markerType: "none",
          type: "spline",
          name: "Curva de Desgaste Cilindro Direito",
          showInLegend: true,
          dataPoints: v1,
          color: "#eb8d1b",
        },
        {
          markerType: "none",
          type: "spline",
          name: "Nominal Cilindro Direito",
          lineDashType: "dash",
          showInLegend: true,
          dataPoints: v2,
          color: "#eb8d1b",
        },
        {
          markerType: "none",
          type: "spline",
          name: "Curva de Desgaste Rosca Direita",
          showInLegend: true,
          dataPoints: v3,
          color: "#0a3974",
        },
        {
          markerType: "none",
          type: "spline",
          name: "Nominal Rosca Direirta",
          lineDashType: "dash",
          showInLegend: true,
          dataPoints: v4,
          color: "#0a3974",
        },
      ],
    };
    return option;
  }
};
