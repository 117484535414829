
import { Card } from "components/Card/Card.jsx";
import moment from 'moment';
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import api from '../services/api';
import ChartEtapas from './Dashboard/ChartEtapas';
import ModalEstoque from './Dashboard/ModalEstoque';
import './Settings/styles/styles.css';
import { Redirect } from "react-router-dom";


const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? '#c5152f': 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 500,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}


class Dashboard extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  constructor(props) {
    super(props);
    this.closeModalEstoque = this.closeModalEstoque.bind(this);
  }

  state = {
    contAndamento: 0,
    series: [
      {
        name: "series-1",
        data: []
      },
      {
        name: "series-2",
        data: []
      }
    ],
    arrayOs: '',
    arrayCategorias: [],
    arrayDataPre: [],
    arrayDataTotal: [],
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: []
      }
    },
    arrayConsultaImportacao: '',
    botoes: [1, 1, 1, 1, 1, 1, 1],
    estoqueA: ["A205", "A204", "A203", "A202", "A201", "A105", "A104", "A103", "A102", "A101", "B105", "B104", "B103", "B102", "B101", "B205", "B204", "B203", "B202", "B201"],
    openModalEstoque: false,
    press: false,
    posicoesInseridas: [],
    defaultValueChart: '',
    OSRecuperacoes: false,
    osSelect: 0,
    optionsPie: {
      labels: [],
      colors: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
    seriesPie: '',
    series: '',
    tempoTotalPre: 0,
    tempoTotalPos: 0,
    colorsSet: '',
    cliente: '',
    data: '',
    tipo: '',
  }

  async buscarRecuperacoes() {
    const r1Andamento = await api.get('/get/consultarRecuperacaoStatus1')
    //contar qtde de recuperações em andamento e inserir OS's em array para pegar no select
    let contAuxAndamento = 0;
    if (r1Andamento.data.length > 0) {
      r1Andamento.data.map(item => {
        this.setState({ arrayOs: [...this.state.arrayOs, { value: item.idrecuperacao, label: item.ordem_servico }] })
        contAuxAndamento++;
      })
      let arrayTemp = this.state.arrayOs.sort((a, b) => b.label - a.label);
      // console.log("array temp: ", arrayTemp[0].label)
      await this.setState({ defaultValueChart: arrayTemp[0], osSelect: arrayTemp[0].label });
      this.handleChange(this.state.arrayOs[0]);
      this.setState({ contAndamento: contAuxAndamento, arrayOs: arrayTemp })
    } else {
      this.setState({ OSRecuperacoes: true })
    }
  }

  async buscarDolar() {
    const dolar = await api.get("https://economia.awesomeapi.com.br/all/USD");
    let dol = dolar.data.USD.ask.toString();
    dol = dol.slice(0, (dol.indexOf(".")) + 5);
    dol = '$' + dol;
    this.setState({ dolar: dol })
  }

  async preencherGrafico(data, pos, data2) {
    const state = this.state;
    let tempoPos;
    if (pos == 1) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Soldar Ponteira e Alinhamento'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Soldar Ponteira e Alinhamento', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Soldar Ponteira e Alinhamento'], colors: [...this.state.optionsPie.colors, '#ff0000'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    //teste2
    if (pos == 2) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Usinagem Desbaste'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Usinagem Desbaste', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Usinagem Desbaste'], colors: [...this.state.optionsPie.colors, '#ff4000'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    if (pos == 3) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Solda Almofada'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Solda Almofada', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Solda Almofada'], colors: [...this.state.optionsPie.colors, '#ff8000'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    //teste
    if (pos == 4) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Usinagem Almofada'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Usinagem Almofada', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Usinagem Almofada'], colors: [...this.state.optionsPie.colors, '#ffbf00'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    if (pos == 5) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Solda Revestimento'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Solda Revestimento', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Solda Revestimento'], colors: [...this.state.optionsPie.colors, '#ffff00'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    if (pos == 6) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Usinagem Revestimento'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Usinagem Revestimento', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Usinagem Revestimento'], colors: [...this.state.optionsPie.colors, '#00bfff'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    if (pos == 7) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Lixamento'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Lixamento', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Lixamento'], colors: [...this.state.optionsPie.colors, '#00ffff'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    if (pos == 8) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Desbate Lateral Filetes'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Desbate Lateral Filetes', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Desbate Lateral Filetes'], colors: [...this.state.optionsPie.colors, '#00bfff'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    if (pos == 9) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Polimento'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Polimento', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Polimento'], colors: [...this.state.optionsPie.colors, '#0080ff'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }
    if (pos == 10) {
      this.setState({ tempoTotalPre: this.state.tempoTotalPre + moment.duration(data).asHours() })
      if (data2 != null)
        tempoPos = data2.replace(/:/g, ".");
      if (data2 == '' || data2 == null) {
        data2 = 0;
      }
      this.setState({ tempoTotalPos: this.state.tempoTotalPos + parseFloat(data2) })
      this.setState({
        arrayCategorias: [...state.arrayCategorias, 'Usinagem Final'],
        arrayDataTotal: [...state.arrayDataTotal, { name: 'Usinagem Final', data: [tempoPos] }],
        optionsPie: { labels: [...this.state.optionsPie.labels, 'Usinagem Final'], colors: [...this.state.optionsPie.colors, '#8000ff'], responsive: [{ breakpoint: 480, options: { chart: { width: 350 }, legend: { position: 'bottom' } } }] },
        seriesPie: [...this.state.seriesPie, moment.duration(data).asHours()]
      })
    }

  }

  preencherArrayGrafico() {
    this.setState({
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        colors: this.state.colorsSet,
        xaxis: {
          categories: [this.state.osSelect],
          labels: {
            style: {
               color: "#f0f0f0f0",
              fontSize: '13px',
              fontFamily: 'Nunito',
              cssClass: 'apexcharts-xaxis-label',
            }
          }
        },
        yaxis: {
          show: true,
          showAlways: true,
          opposite: false,
          reversed: false,
          logarithmic: false,
          tickAmount: 6,
          max: 10000,
          forceNiceScale: true,
          floating: false,
          decimalsInFloat: undefined,
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
          },
          axisBorder: {
            show: true,
            color: '#f0f0f0',
            offsetX: 0,
            offsetY: 0
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#f0f0f0',
            width: 6,
            offsetX: 0,
            offsetY: 0
          },
          title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          crosshairs: {
            show: true,
            position: 'back',
            stroke: {
              color: '#f0f0f0',
              width: 1,
              dashArray: 0,
            },
          },
          tooltip: {
            enabled: true,
            offsetX: 0,
          },
        }
      }
    })
    this.setState({
      series: this.state.arrayDataTotal,
    })

  }
  async limparGrafico() {
    await this.setState({
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: []
        },
        yaxis: {}
      },
      series: [],
      arrayDataPre: '',
      arrayDataTotal: '',
      arrayCategorias: '',
      arrayPos: '',
      optionsPie: {
        labels: [],
        colors: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesPie: '',
      tempoTotalPre: 0,
      tempoTotalPos: 0
    })
  }

  //teste
  async handleChange(e) {
    await this.limparGrafico();
    this.setState({ defaultValueChart: e, osSelect: e.label })
    const r = await api.post('/posts/consultarRecuperacao', { idrecuperacao: e.value })

    this.setState({ cliente: r.data[0].cliente, data: r.data[0].data, tipo: r.data[0].tipo })

    const response = await api.post('/recuperacao/buscarTemposPre', {
      idrecuperacao: e.value
    })
    const data = response.data;

    const response2 = await api.post('/recuperacao/buscarTempoTotal', {
      idrecuperacao: e.value
    })
    const data2 = response2.data;


    data.forEach(obj => {
      data2.forEach(obj2 => {
        for (let i = 1; i < 11; i++) {
          if (obj[`t${i}`] != null || obj2[`t${i}`] != null)
            this.preencherGrafico(obj[`t${i}`], i, obj2[`t${i}`])
        }
      })
    })
    this.setState({ colorsSet: this.state.optionsPie.colors })

    if (this.state.tempoTotalPos < this.state.tempoTotalPre) {

      let tempoFaltante = this.state.tempoTotalPre - this.state.tempoTotalPos
      let colors = this.state.optionsPie.colors;
      colors.push("black");
      this.setState({
        colorsSet: colors,
        arrayDataTotal: [...this.state.arrayDataTotal, { name: 'Tempo Faltante', data: [tempoFaltante.toFixed(2)] }],
      })
    }
    this.preencherArrayGrafico();

  }

  async carregarBotoes() {
    for (let i = 0; i < 20; i++) {
      await this.setState({ botoes: [...this.state.botoes, i] })
    }
  }

  async buscarReservadas() {
    const r = await api.get("/stock/buscarReservadas");
    var arrayTemp = [];
    r.data.map(item => {
      arrayTemp.push(item.id_posicao);
    })
    await this.setState({ posicoesInseridas: arrayTemp });

  }

  async componentDidMount() {
    // await this.buscarRecuperacoes();
    await this.buscarDolar();
    // this.consultarImportacoes();
    this.carregarBotoes();
    this.buscarReservadas();

    // setTimeout(() => {
    //   window.location.reload();
    // }, 50000)
  }

  async consultarImportacoes() {
    const responseConsultarImportacao = await api.get(
      '/get/consultarImportacao'
    );
    await this.setState({ arrayConsultaImportacao: responseConsultarImportacao.data });

  }

  async openEstoque(i, item) {
    var arrayTemp = [];
    if (i < 5)
      arrayTemp.push(i + 5);
    if (i > 4 && i < 10)
      arrayTemp.push(i - 5);
    if (i >= 10 && i < 15)
      arrayTemp.push(i + 5);
    if (i >= 14)
      arrayTemp.push(i - 5);

    var arrayEstoque = [{ posSelecionada: i, arrayPos: arrayTemp }]
    await this.setState({ openModalEstoque: true, arrayPos: arrayEstoque });
  }

  async closeModalEstoque() {
    await this.setState({ openModalEstoque: false, arrayPos: '' });
  }

  verifyIsNumberEqual(i) {
    return this.state.posicoesInseridas.includes(i);
  }

  render() {
    const columns = [
      {
        dataField: 'orcamento',
        text: 'Orçamento',
      },
      {
        dataField: 'pedido',
        text: 'Pedido',
      },
      {
        dataField: 'cliente',
        text: 'Cliente',
      },
      {
        dataField: 'tipo',
        text: 'Tipo',
      },
    ];

    if (localStorage.getItem('login') == 'on') {
    return (
      <div className="content">
        <ModalEstoque open={this.state.openModalEstoque} close={this.closeModalEstoque} dados={this.state.arrayPos} backdrop="static"
        keyboard={false} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="RECUPERAÇÃO DE ROSCA"
                // category="Gráfico"
                // stats="Atualizado"
                content={
                    <ChartEtapas />
                }
              />
            </Col>
            {/* <Col md={12}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Importação"
                // category="Gráfico"
                // stats="Atualizado"
                content={
                  <div className="ct-chart" style={{ marginBottom: '30%', alignItems: 'center', justifyContent: 'center' }}>
                    <BootstrapTable
                      keyField="id"
                      pagination={paginator()}
                      data={this.state.arrayConsultaImportacao}
                      columns={columns}
                    />
                  </div>
                }
              />
            </Col> */}

          </Row>

          {/* <Row>
            <Col md={12}>
              <Card
                // id="chartActivity"
                title="Estoque"
                // category="All products including Taxes"
                stats="Atualizado"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart" style={{ textAlign: 'center' }}>
                    <div style={{ flexDirection: 'row', display: 'flex', textAlign: 'center', justifyContent: 'center', }}>
                      <div>
                        {this.state.estoqueA.map((item, index) => {
                          if (index < 5) {
                            return (
                              <div style={{ textAlign: 'center' }}>
                                <Button className={this.verifyIsNumberEqual(index) ? "reservado" : "livre"} onClick={() => this.openEstoque(index, item)} style={{ marginBottom: 7, marginLeft: 10 }}>{item}</Button>
                              </div>
                            )
                          }
                        })}
                      </div>
                      <div >
                        {this.state.estoqueA.map((item, index) => {
                          if (index >= 5 && index <= 9) {
                            return (
                              <div style={{ textAlign: 'center' }}>
                                <Button className={this.verifyIsNumberEqual(index) ? "reservado" : "livre"} onClick={() => this.openEstoque(index, item)} style={{ marginBottom: 7, marginLeft: 15 }}>{item}</Button>
                              </div>
                            )
                          }
                        })}
                      </div>
                      <div >
                        {this.state.estoqueA.map((item, index) => {
                          if (index >= 10 && index <= 14) {
                            return (
                              <div style={{ textAlign: 'center' }}>
                                <Button className={this.verifyIsNumberEqual(index) ? "reservado" : "livre"} onClick={() => this.openEstoque(index, item)} style={{ marginBottom: 7, marginLeft: 15 }}>{item}</Button>
                              </div>
                            )
                          }
                        })}
                      </div>
                      <div >
                        {this.state.estoqueA.map((item, index) => {
                          if (index >= 15 && index <= 19) {
                            return (
                              <div style={{ textAlign: 'center' }}>
                                <Button className={this.verifyIsNumberEqual(index) ? "reservado" : "livre"} onClick={() => this.openEstoque(index)} style={{ marginBottom: 7, marginLeft: 15 }}>{item}</Button>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                }
              // legend={
              //   <div className="legend">{this.createLegend(legendBar)}</div>
              // }
              />
            </Col>

            <Col md={6}>

            </Col>
          </Row> */}
        </Grid>
      </div>
    );
  } else {
    return (
      <Redirect to="/" from="" />
    )
  }
}
}

export default Dashboard;
