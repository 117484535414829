import React, { useEffect, useState } from 'react';
import api from 'services/api';
import Chart from "react-apexcharts";
import './index.css';

function BarChartCompare(props) {
    const { selectItem } = props;
    const [count, setCount] = useState(0);

    const [arrayCategories, setArrayCategories] = useState([]);
    const [arrayLabel, setArrayLabel] = useState([]);
    const [arrayDataPre, setArrayDataPre] = useState([]);
    const [arrayDataTotal, setArrayDataTotal] = useState([]);
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);
    const numberOfStep = 12;

    var arrayAuxCategories = [];
    var arrayAuxLabel = [];
    var arrayAuxDataPre = [];
    var arrayAuxDataTotal = [];

    const labels = [
        'Soldar Ponteira',
        'Alinhamento',
        'Usinagem Desbaste',
        'Solda Almofada',
        'Usinagem Almofada',
        'Solda Revestimento',
        'Usinagem Revestimento',
        'Lixamento',
        'Desbaste Lateral Filetes',
        'Polimento',
        'Usinagem Final',
        'Retrabalho'
    ]

    const timePre = async () => {
        const response = await api.post('/recuperacao/buscarTemposPre', {
            idrecuperacao: selectItem.idrecuperacao
        })
        const data = response.data;

        const response2 = await api.post('/recuperacao/buscarTempoTotal', {
            idrecuperacao: selectItem.idrecuperacao
        })
        const data2 = response2.data;

        data.forEach(obj => {
            data2.forEach(obj2 => {
                for (let i = 0; i < 12; i++) {
                    if (obj[`t${i}`] != null || obj2[`t${i}`] != null)
                        preencherGrafico(obj[`t${i}`], i, obj2[`t${i}`])
                }
            })
        })
    }

    const preencherGrafico = async (data, pos, data2) => {
        setCount(count + 1);
        const countStep = count + 1;
        let tempoPos;

        for (let i = 0; i < numberOfStep; i++) {
            if (pos === i) {
                let tempoPre = data.replace(/:/g, ".");
                if (data2 !== null) {
                    tempoPos = data2.replace(/:/g, ".");
                }
                arrayAuxCategories = [...arrayAuxCategories, countStep];
                arrayAuxLabel = [...arrayAuxLabel, { nome: labels[i], dataPre: tempoPre, dataPos: tempoPos }];
                arrayAuxDataPre = [...arrayAuxDataPre, tempoPre];
                arrayAuxDataTotal = [...arrayAuxDataTotal, tempoPos];
            }
        }
        setArrayCategories(arrayAuxCategories);
        setArrayLabel(arrayAuxLabel);
        setArrayDataPre(arrayAuxDataPre);
        setArrayDataTotal(arrayAuxDataTotal);

    }
    const preencherArrayGrafico = () => {

        setOptions({
            colors: [
                'rgb(0,57,117)',
                '#b60e39'
            ],
            xaxis: {
                categories: arrayCategories,
                labels: {
                    style: {
                        color: "#f0f0f0",
                        fontSize: '20px',
                        fontFamily: 'Nunito',
                        cssClass: 'apexcharts-xaxis-label',
                    }
                }
            },
            legend: {labels:{colors: ['#f0f0f0']}},
        })

        setSeries([
            {
                name: "Gráfico Tempos Prévios",
                data: arrayDataPre
            },
            {
                name: "Gráfico Tempos Totais",
                data: arrayDataTotal
            }
        ])

    }

    useEffect(() => {
        preencherArrayGrafico();
    }, [arrayCategories, arrayDataPre, arrayDataTotal])

    useEffect(() => {
        timePre();
    }, [props])

    return (
        <>
            <label
                className="title-bar-chart-compare"
                htmlFor=""
            >
                Gráfico Comparativo
            </label>
            <Chart
                options={options}
                series={series}
                type="bar"
                height="250"
            />
        </>
    )

}

export default BarChartCompare;