import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './styles.css'

function HeaderModalRelatorio(props) {
    const { data, weightMaterial } = props;

    const returnWeight = (value, tipo) => {
        if(value) {
            return value.toFixed(2) + tipo
        } else {
            return 'Não possui'
        }
    }
 
    return (
        <>
            {/* OS, CLIENTE, ORÇAMENTO, RELATÓRIO DIMENSIONAL, TIPO DE CONJUNTO */}
            <Col md="12">
                <label className={"labels_header"} htmlFor="">OS: <p>{data.ordem_servico}</p></label>
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Cliente: <p>{data.cliente}</p></label>
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Nº Orçamento: <p>{data.codigo}</p></label>
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Relatório Dimensional: <p>{data.id_relatorio_dimensional ? data.id_relatorio_dimensional : "Não"}</p></label>
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Tipo de Conjunto: <p>{data.tipo}</p></label>
            </Col>

            <Col md="13">
                <div className='linhas'></div>
            </Col>

            {/* DATA INICIAL, DATA FINAL, DIAMETRO NOMINAL, COMPRIMENTO RECUPERADO, ALMOFADA, PESO DO ARAME, QUANTIDADE DE ELETRODOS */}
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Data Inicial: <p>{data.date}</p> </label>
                
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Data Final: <p>{data.data_final}</p> </label>
                
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Diametro Nominal: <p>{data.diametro}mm</p></label>
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Comprimento Recuperado: <p>{data.comprimento_recuperado}mm</p></label>
            </Col>
            {weightMaterial ?
                <>
                    <Col md="12">
                        {/* <label className={'labels_header'} htmlFor="">Peso do Arame</label> */}
                        <p style={{fontWeight: '550', fontSize: '18px'}}>{data.almofada}</p>
                        <p style={{fontWeight: '350', fontSize: '16px'}}>{weightMaterial.pesoArameAlmofada ? returnWeight(weightMaterial.pesoArameAlmofada[0].total, ' Kg') : '0'}</p>
                        <p style={{fontWeight: '550', fontSize: '18px'}}>{data.revestimento_duro}</p>
                        <p style={{fontWeight: '350', fontSize: '16px'}}>{weightMaterial.pesoArameRevestimentoDuro ? returnWeight(weightMaterial.pesoArameRevestimentoDuro[0].total, ' Kg') : '0'}</p>
                    </Col>
                    {/* <Col md="12">
                        <label className={'labels_header'} htmlFor="">Quantidade de Eletrodos</label>
                        <p style={{fontWeight: '350', fontSize: '18px'}}>{'Almofada: '}{weightMaterial.pesoEletrodoAlmofada ? returnWeight(weightMaterial.pesoEletrodoAlmofada[0].total, ' un') : '0'}</p>
                        <p style={{fontWeight: '350', fontSize: '18px'}}>{'Revestimento Duro: '}{weightMaterial.pesoEletrodoRevestimentoDuro ? returnWeight(weightMaterial.pesoEletrodoRevestimentoDuro[0].total, ' un') : '0'}</p>
                    </Col> */}
                </>
                : null}

            <Col md="13">
                <div className='linhas'></div>
            </Col>

            {/* BRUNIMENTO, NITRETAÇÃO, EMENDA */}
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Brunimento: <p>{data.brunimento ? 'Sim' : 'Não'}</p></label>
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Nitretação: <p>{data.nitretacao ? 'Sim' : 'Não'}</p></label>
            </Col>
            <Col md="12">
                <label className={'labels_header'} htmlFor="">Emenda: <p>{data.terceirizado ? 'Sim' : 'Não'}</p></label>
            </Col>
        </>
    )
}

export default HeaderModalRelatorio;