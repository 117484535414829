import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginator from 'react-bootstrap-table2-paginator';
import api from '../../../services/api';
import { Modal, Button } from 'react-bootstrap';
import ModalEditar from './ModalEditarRow';

export default class Modais extends Component {

    state = {
        cilindro: '',
        cilindroDadosDuplo: '',
        rosca: '',
        roscaDadosDupla: '',
        cilindroDuplo: false,
        cilindroOp: false,
        roscaDupla: false,
        monoRosca: false,
        idCadastro: '',
        idRelatorio: '',
    }

    async preencherTabela(r, lado, op) {
        if (op == 1) {
            if (lado == 1)
                await this.setState({ cilindroDadosDuplo: [...this.state.cilindroDadosDuplo, { idRelatorio: r.id_relatorio, l: r.l, d: r.diam, lado: 'E' }], cilindroDuplo: true });
            if (lado == 2)
                await this.setState({ cilindroDadosDuplo: [...this.state.cilindroDadosDuplo, { idRelatorio: r.id_relatorio, l: r.l, d: r.diam, lado: 'D' }], cilindroDuplo: true });
            if (lado == 0)
                await this.setState({ cilindro: [...this.state.cilindro, { idRelatorio: r.id_relatorio, l: r.l, d: r.diam }], cilindroOp: true });
        }
        if (op == 2) {
            if (lado == 1)
                await this.setState({ roscaDadosDupla: [...this.state.roscaDadosDupla, { idRelatorio: r.id_relatorio, l: r.l, d: r.diam, lado: 'E' }], roscaDupla: true });
            if (lado == 2)
                await this.setState({ roscaDadosDupla: [...this.state.roscaDadosDupla, { idRelatorio: r.id_relatorio, l: r.l, d: r.diam, lado: 'D' }], roscaDupla: true });
            if (lado == 0)
                await this.setState({ rosca: [...this.state.rosca, { idRelatorio: r.id_relatorio, l: r.l, d: r.diam }], monoRosca: true });
        }

    }

    async clearDatas() {
        this.setState({
            cilindro: '',
            cilindroDadosDuplo: '',
            rosca: '',
            roscaDadosDupla: '',
            cilindroDuplo: false,
            cilindroOp: false,
            roscaDupla: false,
            monoRosca: false,
            idCadastro: '',
        })
    }

    async componentWillReceiveProps(props) {
        this.clearDatas();
        this.setState({ idCadastro: props.id })

        if (props.id !== null) {
            const r = await api.post("/report/buscarRelatorio", { filtro: 'r.id_cadastro', id_cadastro: props.id });

            if (r.data) {
                r.data.map((item, index) => {
                    if (item.id_tipo_rosca == 1) {
                        this.preencherTabela(item, item.lado, 1)
                    }
                    if (item.id_tipo_rosca == 2) {
                        this.preencherTabela(item, item.lado, 2)
                    }
                    if (item.id_tipo_rosca == 3) {
                        this.preencherTabela(item, 3)
                    }
                })
            }
        }
    }

    async callDelete(row) {
        var confirm = window.confirm("Deseja deletar este cadastro?");
        if (confirm) {
            await api.post('/report/deleteRelatorioId', {
                id_relatorio: row.idRelatorio
            })
            alert("Cadastro deletado com sucesso!")
        }
    }


    showModal(row, op) {
        if (op == 1)
            this.setState({ showModal: true, dados: row })
        if (op == 2)
            this.callDelete(row);
    }

    close = () => {
        this.setState({ showModal: false })
    }

    render() {
        const botoes = (cell, row, rowIndex) => {
            return (
                <div>
                    <i
                        title='Editar Cadastro'
                        onClick={() =>
                            this.showModal(row, 1)
                        }
                        className="fa fa-edit"
                        style={{  fontSize: 20, marginLeft: 10 }}
                    />
                    <i
                        title='Excluir Cadastro'
                        onClick={() =>
                            this.showModal(row, 2)
                        }
                        className="fa fa-trash"
                        style={{ color: '#c5152f', fontSize: 20, marginLeft: 10 }}
                    />
                </div>
            );
        };

        const columnsMono = [
            {
                dataField: 'l',
                text: 'L',
                headerAlign: 'center',
            },
            {
                dataField: 'd',
                text: 'Diamêtro',
                headerAlign: 'center'
            },
            {
                dataField: 'button',
                text: 'Ações',
                formatter: botoes,
                headerAlign: 'center'
            },
        ];
        const columnsDuplo = [
            {
                dataField: 'l',
                text: 'L',
                headerAlign: 'center'
            },
            {
                dataField: 'd',
                text: 'Diamêtro',
                headerAlign: 'center'
            },
            {
                dataField: 'lado',
                text: 'Lado',
                headerAlign: 'center'
            },
            {
                dataField: 'button',
                text: 'Ações',
                formatter: botoes,
                headerAlign: 'center'
            },
        ];

        return (
            <>
                <ModalEditar show={this.state.showModal} close={this.close} dados={this.state.dados} idCadastro={this.state.idCadastro} 
                backdrop="static"
                keyboard={false} />
                {this.props.id !== null ?
                    <Modal
                        show={this.props.show}
                        onHide={() => this.props.close(2)}
                        className="ModalGraficoRelatorio"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>Relatório</Modal.Header>
                        <Modal.Body
                        >
                            <div id="dadosEdicao">
                                {this.state.monoRosca && !this.state.cilindroOp ?
                                    <>
                                        <p>Mono Rosca</p>
                                        <BootstrapTable
                                            keyField="id"
                                            pagination={paginator()}
                                            data={this.state.rosca}
                                            columns={columnsMono}
                                        />
                                    </>
                                    : !this.state.monoRosca && this.state.cilindroOp ?
                                        <>
                                            <p>Mono Cilindro</p>
                                            <BootstrapTable
                                                keyField="id"
                                                pagination={paginator()}
                                                data={this.state.cilindro}
                                                columns={columnsMono}
                                            />
                                        </>
                                        : this.state.monoRosca && this.state.cilindroOp ?
                                            <>
                                                <p>Mono Rosca</p>
                                                <BootstrapTable
                                                    keyField="id"
                                                    pagination={paginator()}
                                                    data={this.state.rosca}
                                                    columns={columnsMono}
                                                />
                                                <p>Mono Cilindro</p>
                                                <BootstrapTable
                                                    keyField="id"
                                                    pagination={paginator()}
                                                    data={this.state.cilindro}
                                                    columns={columnsMono}
                                                />
                                            </>

                                            : this.state.roscaDupla && this.state.cilindroDuplo ?
                                                <>
                                                    <p>Cilindro Duplo</p>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        pagination={paginator()}
                                                        data={this.state.cilindroDadosDuplo}
                                                        columns={columnsDuplo}
                                                    />
                                                    <p>Rosca Dupla</p>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        pagination={paginator()}
                                                        data={this.state.roscaDadosDupla}
                                                        columns={columnsDuplo}
                                                    />
                                                </>
                                                : this.state.roscaDupla && !this.state.cilindroDuplo ?
                                                    <>
                                                        <p>Rosca Dupla</p>
                                                        <BootstrapTable
                                                            keyField="id"
                                                            pagination={paginator()}
                                                            data={this.state.roscaDadosDupla}
                                                            columns={columnsDuplo}
                                                        />
                                                    </>
                                                    : !this.state.roscaDupla && this.state.cilindroDuplo ?
                                                        <>
                                                            <p>Cilindro Duplo</p>
                                                            <BootstrapTable
                                                                keyField="id"
                                                                pagination={paginator()}
                                                                data={this.state.cilindroDadosDuplo}
                                                                columns={columnsDuplo}
                                                            />
                                                        </>
                                                        : null}

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.props.close(2)} variant="primary">Cancelar</Button>
                            <Button onClick={() => this.imprimir()} variant="primary">Editar</Button>
                        </Modal.Footer>
                    </Modal>
                    : null}
            </>
        );

    }

}