const colors = [
    { step: 'zero_etapa', color: '#d7bde2' },
    { step: 'primeira_etapa', color: '#5b2c6f' },
    { step: 'segunda_etapa', color: '#52be80' },
    { step: 'terceira_etapa', color: '#808b96' },
    { step: 'quarta_etapa', color: '#eb984e' },
    { step: 'quinta_etapa', color: '#2e4053' },
    { step: 'sexta_etapa', color: '#40E0D0' },
    { step: 'setima_etapa', color: '#CCCCFF' },
    { step: 'oitava_etapa', color: '#aab7b8' },
    { step: 'nona_etapa', color: ' #78281f' },
    { step: 'decima_etapa', color: '#d98880' },
    { step: 'decima_primeira_etapa', color: '#a9dfbf' },
]

export default colors;