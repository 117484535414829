import { css } from '@emotion/core';
import BarChartCompare from 'components/BarChartCompare/Index';
import Calendar from 'components/Calendar';
import ChartBySection from 'components/ChartBySection/Index';
import EmptyContent from 'components/EmptyContent';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import 'moment/locale/pt-br';
import logo from '../../assets/img/logo-branca.png';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { BarLoader } from 'react-spinners';
import { recuperacaoService } from 'services/recuperacao';
import { v4 as uuidv4 } from 'uuid';
import HeaderModalRelatorio from './Header';
import "./styles.css";
import Subtitle from './Subtitle';
import colors from './utils';
const { ExportCSVButton } = CSVExport;

moment.locale('pt-br');
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;

function ModalRelatorio({ open, handleClose, dadosOs }) {
    const [data] = useState(dadosOs);
    const [logs, setLogs] = useState([]);
    const [weightMaterial, setWeightMaterial] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reportEmployee, setReportEmployee] = useState([]);
    const [totalPage, setTotal] = useState(0);
    const [calendars, setCalendars] = useState({
        firstMonth: null,
        secondMonth: null,
        thirdMonth: null
    })
    const [initialMonth, setInitialMonth] = useState(null);
    const [tabOption, setTabOption] = useState(1);
    const componentRef = useRef();

    const returnColor = (step) => {
        const findStep = colors.filter(color => color.step === step);
        return findStep[0].color;
    }

    const verifyMonth = (findLogs) => {
        const findFirstDate = findLogs[0].data;
        const date = moment(findFirstDate, 'DD/MM/YYYY');
        const initialDate = Number(date.format('M'));

        const currentDate = moment();
        const currentMonth = currentDate.month() + 1;

        if (initialDate < currentMonth) {
            const diffMonths = currentMonth - initialDate;
            setCalendars({
                firstMonth: diffMonths,
                secondMonth: diffMonths - 1,
                thirdMonth: diffMonths - 2
            })
        } else {
            setCalendars({
                firstMonth: Number(initialDate) - currentMonth,
                secondMonth: (Number(initialDate) - currentMonth) + 1,
                thirdMonth: (Number(initialDate) - currentMonth) + 2
            })
        }

        setInitialMonth(initialDate);
    }

    function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            var key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }


    const returnColorRepeat = (arrayColors, names) => {
        if (arrayColors.some(step => step.etapa === names)) {
            const step = arrayColors.filter(step => step.etapa === names);
            return step[0].color;
        } else {
            return "#" + ((1 << 24) * Math.random() | 0).toString(16);
        }
    }

    const onInit = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await recuperacaoService.getLogById(dadosOs.idrecuperacao);

            let findLogs = data.map(log => ({
                ...log,
                etapa: recuperacaoService.getStep(log.desc_etapa),
                color: returnColor(log.desc_etapa)
            }))

            const getMaterial = await recuperacaoService.getWeightMaterial(dadosOs.idrecuperacao);
            setWeightMaterial(getMaterial.data);

            if (findLogs.length) {
                verifyMonth(findLogs);
            }

            findLogs.forEach((logs, index) => {
                findLogs[index] = { ...logs, uuid: uuidv4() }
            })

            const groupByDate = groupBy(findLogs, "data");
            let convert = Object.values(groupByDate);

            let arrayChangeColor = [];
            let stepsToRemove = [];

            convert.forEach((item, index) => {
                if (item.length > 1) {
                    const concatNames = item.reduce(function (a, b) { return (a.etapa || a) + ", " + b.etapa });

                    findLogs.push({
                        data: item[0].data,
                        id: uuidv4(),
                        color: returnColorRepeat(arrayChangeColor, concatNames),
                        etapa: concatNames
                    });

                    stepsToRemove.push(item.map(item => item.uuid)); // remove steps that were joined
                }
            });

            stepsToRemove = stepsToRemove.flat();

            const filterStepsWithUnion = findLogs.filter(item => !stepsToRemove.some(step => item.uuid === step))

            setLogs(filterStepsWithUnion);
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        } catch (error) {
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
    }, [dadosOs]);

    const printScreen = async () => {
        window.scrollTo(0, 0)
        const canvas = await html2canvas(document.querySelector('#generatePdf'), { scale: 1, onclone: (subDocument) => {
        } })

        var imgData = canvas.toDataURL("image/png");
        var pdf = new jsPDF('l', 'pt', [canvas.width, canvas.height]);
        pdf.setFillColor('#414141')
        pdf.rect(0,0,pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getWidth(), 'FD');
        var pdfWidth = pdf.internal.pageSize.getWidth() - 100;
        var pdfHeight = pdf.internal.pageSize.getHeight() - 100;
        pdf.addImage(imgData, 'JPEG', 10, 10, pdfWidth, pdfHeight);
        pdf.save(`${data.cliente}.pdf`);
        

    };


    const employeeReport = async () => {
        const response = await recuperacaoService.getEmployeeReport(dadosOs.idrecuperacao);
        
        setReportEmployee(response.data);
        }

    useEffect(() => {
        onInit();
        employeeReport();
    }, [])

    const getContent = useMemo(() => {
        if (loading) {
            return <BarLoader
                css={override}
                sizeUnit={'px'}
                size={60}
                color={'#f0f0f0'}
                loading={loading}
            />
        }
        else {
            return (
                <div id="generatePdf" style={{ zoom: '0.8', background: '#333e42', display: 'flex' }}>
                    <Row className="content-header">
                        <HeaderModalRelatorio data={data} weightMaterial={weightMaterial} />
                    </Row>

                    <Row className="content-bar-compare">
                        {/* Barra do Gráfico comparativo */}
                        <Col md="12">
                            <BarChartCompare
                                selectItem={data}
                            />
                            <div className='linha'></div>
                            <Col md="4" style={{ marginTop: '1%' }}>
                                <label className="content-label-section">Solda</label>
                                <ChartBySection
                                    dataOs={dadosOs}
                                    url="buscarSetorSolda"
                                />
                            </Col>
                            <Col md="4" style={{ marginTop: '1%' }}>
                                <label className="content-label-section">Torno</label>
                                <ChartBySection
                                    dataOs={dadosOs}
                                    url="buscarSetorTorno"
                                />
                            </Col>
                            <Col md="4" style={{ marginTop: '1%' }}>
                                <label className="content-label-section">Desbaste</label>
                                <ChartBySection
                                    dataOs={dadosOs}
                                    url="buscarSetorDesbaste"
                                />
                            </Col>
                            <Col md="3" style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5%' }}>
                                {initialMonth ?
                                    <>
                                        <Calendar
                                            logs={logs}
                                            getMonth={calendars.firstMonth}
                                            initialMonth={initialMonth}
                                        />
                                    </>
                                    : null}
                            </Col>
                            <Col md="5" style={{ border: '1px solid #6b6b6b', height: '25em'}}>
                            <div className='legend'>
                                            <Subtitle logs={logs} />
                                        </div>
                            </Col>
                            <Col md="4" style={{height: '20em', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className='logoRosc'>
                                    <img src={logo} alt='Logo Roscan'></img>
                                </div>
                            </Col>

                        </Col>
                    </Row>
                </div>
            )
        }

    }, [dadosOs, loading])

    const columns = [
        {
            dataField: 'data',
            text: 'Data',
            headerAlign: 'center',
            align: 'center'
        },
        {
            dataField: 'hora',
            text: 'Hora',
            headerAlign: 'center',
            align: 'center'
        },
        {
            dataField: 'desc_status',
            text: 'Status',
            headerAlign: 'center',
            align: 'center'
        },
        {
            dataField: 'operador',
            text: 'Operador',
            headerAlign: 'center',
            align: 'center'
        },
        {
            dataField: 'desc_etapa',
            text: 'Etapa',
            headerAlign: 'center',
            align: 'center'
        },

    ];


    const renderEmployeeReport = useMemo(() => (
        <ToolkitProvider
            keyField="id"
            data={reportEmployee}
            columns={columns}
            align="center"
        >
            {props => (
                <>
                    {!reportEmployee.length ? <EmptyContent /> :
                        <>
                            <BootstrapTable
                                pagination={paginator()}
                                {...props.baseProps}
                            />
                            <ExportCSVButton {...props.csvProps}>
                                Exportar Excel
                            </ExportCSVButton>
                        </>
                    }
                </>
            )}
        </ToolkitProvider>
    ), [reportEmployee])


    return (
        <>
            <Modal
                show={open}
                onHide={() => handleClose()}
                className="modal-grafico-relatorio"

            >
                <ModalHeader closeButton>
                    <ModalTitle style={{ background: '#333e42' }}>
                        <label className={"labels_title"}>Relatório de Recuperações</label>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody ref={componentRef} style={{ background: '#333e42' }}>
                    <section className="tab-box" style={{ background: '#333e42' }}>
                        <span
                            onClick={() => setTabOption(1)}
                            className={tabOption === 1 ? "tab-option-active" : "tab-option-disabled"}
                        >
                            Geral
                        </span>
                        <span
                            onClick={() => setTabOption(2)}
                            className={tabOption === 2 ? "tab-option-active" : "tab-option-disabled"}
                        >
                            Funcionários
                        </span>
                        <hr className="tab-line" />
                    </section>
                    <section>
                        {tabOption === 1 && getContent}
                        {tabOption === 2 && renderEmployeeReport}
                    </section>
                </ModalBody>
                <ModalFooter style={{ background: '#333e42' }}>
                    {tabOption !== 2 && <Button onClick={printScreen}>Gerar PDF</Button>}
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalRelatorio;