import { Card } from "components/Card/Card.jsx";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { clienteService } from "services/clientes";
import Botoes from "./components/Botoes";
import Header from "./components/Header";
import ModalClientes from "./components/Modal";
import { Redirect } from "react-router-dom";
const { SearchBar, ClearSearchButton } = Search;


export default function Clientes(props) {
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState(null)

  const onOpen = () => setOpenModalEdit(true)
  
  const onClose = (onInitAgain) => {
    setOpenModalEdit(false)
    setClienteSelecionado(null)
    if(onInitAgain){
      onInit()
    }
  };

  const onDelete = async (id) => {
    try {
      if (window.confirm("Deseja deletar este cliente ?")) {
        await clienteService.delete(id);
        await onInit()
      }
    } catch (error) {
      alert(error.response.data.message)

    }
  };

  const columns = useMemo(
    () => [
      {
        dataField: "cod_cliente",
        text: "Código do Cliente",
        headerAlign: "center",
      },
      {
        dataField: "nome",
        text: "Nome do Cliente",
        headerAlign: "center",
      },
      {
        dataField: "cidade",
        text: "Cidade",
        headerAlign: "center",
      },
      {
        dataField: "estado",
        text: "Estado",
        headerAlign: "center",
      },
      {
        dataField: "button",
        text: "Ações",
        formatter: (cell, row, rowIndex) => (
          <Botoes onOpen={onOpen} row={row} onDelete={onDelete} setClienteSelecionado={setClienteSelecionado} />
        ),
        headerAlign: "center",
      },
    ],
    []
  );

const onInit = async () => {
  try {
    const response = await clienteService.get()
    setClientes(response.data)
  } catch (error) {
    
  }
}
useEffect(() => {
  onInit()
},[])
if (localStorage.getItem('login') == 'on') {
  return (
    <>
      <Header onAdd={onOpen} />
      <div style={{ marginTop: "3%", marginBottom: "10%" }}>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Clientes"
                content={
                  <>
                    <div style={{ textAlign: "center" }}>
                      <ToolkitProvider
                        keyField="id"
                        data={clientes}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div style={{ textAlign: "center" }}>
                            <SearchBar
                              placeholder="Pesquisar"
                              id="search_txt"
                              style={{ width: 470, marginRight: 10 }}
                              {...props.searchProps}
                            />
                            <ClearSearchButton text="Limpar" { ...props.searchProps} />
                            <BootstrapTable
                              pagination={paginator()}
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
        {openModalEdit ? <ModalClientes open={openModalEdit} onClose={onClose} clienteId={clienteSelecionado} backdrop="static"
        keyboard={false} /> : null}
      </div>
    </>
  );
} else {
  return (
    <Redirect to="/" from="" />
  )
}
}
