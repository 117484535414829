import React from 'react';
import './styles.css';
import StorageOutlined from '@material-ui/icons/StorageOutlined';

const EmptyContent = ({ description }) => (
  <div className="empty-content-box">
    <StorageOutlined color="disabled" style={{ fontSize: 48 }} />
    <p className='span-empty-content'>{description || "Nenhum dado encontrado"}</p>
  </div>
);

export default EmptyContent;
