import React, { Component } from 'react';
import {
    Grid,
    Row,
    Col,
    Table,
    FormGroup,
    ControlLabel,
    FormControl,
    Navbar,
    NavItem,
    Nav,
    Modal,
    Form,
    Button,
} from 'react-bootstrap';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import api from '../../services/api';
import Select from 'components/Select';


class ListUsers extends Component {
    state = {
        estoqueA: ["A205", "A204", "A203", "A202", "A201", "A105", "A104", "A103", "A102", "A101", "B105", "B104", "B103", "B102", "B101", "B205", "B204", "B203", "B202", "B201"],
        posEstoque: [],
        descricao: '',
        cliente: '',
        nfEntrada: '',
        situacao: '',
        dataEntrada: '',
        transportadora: '',
        tiposRosca: [],
        id_estoque: '',
        conferir: false,
        tipoLegenda: '',
    };

    constructor() {
        super();
    }

    async loadStates(dados) {
        var array = [];
        dados[0].arrayPos.map(item => {
            array = [...array, { value: item, label: this.state.estoqueA[item] }]
        })
        this.setState({ posEstoque: array })
    }

    async buscarDados(d) {
        console.log("id posicao: ", d)
        const r = await api.post("/stock/buscarDadosInseridos", { id_posicao: d });
        if (r.data.length > 0) {
            this.setState({
                id_estoque: r.data[0].id_estoque,
                cliente: r.data[0].cliente,
                descricao: r.data[0].descricao,
                nfEntrada: r.data[0].nf_entrada,
                situacao: r.data[0].situacao,
                dataEntrada: r.data[0].data_entrada,
                transportadora: r.data[0].transportadora,
                tipo: r.data[0].id_tipo_rosca,
                id_posicao: r.data[0].id_posicao,
                id_posicao2: r.data[0].id_posicao2,
                conferir: true,
            })

        }
    }

    async componentDidMount() {
        const r = await api.get("/stock/tiposRosca");
        r.data.map(item => {
            this.setState({ tiposRosca: [...this.state.tiposRosca, { value: item.id_tipo_rosca, label: item.descricao }] })
        })
    }

    async setarTipo() {
        if (this.state.tipo == 1)
            await this.setState({ tipoLegenda: "Cilindro" })
        if (this.state.tipo == 2)
            await this.setState({ tipoLegenda: "Rosca" })
        if (this.state.tipo == 3)
            await this.setState({ tipoLegenda: "Conjunto" })

    }

    async componentWillReceiveProps(props) {
        if (typeof (props.dados) === 'object') {
            await this.loadStates(props.dados)
            await this.buscarDados(props.dados[0].posSelecionada);
            await this.setState({ posicoes: [props.dados[0].posSelecionada] })
            await this.setarTipo();
        }
    }

    async handleChange(e, op) {
        if (op == 1)
            this.setState({ tipo: e.value })
        if (op == 2)
            this.setState({ descricao: e.target.value })
        if (op == 3)
            this.setState({ cliente: e.target.value })
        if (op == 4)
            this.setState({ nfEntrada: e.target.value })
        if (op == 5)
            this.setState({ situacao: e.target.value })
        if (op == 6)
            this.setState({ dataEntrada: e.target.value })
        if (op == 7)
            this.setState({ transportadora: e.target.value })
        if (op == 8)
            this.setState({ posicoes: [this.props.dados[0].posSelecionada, e.value] })

    }

    clearStates() {
        this.setState({ posEstoque: '', tipo: '', descricao: '', cliente: '', nfEntrada: '', situacao: '', dataEntrada: '', situacao: '', dataEntrada: '', transportadora: '', posicoes: '', conferir: false, tipoLegenda: '' });
        this.props.close();
    }

    async inserir() {
        let t = this.state;

        console.log("t posicoes: ", t.posicoes);
        await api.post("/stock/attStatusPosicao", {
            posicoes: t.posicoes
        })

        await api.post("/stock/inserirEstoque", {
            tipo: t.tipo,
            descricao: t.descricao,
            cliente: t.cliente,
            nfEntrada: t.nfEntrada,
            situacao: t.situacao,
            dataEntrada: t.dataEntrada,
            transportadora: t.transportadora,
            posicoes: t.posicoes,
        })
        window.location.reload();
    }

    async excluir() {
        let t = this.state;
        var arrayExcluir = [];
        arrayExcluir.push(this.state.id_posicao, this.state.id_posicao2);
        var arrayFilter = [];
        arrayExcluir.map(item => {
            if (item != null)
                arrayFilter.push(item);
        })
        await api.post("/stock/excluirPosicao", {
            id_posicao: this.state.id_estoque
        })
        await api.post("/stock/excluirPosicaoStatus", {
            id_posicao: arrayFilter
        })
        window.location.reload();
    }
    render() {
        var self = this;
        return (
            <div>
                <Modal
                    show={this.props.open}
                    onHide={() => this.clearStates()}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>Estoque</Modal.Header>
                    <Modal.Body
                    >
                        <div className="container">
                            <Row>
                                <Col md={6}>
                                    <FormGroup controlId="formControlsTextarea">
                                        <ControlLabel>Tipo Rosca</ControlLabel>
                                        <Select placeholder={this.state.conferir ? this.state.tipoLegenda : "Selecione o tipo da rosca"} onChange={(e) => this.handleChange(e, 1)} options={this.state.tiposRosca} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormInputs
                                ncols={["col-md-6"]}
                                properties={[
                                    {
                                        label: "Descrição",
                                        type: "text",
                                        bsClass: "form-control",
                                        onChange: ((e) => self.handleChange(e, 2)),
                                        defaultValue: self.state.descricao,
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-6"]}
                                properties={[
                                    {
                                        label: "Cliente",
                                        type: "text",
                                        bsClass: "form-control",
                                        onChange: ((e) => self.handleChange(e, 3)),
                                        defaultValue: self.state.cliente
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-6"]}
                                properties={[
                                    {
                                        label: "Nota Fiscal - Entrada",
                                        type: "text",
                                        bsClass: "form-control",
                                        onChange: ((e) => self.handleChange(e, 4)),
                                        defaultValue: self.state.nfEntrada
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-6"]}
                                properties={[
                                    {
                                        label: "Situação",
                                        type: "text",
                                        bsClass: "form-control",
                                        onChange: ((e) => self.handleChange(e, 5)),
                                        defaultValue: self.state.situacao
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-6"]}
                                properties={[
                                    {
                                        label: "Data de Entrada",
                                        type: "date",
                                        bsClass: "form-control",
                                        onChange: ((e) => self.handleChange(e, 6)),
                                        defaultValue: self.state.dataEntrada
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-6"]}
                                properties={[
                                    {
                                        label: "Transportadora",
                                        type: "text",
                                        bsClass: "form-control",
                                        onChange: ((e) => self.handleChange(e, 7)),
                                        defaultValue: self.state.transportadora
                                    }
                                ]}
                            />
                            {!this.state.conferir ?
                                <Row>
                                    <Col md={6}>
                                        <FormGroup controlId="formControlsTextarea">
                                            <ControlLabel>Inserir outra posição</ControlLabel>
                                            <Select placeholder="Selecione a posição" onChange={(e) => this.handleChange(e, 8)} options={this.state.posEstoque} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.conferir ?
                            <Button variant="primary" className="deletar" onClick={() => this.excluir()}>Deletar</Button>
                            :
                            <Button variant="primary" onClick={() => this.inserir()}>Inserir</Button>
                        }
                        <Button variant="primary" onClick={() => this.props.close()}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ListUsers;
