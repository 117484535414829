import api from "./api";
class Clientes {

    async get() {
        return api.get('/clientes')
    }
    async post(body) {
        return api.post('/clientes', body)
    }
    async getById(id) {
        return api.get(`/clientes/${id}`)
    }
    async put(id, body) {
        return api.put(`/clientes/${id}`, body)
    }
    async delete(id) {
        return api.delete(`/clientes/${id}`)
    }

}

export const clienteService = new Clientes();