/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'components/Select';
//componentes internos
import api from "../../../services/api";
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

class UserProfile extends Component {

  state = {
    tipos: '',
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    type: '',
    function: '',
    foto: 0,

  }

  async buscarPerfis() {
    const perfis = await api.get("/config/buscarTipos");
    console.log("PERFIS: ", perfis.data)
    perfis.data.map(item => {
      this.setState({ tipos: [...this.state.tipos, { value: item.id_perfil, label: item.descricao }] })
    })
  }

  async componentDidMount() {
    this.buscarPerfis();
  }

  confirmPassword(v) {
    if (this.state.password != v)
      alert("Senhas não conferem");
  }

  async handleChange(e, op) {
    if (op == 1)
      this.setState({ firstName: e.target.value })
    if (op == 2)
      this.setState({ lastName: e.target.value })
    if (op == 3)
      this.setState({ email: e.target.value })
    if (op == 4)
      this.setState({ username: e.target.value })
    if (op == 5)
      this.setState({ password: e.target.value })
    if (op == 6)
      this.confirmPassword(e.target.value);
    if (op == 7)
      this.setState({ type: e.value })
    if (op == 8)
      this.setState({ function: e.target.value })
    if (op == 9) {
      const result = await toBase64(e.target.files[0]).catch(e => e);
      this.setState({ foto: result })
    }
  }


  async insertUser() {
    const t = this.state;
    await api.post("/config/inserirUsuario", {
      firstName: t.firstName,
      lastName: t.lastName,
      email: t.email,
      username: t.username,
      password: t.password,
      type: t.type,
      funcao: t.function,
      foto: t.foto,
    })
    window.location.reload();
  }

  render() {
    const t = this;
    return (
      <div>
        <div className="content" style={{ marginBottom: 140 }}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Inserir Usuário"
                  content={
                    <form>
                      <FormInputs
                        ncols={["col-md-4", "col-md-4", "col-md-4"]}
                        properties={[
                          {
                            label: "Primeiro Nome",
                            type: "text",
                            bsClass: "form-control",
                            onChange: ((e) => t.handleChange(e, 1))
                          },
                          {
                            label: "Sobrenome",
                            type: "text",
                            bsClass: "form-control",
                            onChange: ((e) => t.handleChange(e, 2))
                          },
                          {
                            label: "E-mail",
                            type: "email",
                            bsClass: "form-control",
                            onChange: ((e) => t.handleChange(e, 3))

                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-4", "col-md-4", "col-md-4"]}
                        properties={[
                          {
                            label: "Username",
                            type: "text",
                            bsClass: "form-control",
                            onChange: ((e) => t.handleChange(e, 4))

                          },
                          {
                            label: "Senha",
                            type: "password",
                            bsClass: "form-control",
                            onChange: ((e) => t.handleChange(e, 5))

                          },
                          {
                            label: "Confirme sua senha",
                            type: "password",
                            bsClass: "form-control",
                            // onChange: ((e) => t.handleChange(e, 6))

                          },
                        ]}
                      />
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <ControlLabel>Tipo de usuário</ControlLabel>
                            <Select onChange={(e) => this.handleChange(e, 7)} placeholder="Selecione o tipo" options={this.state.tipos} />
                          </FormGroup>
                        </Col>
                        {this.state.type == 2 ?
                          <Col md={4}>
                            <FormGroup>
                              <ControlLabel>Função</ControlLabel>
                              <FormControl
                                placeholder="Digite a função do funcionário"
                                onChange={(e) => this.handleChange(e, 8)}
                              />
                            </FormGroup>
                          </Col>
                          : null}
                        <Col md={4}>
                          <FormGroup>
                            <ControlLabel>Foto do Usuário</ControlLabel>
                            <input onChange={(e) => this.handleChange(e, 9)} type="file" />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button bsStyle="info" pullRight fill onClick={() => this.insertUser()}>
                        Inserir Usuário
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default UserProfile;
