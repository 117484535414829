import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'react-bootstrap';

function ModalAlert({ show, handleModal, mainText, buttons }) {
    return (
        <Modal
            show={show}
            onHide={() => handleModal()}
        >
            <ModalHeader closeButton>
                Alerta
          </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="12">
                        {mainText}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {buttons}
            </ModalFooter>
        </Modal>
    )
}

export default ModalAlert;