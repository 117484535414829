import api from "./api";
class Recuperacao {
  async postCategorie(params) {
    return api.post("/painel/postCategories", params);
  }

  async findCategorie() {
    return api.get("/painel/buscarCategoriaSalva");
  }

  async getOrderInProgress() {
    return api.get("/painel/consultarOsEmAndamento");
  }

  async putCategorie(params) {
    return api.put("/painel/updateCategoria", {
      idrecuperacao: params.idrecuperacao,
      categoria: params.categoria,
      posicao_painel: params.posicao_painel,
    });
  }

  async getAllOrderInProgress() {
    return api.get("/get/consultarRecuperacaoDashboard");
  }

  async getLogById(idrecuperacao) {
    return api.get(`/recuperacao/buscarLogPorId/${idrecuperacao}`);
  }

  getStep = (etapa) => {
    switch (etapa) {
      case "zero_etapa": //  SOLDAR PONTEIRA
        return "Soldar Ponteira";
      case "primeira_etapa": //  ALINHAMENTO
        return "Alinhamento";
      case "segunda_etapa": //  USINAGEM DESBASTE
        return "Usinagem Desbaste";
      case "terceira_etapa": //  SOLDA ALMOFADA
        return "Solda Almofada";
      case "quarta_etapa": //  USINAGEM ALMOFADA
        return "Usinagem Almofada";
      case "quinta_etapa": //  SOLDA REVESTIMENTO
        return "Solda Revestimento";
      case "sexta_etapa": //  USINAGEM REVESTIMENTO
        return "Usinagem Revestimento";
      case "setima_etapa": //  LIXAMENTO
        return "Lixamento";
      case "oitava_etapa": //  DESBASTE
        return "Desbaste Lateral Filetes ";
      case "nona_etapa": //  POLIMENTO
        return "Polimento";
      case "decima_etapa": // USINAGEM FINAL
        return "Usinagem Final";
      case "decima_primeira_etapa": // RETRABALHO
        return "RETRABALHO";
      default:
        return "invalid";
    }
  };

  async getWeightMaterial(idrecuperacao) {
    return api.get(`/relatorio/buscarPesoMaterial/${idrecuperacao}`);
  }

  montarTemplateTipo(recuperacao) {
    let array = [];
    recuperacao.map((item) => {
      let diametro = item.diametro === null ? "-" : item.diametro;
      let comprimento_total = item.comprimento_total === null ? "-" : item.comprimento_total;
      let diametro_menor = item.diametro_menor === null ? "-" : item.diametro_menor;
      let diametro_maior = item.diametro_maior === null ? "-" : item.diametro_maior;
      let conica = item.conica ? `Cônica ${diametro_menor} / ${diametro_maior}` : "";
      array.push({
        ...item,
        tipo_template:
          item.tipo === "Rosca Dupla"
          ? conica
            ? `Dupla Rosca ${conica}`
            :  `Dupla Rosca ${conica} ${diametro} LD ${
                comprimento_total === "-" || diametro === "-"
                  ? "-"
                  : Math.round(
                      parseFloat(comprimento_total) / parseFloat(diametro)
                    )
              }`
            : item.tipo === "Mono Rosca"
            ? `Mono Rosca ${diametro} LD ${
                comprimento_total === "-" || diametro === "-"
                  ? "-"
                  : Math.round(
                      parseFloat(comprimento_total) / parseFloat(diametro)
                    )
              }`
            : item.tipo
      });
    });
    return array;
  }

  async getEmployeeReport(ordemServicoId) {
    const { data } = await api.get(
      `/recuperacao/buscarLogFuncionario/${ordemServicoId}`
    );

    return data;
  }
}

export const recuperacaoService = new Recuperacao();
