/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import Dashboard from "views/Dashboard.jsx";
import Importacao from "views/Importacao.jsx";
import Recuperacao from "views/Recuperacao.jsx";
import UserProfile from "views/Settings/UserProfile.jsx";
import RelatorioDimensional from "views/Relatorio/ConsultarRelatorios";
import Clientes from "views/Clientes/Clientes";
import Typography from "views/Typography.jsx";
import Icons from "views/Icons.jsx";
import Maps from "views/Maps.jsx";
import Notifications from "views/Notifications.jsx";
import Upgrade from "views/Upgrade.jsx";
import PainelPrioridades from 'views/PainelPrioridades/Painel';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin"
  },
  // {
  //   path: "/importacao",
  //   name: "Importação",
  //   icon: "pe-7s-box2",
  //   component: Importacao,
  //   layout: "/admin"
  // },
  {
    path: "/recuperacao",
    name: "RECUPERAÇÃO DE ROSCA",
    icon: "pe-7s-display1",
    component: Recuperacao,
    layout: "/admin"
  },
  {
    path: "/relatorio",
    name: "Relatório Dimensional",
    icon: "pe-7s-graph2",
    component: RelatorioDimensional,
    layout: "/admin"
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: "pe-7s-id",
    component: Clientes,
    layout: "/admin"
  },
  {
    path: "/painel",
    name: "Painel de prioridades",
    icon: "pe-7s-note2",
    component: PainelPrioridades,
    layout: "/painel"
  },
  {
    path: "/settings",
    name: "Configurações",
    icon: "pe-7s-settings",
    component: UserProfile,
    layout: "/admin"
  },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "pe-7s-note2",
  //   component: TableList,
  //   layout: "/admin"
  // },
  
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "pe-7s-science",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "pe-7s-map-marker",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "pe-7s-bell",
  //   component: Notifications,
  //   layout: "/admin"
  // },
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "pe-7s-rocket",
  //   component: Upgrade,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
