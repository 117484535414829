import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React, { useCallback, useEffect, useState } from 'react';
import './styles.css';

const Subtitle = ({ logs }) => {
    const [steps, setSteps] = useState([]);

    const onInit = useCallback(() => {
        if (logs.length) {
            let findSteps = logs.filter((v, i, a) => a.findIndex(t => (t.etapa === v.etapa)) === i);
            setSteps(findSteps.map(item => ({ etapa: item.etapa, color: item.color })))
        }
    }, [logs])

    useEffect(() => {
        onInit();
    }, [logs])


    return (
        <>
            <label
                className="content-label-section"
            >
                LEGENDA
            </label>
            <div style={{display:'grid', gridTemplateColumns: 'repeat(3, 16em)', padding: '2em', gap: '0.5%', marginTop: '-5%', justifyContent: 'center', alignContent: 'center'}}>
            {steps.map(step => (
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2em', border: '1px solid #6b6b6b', paddingLeft: '5%', alignItems: 'center'}}>
                    {/* <FiberManualRecordIcon style={{ color: step.color, marginTop: 3, marginRight: 5 }} /> */}
                    <div style={{ width: 14, height: 14, borderRadius: 7, backgroundColor: step.color, marginTop: 3, marginRight: 5 }}/>
                    <p style={{ fontSize: 16, display: 'flex', marginTop: '0.9em' }}>{step.etapa}</p>
                </div>
            
            ))}
            </div>
        </>
    )
}

export default Subtitle;