import { Grid } from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
//import estilo from './styles.css'
import { GlobalStyle, Section } from './styles/Base'
import Card from './components/Card'
import Logo from '../../assets/img/logo-branca.png'
import data from "./utils/data.json";
import Board from "react-trello";
import { recuperacaoService } from 'services/recuperacao';
import ModalPanelPriority from 'components/ModalPanelPriority/Index';
import LaneHeader from './components/LaneHeader'
//import LaneFooter from './components/LaneFooter'
import NewLaneForm from './components/NewLaneForm'
import { style } from 'variables/Variables';
import { Redirect } from "react-router-dom";

//import NewLaneSection from './components/NewLaneSection'

const components = {
    GlobalStyle, // global style created with method `createGlobalStyle` of `styled-components`
    Section,
    Card,
    NewLaneForm,
    LaneHeader
};
function PainelPrioridades() {

    const [dataBoard, setDataBoard] = useState({ lanes: [] });
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [cardToEdit, setCardToEdit] = useState(null);
    const putCategorie = async (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        try {
            const params = {
                idrecuperacao: cardDetails.idrecuperacao,
                categoria: targetLaneId,
                posicao_painel: position,
            }
            await recuperacaoService.putCategorie(params);
        } catch (err) {
            // alert('erro ao atualizar dados');
        }
    }

    const postCategorie = async (data) => {
        try {
            setDataBoard(data)
            await recuperacaoService.postCategorie(data.lanes);
        } catch (err) {
            // alert('erro ao atualizar dados');
        }
    }

    const pushCategoriesInProgress = (orderInProgress) => {
        if (orderInProgress.length) {
            orderInProgress.forEach(order => {
                data.lanes[0].cards.push({
                    id: order.idrecuperacao,
                    title: 'OS ' + order.ordem_servico + ` ${order.tipo === 'Rosca Dupla' ? `\n${order.lado}` : ''}`,
                    label: String(order.cliente).length > 10 ? order.cliente.substring(0, 15) + '...' : order.cliente,
                    description: `${order.data_final}\n${order.tipo_template}`,
                    idrecuperacao: order.idrecuperacao,
                    style: order.style ? { backgroundColor: order.style } : { backgroundColor: 'white' },
                    cardStyle: {
                        borderRadius: '8px',
                        minWidth: '100%',
                        minHeight: '100px'
                    },
                });
            })
        }

        setDataBoard(data);
    }


    const findCategorie = async () => {
        try {
            const categorieSave = await recuperacaoService.findCategorie();
            const orderInProgress = await recuperacaoService.getOrderInProgress();
            const allOrderInProgress = await recuperacaoService.getAllOrderInProgress();

            const ordersInProgressWithType = await recuperacaoService.montarTemplateTipo(orderInProgress.data)

            if (categorieSave.data.length) {
                getOrderInProgress(JSON.parse(categorieSave.data[0].painel), ordersInProgressWithType, allOrderInProgress.data)
            } else {
                pushCategoriesInProgress(ordersInProgressWithType, allOrderInProgress.data);
            }
        } catch (err) {
            alert('erro ao buscar categoria salva')
        }
    }

    const getOrderInProgress = async (panel, order) => {

        try {
            let arrayFilterOs = [];
            if (order.length) {
                panel.forEach(categorie => {
                    if (categorie.id === 1) {
                        if (categorie.cards.length) {
                            arrayFilterOs = order.filter(({ idrecuperacao: idOrderProgress }) => !categorie.cards.some(({ id: idCard }) => idCard === idOrderProgress));
                        } else {
                            arrayFilterOs = order;
                        }
                    }
                })

                if (arrayFilterOs.length) {
                    arrayFilterOs.forEach(order => {
                        panel[0].cards.push({
                            id: order.idrecuperacao,
                            title: 'OS ' + order.ordem_servico + ` ${order.tipo === 'Rosca Dupla' ? `\n${order.lado}` : ''}`,
                            label: String(order.cliente).length > 10 ? order.cliente.substring(0, 15) + '...' : order.cliente,
                            description: `${order.data_final}\n${order.tipo_template}`,
                            idrecuperacao: order.idrecuperacao,
                            style: order.style ? { backgroundColor: order.style } : { backgroundColor: 'white' },
                        });
                    })
                }


                setDataBoard({ lanes: panel })
            } else {
                setDataBoard({ lanes: panel })
            }
        } catch (err) {
            alert('erro ao buscar OS nova')
        }
    }

    const handleChangeModal = () => {
        setModal(!modal);
    }

    const openModal = (cardId, metadata, laneId) => {
        const filterCard = dataBoard.lanes.filter(cards =>
            cards.cards.some(card => card.id == cardId)
        );

        const cardSelected = filterCard[0].cards.filter(card => card.id == cardId);

        setCardToEdit(cardSelected[0]);
        handleChangeModal();
    }

    const modalCard = useMemo(() => {
        if (modal) {
            return <ModalPanelPriority
                modal={modal}
                card={cardToEdit}
                handleChangeModal={handleChangeModal}
                dataBoard={dataBoard}
                setDataBoard={setDataBoard}
                postCategorie={postCategorie}
                backdrop="static"
                keyboard={false}
            />
        } else {
            return null;
        }
    }, [modal])

    useEffect(() => {
        var style = document.createElement("style");
        style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
        document.head.appendChild(style);
        findCategorie();
    }, [])

    if (localStorage.getItem('login') == 'on') {
    return (
        <div className="panel-content">
            <Grid container spacing={0}>
                <Grid item xs={12}
                    style={{ display: 'flex', flexDirection: 'row' }}
                >
                    <Grid item xs={4} style={{ textAlign: "left" }}>
                        <img src={Logo} style={{ height: "150px" }} />
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center", display: 'flex', fontSize: '40px', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="titulo">Painel de Prioridades</div>
                    </Grid>
                    <Grid item xs={4} style={{ marginLeft: 'auto', marginTop: 20, marginLeft: 200, flex: 1 }}>
                        <Grid style={{ textAlign: "left", flex: 1 }}>
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <i style={{ color: '#36B37E', marginTop: 10, marginRight: 10, fontSize: 22 }}
                                    class="fa fa-circle"></i><p style={{ fontSize: 20 }}>Rodando</p>
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <i
                                    style={{ color: '#FF8B00', marginTop: 10, marginRight: 10, fontSize: 22 }}
                                    class="fa fa-circle"></i><p style={{ fontSize: 20 }}>Espera</p>
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                <i
                                    style={{ color: '#00B8D9', marginTop: 10, marginRight: 10, fontSize: 22 }}
                                    class="fa fa-circle"></i><p style={{ fontSize: 20 }}>Próximo</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {console.log("databoard: ", dataBoard)}
                <Grid item xs={12}>
                    {dataBoard.lanes.length ?
                        <Board
                            data={dataBoard}
                            draggable
                            onDataChange={postCategorie}
                            onCardClick={openModal}
                            handleDragEnd={putCategorie}
                            hideCardDeleteIcon={true}
                            laneDraggable={false}
                            components={components}
                            style={{ backgroundColor: "transparent", justifyContent: "center", height: "auto" }}
                        />
                        : null}
                </Grid>
                {modalCard}
            </Grid>
        </div>
    )
    } else {
    return (
      <Redirect to="/" from="" />
    )
  }
}

export default PainelPrioridades;