/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from 'react-bootstrap';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Header from './Header.jsx'

//importar componentes internos
import api from '../../services/api';
import avatar from "assets/img/faces/avatar_generico.jpg";
import background from "assets/img/bgImage.jpeg";
import InsertUsers from "./Actions/InsertUsers.jsx";
import ListUsers from "./Actions/ListUsers";
import { Redirect } from "react-router-dom";
import './styles/styles.css';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.action = this.action.bind(this);
  }
  state = {
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    menu: '',
    function: '',
    foto: '',
    cep: '',
  }

  action(op) {
    if (op == 1)
      this.setState({ menu: op })
    if (op == 2)
      this.setState({ menu: op })
    if (op == 3)
      this.setState({ menu: op })
  }

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario");
    var dadosUsuario = JSON.parse(usuario);
    await this.setState({
      id_usuario: dadosUsuario.id_usuario,
    })
    const dados = await api.post("/config/buscarUsuario", { id_usuario: this.state.id_usuario });
    await this.setState({
      id_usuario: dados.data[0].id_usuario,
      firstName: dados.data[0].primeiroNome,
      lastName: dados.data[0].sobrenome,
      email: dados.data[0].email,
      username: dados.data[0].username,
      adress: dados.data[0].endereco,
      city: dados.data[0].cidade,
      function: dados.data[0].funcao,
      foto: dados.data[0].foto,
      cep: dados.data[0].cep,
      city: dados.data[0].cidade
    })
    
  }

  async attDados() {
    await api.post("/config/updateData", {
      id_usuario: this.state.id_usuario,
      primeiroNome: this.state.firstName,
      sobrenome: this.state.lastName,
      funcao: this.state.function,
      email: this.state.email,
      username: this.state.username,
      endereco: this.state.adress,
      cidade: this.state.city,
      cep: this.state.cep,
    });
    window.location.reload();
  }

  async handleChange(e, op) {
    if (op == 1)
      this.setState({ username: e.target.value })
    if (op == 2)
      this.setState({ email: e.target.value })
    if (op == 3)
      this.setState({ firstName: e.target.value })
    if (op == 4)
      this.setState({ lastName: e.target.value })
    if (op == 5)
      this.setState({ adress: e.target.value })
    if (op == 6)
      this.setState({ city: e.target.value })
    if (op == 7)
      this.setState({ function: e.target.value })
    if (op == 8)
      this.setState({ cep: e.target.value })
    if (op == 1)
      this.setState({ about: e.target.value })
  }

  render() {
    const self = this.state;
    if (localStorage.getItem('login') == 'on') {
    return (
      <div>
        <div className="acoes">
          <Header action={this.action} props={this.props} />
        </div>
        <div className="content">
          {self.menu == 2 ?
            <InsertUsers />
            :
            self.menu == 3 ?
              <ListUsers />
              :
              <Grid fluid>
                <Row>
                  <Col md={8}>
                    <Card
                      title="Perfil"
                      content={
                        <form>
                          <FormInputs
                            ncols={["col-md-5", "col-md-3", "col-md-4"]}
                            properties={[
                              {
                                label: "Empresa",
                                type: "text",
                                bsClass: "form-control",
                                defaultValue: "Roscan Máquinas e Equipamentos",
                                disabled: true,
                              },
                              {
                                label: "Username",
                                type: "text",
                                bsClass: "form-control",
                                defaultValue: self.username,
                                onChange: ((e) => this.handleChange(e, 1))
                              },
                              {
                                label: "E-mail",
                                type: "email",
                                bsClass: "form-control",
                                defaultValue: self.email,
                                onChange: ((e) => this.handleChange(e, 2))
                              }
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-6", "col-md-6"]}
                            properties={[
                              {
                                label: "Primeiro nome",
                                type: "text",
                                bsClass: "form-control",
                                defaultValue: self.firstName,
                                onChange: ((e) => this.handleChange(e, 3))
                              },
                              {
                                label: "Sobrenome",
                                type: "text",
                                bsClass: "form-control",
                                defaultValue: self.lastName,
                                onChange: ((e) => this.handleChange(e, 4))
                              }
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            properties={[
                              {
                                label: "Endereço",
                                type: "text",
                                bsClass: "form-control",
                                defaultValue: self.adress,
                                onChange: ((e) => this.handleChange(e, 5))
                              }
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-4", "col-md-4", "col-md-4"]}
                            properties={[
                              {
                                label: "Função",
                                type: "text",
                                bsClass: "form-control",
                                defaultValue: self.function,
                                onChange: ((e) => this.handleChange(e, 7))
                              },
                              {
                                label: "Cidade",
                                type: "text",
                                bsClass: "form-control",
                                defaultValue: self.city,
                                onChange: ((e) => this.handleChange(e, 6))
                              },
                              {
                                label: "CEP",
                                type: "number",
                                bsClass: "form-control",
                                defaultValue: self.cep,
                                onChange: ((e) => this.handleChange(e, 8))
                              }
                            ]}
                          />

                          <Row>
                            <Col md={12}>
                              <FormGroup controlId="formControlsTextarea">
                                <ControlLabel>Sobre mim</ControlLabel>
                                <FormControl
                                  rows="5"
                                  componentClass="textarea"
                                  bsClass="form-control"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Button bsStyle="info" onClick={() => this.attDados()} pullRight fill>
                            Atualizar Dados
                          </Button>
                          <div className="clearfix" />
                        </form>
                      }
                    />
                  </Col>
                  <Col md={4}>
                    {this.state.foto != 0 ?
                      <UserCard
                        bgImage={background}
                        avatar={this.state.foto}
                        name={this.state.firstName + ' ' + this.state.lastName}
                        userName={this.state.username}
                        description={
                          <span>
                            {this.state.function}
                          </span>
                        }
                        socials={
                          <div>
                            <Button simple>
                              <i className="fa fa-facebook-square" />
                            </Button>
                            <Button simple>
                              <i className="fa fa-twitter" />
                            </Button>
                            <Button simple>
                              <i className="fa fa-google-plus-square" />
                            </Button>
                          </div>
                        }
                      />
                      :
                      <UserCard
                        bgImage={background}
                        avatar={avatar}
                        name={this.state.firstName + ' ' + this.state.lastName}
                        userName={this.state.username}
                        description={
                          <span>
                            {this.state.function}
                          </span>
                        }
                        socials={
                          <div>
                            <Button simple>
                              <i className="fa fa-facebook-square" />
                            </Button>
                            <Button simple>
                              <i className="fa fa-twitter" />
                            </Button>
                            <Button simple>
                              <i className="fa fa-google-plus-square" />
                            </Button>
                          </div>
                        }
                      />
                    }
                  </Col>
                </Row>
              </Grid>
          }
        </div>
      </div>
    );
    } else {
  return (
    <Redirect to="/" from="" />
  )
    }
  }
}

export default UserProfile;
