import chroma from 'chroma-js';
import Select from 'components/Select';
import React, { useState } from 'react';
import { Button, Col, FormControl, FormGroup, Grid, Modal, Row } from 'react-bootstrap';
import { colourOptions } from './data';
import './Index.css';

function ModalPanelPriority({
    modal,
    card,
    handleChangeModal,
    dataBoard,
    setDataBoard,
    postCategorie
}) {
    const [color, setColor] = useState(null);

    const changeValueOfDataBoard = async (option, color) => {
        dataBoard.lanes.forEach((items, key) => {
            items.cards.forEach((item, indexCard) => {
                if (item.id === card.id) {
                    if (option === 'finish') {
                        dataBoard.lanes[key].cards.splice(indexCard, 1);
                    }
                    if (option === 'status') {
                        dataBoard.lanes[key].cards[indexCard] = {
                            ...dataBoard.lanes[key].cards[indexCard],
                            style: { backgroundColor: color, color: 'white' },
                        }
                    }
                }

            })
        })
        setDataBoard(dataBoard);
        postCategorie(dataBoard);
        handleChangeModal();
    }

    const finishOS = () => {
        if (window.confirm("Deseja finalizar esta ordem de serviço?")) {
            changeValueOfDataBoard('finish');
        }
    }

    const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });

    const colourStyles = {
        control: styles => ({ ...styles,   background: "transparent",
        borderColor: "#888888",  color: "#f0f0f0"}),
        singleValue: styles => ({ ...styles,    color: "#f0f0f0"}),
        menu: (base, state) => ({
            ...base,
            background: "#364044",
        
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        input: styles => ({ ...styles, ...dot() }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...{color: '#f0f0f0', marginLeft: 20} }),
    };

    const changeStatus = () => {
        if (window.confirm("Deseja trocar o status?")) {
            changeValueOfDataBoard('status', color);
        }
    }

    return (
        <Modal
            show={modal}
            onHide={handleChangeModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{card.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid>
                    <Row>
                        <Col xs="6" md="3">
                            <FormGroup>
                                <label class="control-label">Id</label>
                                <FormControl
                                    value={card.id}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="6" md="3">
                            <FormGroup>
                                <label class="control-label">Cliente</label>
                                <FormControl
                                    value={card.label}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 15 }}>
                        <Col xs="6" md="6">
                            <label class="control-label">Status</label>
                            <Select
                                defaultValue={colourOptions.filter(item => item.value === card.style.backgroundColor)[0]}
                                label="Single select"
                                onChange={(event) => setColor(event.value)}
                                options={colourOptions}
                                styles={colourStyles}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6" md="6">
                            <FormGroup>
                                <label class="control-label">Descrição</label>
                                <textarea
                                class="form-control"
                                    value={card.description}
                                    style={{ width: '100%' }}
                                    name="" id="" cols="30" rows="5"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                    onClick={handleChangeModal}
                >
                    Fechar
                </Button>
                <Button variant="secondary"
                    onClick={changeStatus}
                >
                    Trocar Status
                </Button>
                <Button variant="secondary"
                    onClick={finishOS}
                >
                    Finalizar OS
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPanelPriority;