export const setarValores = (v1, v2, v3, v4, op, min, max) => {
    if (op == 1) {
        var option = {
            animationEnabled: true,
            title: {
                text: ""
            },
            axisY: {
                title: "",
                includeZero: false,
                minimum: min,
                maximum: max
            },
            data: [
                {

                    markerType: 'none',
                    type: "spline",
                    name: "Curva de Desgaste Rosca",
                    showInLegend: true,
                    dataPoints: v1, 
                    color: '#0a3974'
                },
                {
                    markerType: 'none',
                    lineDashType: "dash",              
                    type: "spline",
                    name: "Nominal Rosca",
                    showInLegend: true,
                    dataPoints: v2,
                    color: '#0a3974'
                },
            ]
        }
        return option;
    }
    if (op == 2) {
        var option = {
            animationEnabled: true,
            title: {
                text: ""
            },
            axisY: {
                title: "",
                includeZero: false,
                minimum: min,
                maximum: max
            },
            data: [
                {
                    markerType: 'none',
                    type: "spline",
                    name: "Curva de Desgaste Cilindro",
                    showInLegend: true,
                    dataPoints: v1,
                    color: '#eb8d1b'
                },
                {
                    markerType: 'none',
                    type: "spline",
                    name: "Nominal Cilindro",
                    showInLegend: true,
                    dataPoints: v2,
                    color: '#eb8d1b',
                    lineDashType: "dash",              

                },
            ]
        }
        return option;
    }
    // if (op == 3) {
    //     var data = [
    //         {
    //             data: v1
    //         },
    //         {
    //             data: v2
    //         },
    //         {
    //             data: v3
    //         },
    //         {
    //             data: v4
    //         },
    //     ]
    //     return data;
    // }
    if (op == 3) {
        var option = {
            animationEnabled: true,
            title: {
                text: ""
            },
            axisY: {
                title: "",
                includeZero: false,
                minimum: min,
                maximum: max
            },
            data: [
                {
                    markerType: 'none',
                    type: "spline",
                    name: "Curva de Desgaste Cilindro",
                    showInLegend: true,
                    dataPoints: v3,
                    color: '#eb8d1b'

                },
                {
                    markerType: 'none',
                    type: "spline",
                    name: "Nominal Cilindro",
                    showInLegend: true,
                    dataPoints: v4,
                    color: '#eb8d1b',
                    lineDashType: "dash",              

                },
                {
                    markerType: 'none',
                    type: "spline",
                    name: "Curva de Desgaste Rosca",
                    showInLegend: true,
                    dataPoints: v1,
                    color: '#0a3974'
                },
                {
                    markerType: 'none',
                    type: "spline",
                    name: "Nominal Rosca",
                    showInLegend: true,
                    dataPoints: v2,
                    color: '#0a3974',
                    lineDashType: "dash",              

                },
            ]
        }
        return option;
    }

}
