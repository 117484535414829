import React, { Component } from 'react';
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { Modal, Button } from 'react-bootstrap';
import api from '../../../services/api';

export default class Modais extends Component {
    state = {
        l: '',
        diam: '',
        idCadastro: '',
        lEdit: '',
        diamEdit: '',
    }

    componentWillReceiveProps(props) {
        if (props.dados != undefined) {
            this.setState({ l: props.dados.l, diam: props.dados.d, idCadastro: props.idCadastro });
            this.setState({ lEdit: props.dados.l, diamEdit: props.dados.d });
        }
    }

    handleChange(v, op) {
        if (op == 1) {
            this.setState({ lEdit: v.target.value })
        }
        if (op == 2) {
            this.setState({ diamEdit: v.target.value })
        }
    }

    async editar() {
        if (this.state.diam == "") {
            await this.setState({ diam: 0 })
        }

        const relatorio = await api.post('/report/buscarIdRelatorioEmpty', {
            id_cadastro: this.state.idCadastro,
            l: this.state.l,
            di: this.state.diam
        });
        var des;
        if (relatorio.data[0].nom < this.state.diamEdit)
            des = (parseFloat(this.state.diamEdit) - parseFloat(relatorio.data[0].nom))
        else
            des = (parseFloat(relatorio.data[0].nom) - parseFloat(this.state.diamEdit))

        await api.put("/update/updateLineRelatorio", {
            idRelatorio: relatorio.data[0].id_relatorio,
            l: this.state.lEdit,
            di: this.state.diamEdit,
            nom: relatorio.data[0].nom,
            dis: des
        })
        if (this.editar){
            alert("Relatório editado com sucesso!");
            this.props.close()
              return 
           }  
    }

    render() {
        const self = this.state;
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={() => this.props.close()}
                    className="ModalGraficoRelatorio"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>Relatório</Modal.Header>
                    <Modal.Body
                    >
                        <div >
                            <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                    {
                                        label: "L",
                                        type: "text",
                                        bsClass: "form-control",
                                        defaultValue: self.l,
                                        onChange: ((e) => this.handleChange(e, 1))
                                    },

                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                    {
                                        label: "Diamêtro",
                                        type: "text",
                                        bsClass: "form-control",
                                        defaultValue: self.diam,
                                        onChange: ((e) => this.handleChange(e, 2))
                                    },
                                ]}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.close()} variant="primary">Cancelar</Button>
                        <Button onClick={() => this.editar()} variant="primary">Editar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }

}