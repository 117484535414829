import axios from 'axios';

const url = process.env.NODE_ENV === 'production'
? 'https://roscanweb.com.br/api/'
: 'http://localhost:5000/api';


const api = axios.create({
    baseURL: url,
})

export default api;