import React, { Component } from 'react';
import { css } from '@emotion/core';
import {
    Grid,
    Row,
    Col,
} from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import Card from 'components/Card/Card.jsx';
import { thArray, tdArray } from 'variables/Variables.jsx';
import api from '../../../services/api';
import BootstrapTable from 'react-bootstrap-table-next';
import paginator from 'react-bootstrap-table2-paginator';
import ModalEditar from '../Modal/ModalEditar';
import ModalDeletar from '../Modal/ModalDeletar';
import { BarLoader } from 'react-spinners';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar, ClearSearchButton } = Search;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  marginTop: 100px;
`;
class ListUsers extends Component {
    state = {
        usuarios: '',
        showModalEditar: false,
        loadArray: false,
    };

    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.closeDeletar = this.closeDeletar.bind(this);
    }

    async componentDidMount() {
        const usuarios = await api.get("/config/listarUsuarios");
        this.setState({ usuarios: usuarios.data, loadArray: true });
    }

    mobileSidebarToggle(e) {
        if (this.state.sidebarExists === false) {
            this.setState({
                sidebarExists: true,
            });
        }
        e.preventDefault();
        document.documentElement.classList.toggle('nav-open');
        var node = document.createElement('div');
        node.id = 'bodyClick';
        node.onclick = function () {
            this.parentElement.removeChild(this);
            document.documentElement.classList.toggle('nav-open');
        };
        document.body.appendChild(node);
    }

    async editarTabela(r) {
        await this.setState({ showModalEditar: true, dados: r })
    }

    async close() {
        await this.setState({ showModalEditar: false });

    }

    async closeDeletar() {
        await this.setState({ showModalDeletar: false });
        await this.componentDidMount();

    }

    render() {
        var self = this;

        const botoes = (cell, row, rowIndex) => {
            return (
                <div>
                    <i
                        onClick={() =>
                            this.editarTabela((row))
                        }
                        className="fa fa-edit"
                        style={{ color: 'green', fontSize: 20 }}
                    />
                    <i
                        className="fa fa-trash fa-2x"
                        onClick={() =>
                            this.setState({
                                showModalDeletar: true,
                                idDeletar: row.id_usuario,
                            })
                        }
                        style={{ color: '#c5152f', marginLeft: 20, fontSize: 20 }}
                    />
                </div>
            );
        };
        const columns = [
            {
                dataField: 'primeiroNome',
                text: 'Nome',
            },
            {
                dataField: 'sobrenome',
                text: 'Sobrenome',
            },
            {
                dataField: 'descricao',
                text: 'Tipo',
            },
            {
                dataField: 'button',
                text: 'Ações',
                formatter: botoes,
            },
        ];
        if (localStorage.getItem('login') == 'on') {
            return (
                <div>
                    {/* modais */}
                    <ModalEditar open={this.state.showModalEditar} close={this.close} dados={this.state.dados}backdrop="static"
                    keyboard={false} />
                    <ModalDeletar open={this.state.showModalDeletar} deletar={this.state.idDeletar} close={this.closeDeletar}
                    backdrop="static"
                    keyboard={false} />
                    <>
      <div style={{ marginTop: "3%", marginBottom: "10%" }}>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Clientes"
                content={
                  <>
                    <div style={{ textAlign: "center" }}>
                    {this.state.loadArray ?
                      <ToolkitProvider
                        keyField="id"
                        data={this.state.usuarios}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div style={{ textAlign: "center" }}>
                            <SearchBar
                              placeholder="Pesquisar"
                              id="search_txt"
                              style={{ width: 470, marginRight: 10 }}
                              {...props.searchProps}
                            />
                            <ClearSearchButton text="Limpar" { ...props.searchProps} />
                            <BootstrapTable
                              pagination={paginator()}
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                      :
                            <BarLoader
                                css={override}
                                sizeUnit={'px'}
                                size={60}
                                color={'#123b7a'}
                                loading={!this.state.loadArray}
                            />
                            }
                    </div>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    </>
                </div>
            );
        } else {
            return <Redirect from="/" to="/" />;
        }
    }
}

export default ListUsers;
