import React, { Component } from 'react'
import {
    Modal,
} from 'react-bootstrap';
import Chart from "react-apexcharts";
import api from '../../services/api';
import CheckBox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import Spinner from 'reactstrap/lib/Spinner';
import { css } from '@emotion/core';
import { BarLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  marginTop: 100px;
`;

export default class barChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: '',
            arrayCategorias: '',
            arrayDataPre: '',
            arrayDataTotal: '',
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: []
                },
                yaxis:{
                    labels:{
                        style: {
                            colors: ['#f0f0f0']
                        }
                    }
                },
                legend: {labels:{colors: ['#f0f0f0']}},
            },
            series: [
                {
                    name: "series-1",
                    data: []
                },
                {
                    name: "series-2",
                    data: []
                }
            ],
            ordem_servico: '',
            cliente: '',
            tipo: '',
            arrayLabel: '',
            cont: 0,
            checkedA: true,
            checkedB: false,
            loading: true
        };
    }

    async preencherGrafico(data, pos, data2) {
        const state = this.state;
        this.setState({ cont: this.state.cont + 1 });
        let tempoPos;
        if (pos == 0) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Soldar Ponteira', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 1) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Alinhamento', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 2) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Usinagem Desbaste', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 3) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Solda Almofada', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 4) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Usinagem Almofada', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 5) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Solda Revestimento', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 6) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Usinagem Revestimento', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 7) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Lixamento', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 8) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Desbaste Lateral Filetes', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 9) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Polimento', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 10) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Usinagem Final', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }
        if (pos == 11) {
            let tempoPre = data.replace(/:/g, ".");
            if (data2 != null)
                tempoPos = data2.replace(/:/g, ".");
            this.setState({
                arrayCategorias: [...state.arrayCategorias, this.state.cont],
                arrayLabel: [...state.arrayLabel, { nome: 'Retrabalho', dataPre: tempoPre, dataPos: tempoPos }],
                arrayDataPre: [...state.arrayDataPre, tempoPre],
                arrayDataTotal: [...state.arrayDataTotal, tempoPos],
            })
        }

    }

    preencherArrayGrafico() {
        this.setState({
            options: {
                xaxis: {
                    categories: this.state.arrayCategorias, labels: {
                        style: {
                            color: "#f0f0f0",
                            fontSize: '13px',
                            fontFamily: 'Nunito',
                            cssClass: 'apexcharts-xaxis-label',
                        }
                    }
                },
                legend: {labels:{colors: ['#f0f0f0']}},
                yaxis:{
                    labels:{
                        style: {
                            colors: ['#f0f0f0']
                        }
                    }
                },
            }
        })

        this.setState({
            series: [
                {
                    name: "Gráfico Tempos Prévios",
                    data: this.state.arrayDataPre
                },
                {
                    name: "Gráfico Tempos Totais",
                    data: this.state.arrayDataTotal
                }
            ],
            loading: false
        })
    }

    async limparGrafico() {

        await this.setState({
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: []
                },
                yaxis:{
                    labels:{
                        style: {
                            colors: ['#f0f0f0']
                        }
                    }
                },
                legend: {labels:{colors: ['#f0f0f0']}},
            },
            series: [],
            arrayDataPre: '',
            arrayDataTotal: '',
            arrayCategorias: '',
            arrayLabel: '',
            cont: 0
        })
    }
    async temposPre() {
        const response = await api.post('/recuperacao/buscarTemposPre', {
            idrecuperacao: this.state.id_recuperacao
        })
        const data = response.data;

        const response2 = await api.post('/recuperacao/buscarTempoTotal', {
            idrecuperacao: this.state.id_recuperacao
        })
        const data2 = response2.data;

        data.forEach(obj => {
            data2.forEach(obj2 => {
                for (let i = 0; i < 12; i++) {
                    if (obj[`t${i}`] != null || obj2[`t${i}`] != null)
                        this.preencherGrafico(obj[`t${i}`], i, obj2[`t${i}`])
                }
            })
        })
    }

    async temposTotal() {
        const response = await api.post('/recuperacao/buscarTempoTotal', {
            idrecuperacao: this.state.id_recuperacao
        })
        const data = response.data;

        data.forEach(obj => {
            for (let i = 1; i < 11; i++) {
                if (obj[`t${i}`] != null)
                    this.preencherGrafico(obj[`t${i}`], i)
            }
        })
    }

    async componentDidMount() {
        await this.limparGrafico();

        if (typeof (this.props.itemSelecionado) === 'object') {
            await this.setState({ id_recuperacao: this.props.itemSelecionado.idrecuperacao, 
                ordem_servico: this.props.itemSelecionado.ordem_servico, cliente: this.props.itemSelecionado.cliente, 
                tipo: this.props.itemSelecionado.tipo });
            await this.temposPre();
            this.preencherArrayGrafico();
        }

    }

    async componentWillReceiveProps(props) {
        await this.limparGrafico();

        if (typeof (props.itemSelecionado) === 'object') {
            await this.setState({ id_recuperacao: props.itemSelecionado.idrecuperacao, ordem_servico: props.itemSelecionado.ordem_servico, cliente: props.itemSelecionado.cliente, tipo: props.itemSelecionado.tipo });

            await this.temposPre();
            this.preencherArrayGrafico();
        }

    }

    handleChange(op) {
        this.props.handleItemSelecionado(this.props.arrayRoscaDupla[op]);
        this.setState({
            idrecuperacao: this.props.arrayRoscaDupla[op].idrecuperacao,
            loading: true
        })

        if (op == 0) {
            this.setState({ checkedA: !this.state.checkedA, checkedB: false, });
        }
        if (op == 1) {
            this.setState({ checkedA: false, checkedB: !this.state.checkedB });
        }
    }

    render() {
        const self = this.props;
        const state = this.state;
        return (
            <div>
                <Modal
                    show={self.open}
                    onHide={() => (self.close(2), this.limparGrafico())}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton><p style={{ fontSize: 16, marginBottom: 0, fontWeight: 'bold' }}>Ordem de Serviço: {state.ordem_servico}</p>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>Cliente: {state.cliente}, Tipo: {state.tipo}</p>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {this.props.tipoRosca === 'dupla' ?
                            <div style={{ textAlign: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <CheckBox
                                            checked={this.state.checkedA}
                                            onChange={() => this.handleChange(0)}
                                            value="checkedA"
                                            color="primary"
                                            type="radio"
                                            id="checar"
                                        />
                                    }
                                    label={<p style={{fontSize:'14px', margin:'10px',fontWeight:'bold'}}>Lado Esquerdo</p>}
                                />
                                <FormControlLabel
                                    control={
                                        <CheckBox
                                            checked={this.state.checkedB}
                                            onChange={() => this.handleChange(1)}
                                            value="checkedB"
                                            color="primary"
                                            type="radio"
                                            id="checar"
                                        />
                                    }
                                    label={<p style={{fontSize:'14px', margin:'10px',fontWeight:'bold'}}>Lado Direito</p>}
                                />
                            </div>
                            : null}

                        {!this.state.loading ?
                            <div style={{ marginTop: 50, marginBottom: 50, textAlign: 'center' }}>
                                <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="bar"
                                    width="550"
                                />
                                <ol>
                                    {this.state.arrayCategorias.length != 0 ?
                                        this.state.arrayLabel.map(item => {
                                            return (
                                                <li><b>{item.nome}</b>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <i className="fa fa-clock-o" style={{ color: 'rgb(38,160,252)' }} />{String(item.dataPre).replace('.', ':')}
                                                        <i className="fa fa-clock-o" style={{ color: 'rgb(38,231,166)', marginLeft: 20 }} />{String(item.dataPos).replace('.', ':')}
                                                    </div>
                                                </li>
                                            )
                                        })
                                        : null
                                    }

                                </ol>
                            </div>
                            :
                            <BarLoader
                                css={override}
                                sizeUnit={'px'}
                                size={60}
                                color={'#123b7a'}
                                loading={this.state.loading}
                            />
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
