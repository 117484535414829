import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import api from 'services/api';

function ChartBySection(props) {
    const { dataOs, url } = props;
    const [optionsChart, setOptions] = useState({});
    const [series, setSeries] = useState([]);

    const findSection = async () => {
        const { data } = await api.get(`/recuperacao/${url}`, {
            params: {
                recuperacao: dataOs
            }
        });

        const steps = data.map(step => ({
            name: step.etapa,
            data: [step.tempos.tempo_pre, step.tempos.tempo_total]
        }))
       
        fillChart(steps);
    }

    const fillChart = (data) => {

        setOptions({
            colors: [
                'rgb(0,57,117)',
                '#f5821f',
                '#a04000',
                '#797d7f',
                '#884ea0',
                '#2e4053',
                '#f0b27a',
                '#85929e',
                '#cd6155',
                '#a93226',
                '#fae5d3',
                '#7fb3d5'
            ],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
            },
            yaxis:{
                labels:{
                    style: {
                        colors: ['#f0f0f0']
                    }
                }
            },
            xaxis: {
                categories: data.map(item => item.name),
                labels: {
                    show: false,
                    style: {
                        color: "#f0f0f0",
                        fontSize: '18px',
                        fontFamily: 'Nunito',
                        cssClass: 'apexcharts-xaxis-label',
                    }
                }
            },
            legend: {labels:{colors: ['#f0f0f0']}},
        })

        setSeries(data)

    }

    useEffect(() => {
        findSection();
    }, [url])

    return <Chart
        options={optionsChart}
        series={series}
        type="bar"
        height="200"
    />
}

export default ChartBySection;