import React, { memo, useCallback } from "react";

const BotoesClientes = ({onOpen, onDelete, row, setClienteSelecionado}) => {
  
  const onEdit = useCallback(() => {
    setClienteSelecionado(row.id)
    onOpen()
  },[row])
  
  return (
    <div>
      <i
      onClick={() => onEdit()}
        className="fa fa-edit"
        style={{
          fontSize: 20,
          marginLeft: 10,
          cursor: "pointer",
        }}
      />
      <i
      onClick={() => onDelete(row.id)}
        className="fa fa-trash"
        style={{
          color: '#c5152f',
          fontSize: 20,
          marginLeft: 10,
          cursor: "pointer",
        }}
      />
    </div>
  );
};
export default memo(BotoesClientes);
