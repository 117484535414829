/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import avatar from "assets/img/faces/avatar_generico.jpg";
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import Select from 'components/Select';
import React, { Component } from 'react';
import {
    Button, Col, ControlLabel,
    FormControl, FormGroup, Modal, Row
} from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import api from '../../../services/api';
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

class ListUsers extends Component {
    state = {
        usuarios: '',
        primeiroNome: '',
        sobrenome: '',
        email: '',
        username: '',
        id_tipo_usuario: '',
        tipos: [],
        idUsuario: '',
        funcao: '',
        foto: '',
        senha: '',
    };

    constructor() {
        super();
    }
    async componentWillReceiveProps(props) {
        if (typeof (props.dados) === 'object') {
            this.setState({
                idUsuario: props.dados.id_usuario,
                primeiroNome: props.dados.primeiroNome,
                sobrenome: props.dados.sobrenome,
                email: props.dados.email,
                username: props.dados.username,
                tipo: props.dados.descricao,
                id_tipo_usuario: props.dados.id_tipo_usuario,
                funcao: props.dados.funcao,
                foto: props.dados.foto,
            })
        }
    }

    async componentDidMount() {
        const usuarios = await api.get("/config/listarUsuarios");
        this.setState({ usuarios: usuarios.data });
        const tipos = await api.get("/config/listarTiposUsuarios");
        tipos.data.map(item => {
            this.setState({ tipos: [...this.state.tipos, { value: item.id_perfil, label: item.descricao }] })
        })
    }

    mobileSidebarToggle(e) {
        if (this.state.sidebarExists === false) {
            this.setState({
                sidebarExists: true,
            });
        }
        e.preventDefault();
        document.documentElement.classList.toggle('nav-open');
        var node = document.createElement('div');
        node.id = 'bodyClick';
        node.onclick = function () {
            this.parentElement.removeChild(this);
            document.documentElement.classList.toggle('nav-open');
        };
        document.body.appendChild(node);
    }

    async handleChange(e, op) {
        if (op == 1)
            this.setState({ primeiroNome: e.target.value })
        if (op == 2)
            this.setState({ sobrenome: e.target.value })
        if (op == 3)
            this.setState({ email: e.target.value })
        if (op == 4)
            this.setState({ username: e.target.value })
        if (op == 5)
            this.setState({ id_tipo_usuario: e.value })
        if (op == 6)
            this.setState({ funcao: e.target.value })
        if (op == 7) {
            const result = await toBase64(e.target.files[0]).catch(e => e);
            this.setState({ foto: result })
        }
        if (op == 8)
            this.setState({ senha: e.target.value })
    }

    async editarDados() {
        await api.post("/config/atualizarDadosUsuario", {
            id_usuario: this.state.idUsuario,
            primeiroNome: this.state.primeiroNome,
            sobrenome: this.state.sobrenome,
            email: this.state.email,
            username: this.state.username,
            id_tipo_usuario: this.state.id_tipo_usuario,
            funcao: this.state.funcao,
            foto: this.state.foto,
            senha: this.state.senha
        })
        this.props.close();
    }

    render() {
        var self = this;
        if (localStorage.getItem('login') == 'on') {
            return (
                <div>
                    <Modal
                        show={this.props.open}
                        onHide={() => this.props.close()}
                        size="lg"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>Editar Usuário</Modal.Header>
                        <Modal.Body
                        >
                            <div className="container">
                                <div style={{ marginLeft: 160 }}>
                                    {this.state.foto != 0 ?
                                        <img src={this.state.foto} style={{ height: 200 }} />
                                        :
                                        <img src={avatar} style={{ height: 200 }} />
                                    }
                                </div>
                                <FormInputs
                                    ncols={["col-md-6"]}
                                    properties={[
                                        {
                                            label: "Nome",
                                            type: "text",
                                            bsClass: "form-control",
                                            defaultValue: self.state.primeiroNome,
                                            onChange: ((e) => self.handleChange(e, 1))
                                        }
                                    ]}
                                />
                                <FormInputs
                                    ncols={["col-md-6"]}
                                    properties={[
                                        {
                                            label: "Sobrenome",
                                            type: "text",
                                            bsClass: "form-control",
                                            defaultValue: self.state.sobrenome,
                                            onChange: ((e) => self.handleChange(e, 2))
                                        }
                                    ]}
                                />
                                <FormInputs
                                    ncols={["col-md-6"]}
                                    properties={[
                                        {
                                            label: "E-mail",
                                            type: "text",
                                            bsClass: "form-control",
                                            defaultValue: self.state.email,
                                            onChange: ((e) => self.handleChange(e, 3))
                                        }
                                    ]}
                                />
                                <FormInputs
                                    ncols={["col-md-6"]}
                                    properties={[
                                        {
                                            label: "Username",
                                            type: "text",
                                            bsClass: "form-control",
                                            defaultValue: self.state.username,
                                            onChange: ((e) => self.handleChange(e, 4))
                                        }
                                    ]}
                                />
                                <FormInputs
                                    ncols={["col-md-6"]}
                                    properties={[
                                        {
                                            label: "Função",
                                            type: "text",
                                            bsClass: "form-control",
                                            defaultValue: self.state.funcao,
                                            onChange: ((e) => self.handleChange(e, 6))
                                        }
                                    ]}
                                />
                                <FormInputs
                                    ncols={["col-md-6"]}
                                    properties={[
                                        {
                                            label: "Editar Foto",
                                            type: "file",
                                            bsClass: "form-control",
                                            onChange: ((e) => self.handleChange(e, 7))
                                        }
                                    ]}
                                />
                                <Row>
                                    <Col md={6}>
                                        <FormGroup controlId="formControlsTextarea">
                                            <ControlLabel>Tipo</ControlLabel>
                                            <Select placeholder={this.state.tipo} onChange={(e) => this.handleChange(e, 5)} options={this.state.tipos} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <ControlLabel>Alterar Senha</ControlLabel>
                                            <FormControl
                                                placeholder="Digite a nova senha"
                                                type="password"
                                                onChange={(e) => this.handleChange(e, 8)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.props.close()}>Cancelar</Button>
                            <Button variant="primary" onClick={() => this.editarDados()} >Editar</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        } else {
            return <Redirect from="/" to="/" />;
        }
    }
}

export default ListUsers;
