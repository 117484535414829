import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from '../../../services/api';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginator from 'react-bootstrap-table2-paginator';
const { SearchBar } = Search;

export default class ModalUnirRelatorio extends Component {
    state = {
        empresa: '',
        rosca: '',
        cilindro: '',
        data: '',
        tipoRosca: '',
        recuperacoes: [],
        carregou: false,
        idRelatorio: '',
    }

    async componentDidMount() {
        const response = await api.get('/get/consultarRecuperacaoStatus1');

        if (response.data != 0) {
            await this.setState({ recuperacoes: response.data, carregou: true });
        }
    }

    componentWillReceiveProps(props) {
        if (props !== this.props) {
            const dados = props.dados;
            this.setState({
                idCadastro: dados.id_cadastro,
                empresa: dados.empresa,
                rosca: dados.rosca_nominal,
                cilindro: dados.cilindro_nominal,
                data: dados.data,
            })
        }
    }

    async unirRelatorio(id) {
        const ordemServico = id;
        if (window.confirm("Deseja unir a esta recuperação?")) {
            await api.put('/report/unirRecuperacao', {
                ordem_servico: ordemServico,
                id_cadastro: this.state.idCadastro,
            })
            alert("Relatório inserido na OS com sucesso")
        }
    }

    render() {
        const { show, close } = this.props;
        const botaoRelatorio = (cell, row, rowIndex) => {
            return (
                <div>
                    <i
                        title='Inserir Relatório'
                        onClick={() =>
                            this.unirRelatorio(row.ordem_servico)
                        }
                        className="fa fa-check-circle"
                        style={{  fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
                    />

                </div>
            )
        };
        const columns = [
            {
                dataField: 'ordem_servico',
                text: 'OS',
                headerAlign: 'center'
            },
            {
                dataField: 'cliente',
                text: 'Cliente',
                headerAlign: 'center'

            },
            {
                dataField: 'tipo',
                text: 'Tipo',
                headerAlign: 'center'

            },
            {
                dataField: 'date',
                text: 'Data',
                headerAlign: 'center'
            },
            {
                dataField: 'button',
                text: 'Ações',
                formatter: botaoRelatorio,
                headerAlign: 'center'
            },
        ];

        return (
            <>
                <Modal
                    show={show}
                    onHide={() => close(3)}
                    className="ModalGraficoRelatorio" backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton><p style={{fontSize: "20px"}}>Unir Recuperação</p></Modal.Header>
                    <Modal.Body
                    >
                        <div id="">
                            <div id="dados">
                            <ul style={{listStyleType: "none", display: "flex", justifyContent: "space-evenly", border:"#f0f0f0 solid", fontSize: "18px"}}>
                                    <li>Empresa: {this.state.empresa}</li>
                                    <li>Rosca: {this.state.rosca}</li>
                                    <li>Cilindro: {this.state.cilindro}</li>
                                    <li>Data: {this.state.data}</li>
                                    {/* <p></p> */}
                                </ul>
                            </div>
                            <div id="uniao" style={{marginTop: "3%"}}>
                                <p id="titulo" style={{fontSize: "20px"}}>Unir Cadastro</p>
                                <ToolkitProvider
                                    keyField="id"
                                    data={this.state.recuperacoes}
                                    columns={columns}
                                    search
                                >
                                    {props => (
                                        <div style={{ textAlign: 'center' }}>
                                            <SearchBar
                                                placeholder="Pesquisar"
                                                id="search_txt"
                                                style={{ width: 470, marginRight: 10 }}
                                                {...props.searchProps}
                                            />
                                            {this.state.carregou ?
                                                <BootstrapTable
                                                    pagination={paginator()}
                                                    {...props.baseProps}
                                                />
                                                : null}

                                        </div>
                                    )}
                                </ToolkitProvider>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => close(3)} variant="primary">Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}