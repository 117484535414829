import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from '../../../services/api';
import CanvasJSReact from '../ChartsJs/canvasjs.react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import '../Modais/style.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import reactLogo from '../../../assets/img/reactlogo.png';
import { setarValoresDuplo } from '../Functions/setarValoresGraficoDuplo';
import { setarValoresDuploPorLado } from '../Functions/setarValoresGraficoDuplosPorLado';
import { setarValores } from '../Functions/setarValoresGraficoUnico';
import {
    BarLoader
} from 'react-spinners';
import { css } from '@emotion/core';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import styled from 'styled-components';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  marginTop: 100px;
`;


var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export default class Modais extends Component {
    state = {
        id: 0,
        cilindroX: [],
        cilindroY: [],
        roscaX: [],
        roscaY: [],
        produto: '',
        empresa: '',
        data: '',
        roscaNominal: '',
        cilindroNominal: '',
        options: {},
        options2: {},
        optionsApex: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                text: 'Product Trends by Month',
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: [''],
            }
        },
        cilindroDuploE: [],
        cilindroDuploD: [],
        cilindroDuploEN: [],
        cilindroDuploDN: [],
        roscaDuplaE: [],
        roscaDuplaD: [],
        roscaDuplaEN: [],
        roscaDuplaDN: [],
        rosca: false,
        cilindro: false,
        cilindroDuplo: false,
        roscaDupla: false,
        escala: 3,
        ordem_servico: '',
        loading: true,
        observacao: '',
        imprimir: false
    };

    //
    async buscarValores(item, lado, op) {
        if (op == 1) { // cilindro
            if (lado == 1) {
                this.setState({
                    cilindroDuploE: [...this.state.cilindroDuploE, {
                        x: parseFloat(item.l), y: parseFloat(item.diam),
                    }],
                    cilindroDuploEN: [...this.state.cilindroDuploEN, { x: parseFloat(item.l), y: parseFloat(item.cilindro_nominal) }],
                    cilindroDuplo: true,
                });
            }
            if (lado == 2) {
                this.setState({
                    cilindroDuploD: [...this.state.cilindroDuploD, { x: parseFloat(item.l), y: parseFloat(item.diam) }],
                    cilindroDuploDN: [...this.state.cilindroDuploDN, {
                        x: parseFloat(item.l), y: parseFloat(item.cilindro_nominal),
                    }],
                    cilindroDuplo: true,
                });
            }
            if (lado == 0) {
                this.setState({
                    cilindroX: [...this.state.cilindroX, { x: parseFloat(item.l), y: parseFloat(item.diam) }],
                    cilindroY: [...this.state.cilindroY, { x: parseFloat(item.l), y: parseFloat(item.nom) }],
                    cilindro: true
                })
            }
        }

        if (op == 2) { //rosca
            if (lado == 1)
                this.setState({
                    roscaDuplaE: [...this.state.roscaDuplaE, { x: parseFloat(item.l), y: parseFloat(item.diam) }],
                    roscaDuplaEN: [...this.state.roscaDuplaEN, { x: parseFloat(item.l), y: parseFloat(item.nom) }],
                    roscaDupla: true,
                })
            if (lado == 2)
                this.setState({
                    roscaDuplaD: [...this.state.roscaDuplaD, { x: parseFloat(item.l), y: parseFloat(item.diam) }],
                    roscaDuplaDN: [...this.state.roscaDuplaDN, { x: parseFloat(item.l), y: parseFloat(item.nom) }],
                    roscaDupla: true
                })
            if (lado == 0)
                await this.setState({
                    roscaX: [...this.state.roscaX, { x: parseFloat(item.l), y: parseFloat(item.diam) }],
                    roscaY: [...this.state.roscaY, { x: parseFloat(item.l), y: parseFloat(item.nom) }],
                    rosca: true
                })
        }

    }

    imprimir = () => {
        window.scrollTo(0, 0)
        // const pdf = new jsPDF('l', 'pt');
        // pdf.internal.scaleFactor = 30;
        // var pdf = new jsPDF("p", "mm", "a4");
        // pdf.setFontSize(12);
        // pdf.setFont('helvetica');
        html2canvas(document.querySelector('#gerarPDFGrafico'), { scale: 1 }).then(canvas => {
            var imgData = canvas.toDataURL("image/png");
            var pdf = new jsPDF('l', 'pt', [canvas.width, canvas.height]);
            var pdfWidth = pdf.internal.pageSize.getWidth() - 100;
            var pdfHeight = pdf.internal.pageSize.getHeight() - 100;
            pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth, pdfHeight);
            pdf.save(`${this.state.empresa}.pdf`);
        })
        // html2canvas(document.querySelector('#gerarPDFGrafico'), { scale: 2 }).then(canvas => {
        //     // const imgData = canvas.toDataURL('image/jpg');
        //     // pdf.addImage(imgData, 'jpg', 80, 30, 650, 400);
        //     // pdf.setFontSize(12);
        //     // pdf.save('download.pdf');

        //     var width = canvas.width;
        //     var height = canvas.height;
        //     var millimeters = {};
        //     millimeters.width = Math.floor(width * 0.264583);
        //     millimeters.height = Math.floor(height * 0.264583);

        //     var imgData = canvas.toDataURL(
        //         'image/png');
        //     pdf.deletePage(1);
        //     pdf.addPage(millimeters.width, millimeters.height);
        //     pdf.addImage(imgData, 'PNG', 0, 0);
        //     pdf.save('WebSiteScreen.pdf');
        // });
    };

    // o duplo não foi feito ainda
    returnMaxY(v1, v2, v3, v4, type) { // retornar o maior valor do array do eixo y. Type = 1 (só uma peça), type = 2 (duas peças)
        const { escala } = this.state;
        if (type == 1) {
            let value1 = Math.max.apply(Math, v1.map(function (o) { return o.y; }))
            let value2 = Math.max.apply(Math, v2.map(function (o) { return o.y; }))
            let max = Math.max(value1, value2);
            return max + parseInt(escala);
        }
        if (type == 2) {
            // buscar maior valor
            let value1 = Math.max.apply(Math, v1.map(function (o) { return o.y; }))
            let value2 = Math.max.apply(Math, v2.map(function (o) { return o.y; }))
            let value3 = Math.max.apply(Math, v3.map(function (o) { return o.y; }))
            let value4 = Math.max.apply(Math, v4.map(function (o) { return o.y; }))
            let max = Math.max(value1, value2, value3, value4);
            return max + parseInt(escala);

        }
    }

    returnMinY(v1, v2, v3, v4, type) {
        const { escala } = this.state;
        if (type == 1) {
            let value1 = Math.min.apply(Math, v1.map(function (o) { return o.y; }))
            let value2 = Math.min.apply(Math, v2.map(function (o) { return o.y; }))
            let min = Math.min(value1, value2);
            return min - parseInt(escala);
        }
        if (type == 2) {
            // buscar menor valor
            let value1 = Math.min.apply(Math, v1.map(function (o) { return o.y; }))
            let value2 = Math.min.apply(Math, v2.map(function (o) { return o.y; }))
            let value3 = Math.min.apply(Math, v3.map(function (o) { return o.y; }))
            let value4 = Math.min.apply(Math, v4.map(function (o) { return o.y; }))
            let min = Math.min(value1, value2, value3, value4);
            return min - parseInt(escala);

        }
    }

    async setarValorGrafico() {

        if (this.state.rosca && !this.state.cilindro) {
            let max = this.returnMaxY(this.state.roscaX, this.state.roscaY, 0, 0, 1);
            let min = this.returnMinY(this.state.roscaX, this.state.roscaY, 0, 0, 1);
            let options = setarValores(this.state.roscaX, this.state.roscaY, 0, 0, 1, min, max);
            this.setState({ options: options });
        }
        if (!this.state.rosca && this.state.cilindro) {
            let max = this.returnMaxY(this.state.cilindroX, this.state.cilindroY, 0, 0, 1);
            let min = this.returnMinY(this.state.cilindroX, this.state.cilindroY, 0, 0, 1);
            let options = setarValores(this.state.cilindroX, this.state.cilindroY, 0, 0, 2, min, max);
            this.setState({ options: options });
        }
        if (this.state.rosca && this.state.cilindro) {
            let max = this.returnMaxY(this.state.roscaX, this.state.roscaY, this.state.cilindroX, this.state.cilindroY, 2);
            let min = this.returnMinY(this.state.roscaX, this.state.roscaY, this.state.cilindroX, this.state.cilindroY, 2);

            let options = setarValores(this.state.roscaX, this.state.roscaY, this.state.cilindroX, this.state.cilindroY, 3, min, max);
            this.setState({ options: options });
        }
        if (this.state.roscaDupla && !this.state.cilindroDuplo) {
            const { roscaDuplaE, roscaDuplaEN, roscaDuplaD, roscaDuplaDN } = this.state;
            let max = 3;
            let min = 3;
            let options = {};

            if (roscaDuplaE.length && roscaDuplaEN.length && roscaDuplaD.length && roscaDuplaDN.length) {
                max = this.returnMaxY(this.state.roscaDuplaE, this.state.roscaDuplaEN, this.state.roscaDuplaD, this.state.roscaDuplaDN, 2)
                min = this.returnMinY(this.state.roscaDuplaE, this.state.roscaDuplaEN, this.state.roscaDuplaD, this.state.roscaDuplaDN, 2)
            }

            if (roscaDuplaE.length && roscaDuplaD.length) {
                options = setarValoresDuplo(this.state.roscaDuplaE, this.state.roscaDuplaEN, this.state.roscaDuplaD, this.state.roscaDuplaDN, 2, min, max);
            }
            if (roscaDuplaE.length && !roscaDuplaD.length) {
                max = this.returnMaxY(this.state.roscaDuplaE, this.state.roscaDuplaEN, 0, 0, 1)
                min = this.returnMinY(this.state.roscaDuplaE, this.state.roscaDuplaEN, 0, 0, 1)

                options = setarValores(this.state.roscaDuplaE, this.state.roscaDuplaEN, 0, 0, 1, min, max);
            } else if (roscaDuplaD.length && !roscaDuplaE.length) {
                max = this.returnMaxY(this.state.roscaDuplaD, this.state.roscaDuplaDN, 0, 0, 1)
                min = this.returnMinY(this.state.roscaDuplaD, this.state.roscaDuplaDN, 0, 0, 1)

                options = setarValores(this.state.roscaDuplaD, this.state.roscaDuplaDN, 0, 0, 1, min, max);
            }

            console.log("options: ", options);

            this.setState({ options: options });
        }
        if (this.state.cilindroDuplo && !this.state.roscaDupla) {
            const { cilindroDuploE, cilindroDuploEN, cilindroDuploD, cilindroDuploDN } = this.state;
            let max = 3;
            let min = 3;

            if (cilindroDuploE.length && cilindroDuploEN.length && cilindroDuploD.length && cilindroDuploDN.length) {
                max = this.returnMaxY(this.state.cilindroDuploE, this.state.cilindroDuploEN, this.state.cilindroDuploD, this.state.cilindroDuploDN, 1);
                min = this.returnMinY(this.state.cilindroDuploE, this.state.cilindroDuploEN, this.state.cilindroDuploD, this.state.cilindroDuploDN, 1);
            }
            let options = setarValoresDuplo(this.state.cilindroDuploE, this.state.cilindroDuploEN, this.state.cilindroDuploD, this.state.cilindroDuploDN, 1, min, max);
            this.setState({ options: options });
        }
        if (this.state.roscaDupla && this.state.cilindroDuplo) {
            const { cilindroDuploE, cilindroDuploD } = this.state;
            let max = 3;
            let min = 3;

            if (cilindroDuploE.length) {
                max = this.returnMaxY(this.state.cilindroDuploE, this.state.cilindroDuploEN, this.state.roscaDuplaE, this.state.roscaDuplaEN, 1);
                min = this.returnMinY(this.state.cilindroDuploE, this.state.cilindroDuploEN, this.state.roscaDuplaE, this.state.roscaDuplaEN, 1);
            }

            if (cilindroDuploD.length) {
                max = this.returnMaxY(this.state.cilindroDuploD, this.state.cilindroDuploDN, this.state.roscaDuplaD, this.state.roscaDuplaDN, 2);
                min = this.returnMinY(this.state.cilindroDuploD, this.state.cilindroDuploDN, this.state.roscaDuplaD, this.state.roscaDuplaDN, 2);
            }

            if (!this.state.roscaDuplaE.length) {
            }


            let options = setarValoresDuploPorLado(this.state.cilindroDuploE, this.state.cilindroDuploEN, this.state.roscaDuplaE, this.state.roscaDuplaEN, 1, min, max);

            this.setState({ options: options });
            let options2 = setarValoresDuploPorLado(this.state.cilindroDuploD, this.state.cilindroDuploDN, this.state.roscaDuplaD, this.state.roscaDuplaDN, 2, min, max)

            this.setState({ options2: options2 });
        }
        this.setState({ loading: false })


    }

    //
    async setarDadosCabecalho(r) {
        await this.setState({
            empresa: r.data[0].empresa, produto: r.data[0].produto, data: r.data[0].data,
            data: r.data[0].data, roscaNominal: r.data[0].rosca_nominal, cilindroNominal: r.data[0].cilindro_nominal,
            ordem_servico: r.data[0].ordem_servico,
            id: r.data[0].id_cadastro,
            observacao: r.data[0].obs
        })

    }

    async componentDidMount() {
        const r = await api.post("/report/buscarRelatorio", { filtro: 'r.id_cadastro', id_cadastro: this.props.id });
        await this.clearStates();
        if (r.data) {
            this.setarDadosCabecalho(r);
            r.data.map(async (item, index) => {
                if (item.id_tipo_rosca == 1) {
                    await this.buscarValores(item, item.lado, 1)
                }
                if (item.id_tipo_rosca == 2) {
                    await this.buscarValores(item, item.lado, 2)
                }
                if (item.id_tipo_rosca == 3) {
                    await this.buscarValores(item, item.lado, 3)
                }
            })
        }

        this.setarValorGrafico();
    }

    async componentWillReceiveProps(props) {
        const r = await api.post("/report/buscarRelatorio", { filtro: 'r.id_cadastro', id_cadastro: props.id });
        await this.clearStates();
        if (r.data) {
            this.setarDadosCabecalho(r);
            r.data.map(async (item, index) => {
                if (item.id_tipo_rosca == 1) {
                    await this.buscarValores(item, item.lado, 1)
                }
                if (item.id_tipo_rosca == 2) {
                    await this.buscarValores(item, item.lado, 2)
                }
                if (item.id_tipo_rosca == 3) {
                    await this.buscarValores(item, item.lado, 3)
                }
            })
        }
        this.setarValorGrafico();
    }
    async clearStates() {
        await this.setState({
            cilindroX: '',
            cilindroY: '',
            roscaX: '',
            roscaY: '',
            cilindroDuploE: '',
            cilindroDuploD: '',
            cilindroDuploEN: '',
            cilindroDuploDN: '',
            rosca: false,
            cilindro: false,
            cilindroDuplo: false,
            roscaDupla: false,
            roscaDuplaE: '',
            roscaDuplaD: '',
            roscaDuplaEN: '',
            roscaDuplaDN: '',
        })
    }

    handleInput = async (event) => {
        await this.setState({ escala: event.target.value })
    }

    handleScale = () => {
        this.clearStates();
        this.componentDidMount();
    }

    pdfNaame = () => {

        return `Relatório Dimensional ${this.state.id} ${this.state.empresa}` 
    }

    render() {
        return (
            <>
                {!this.state.loading ?
                    <Modal
                        show={this.props.show}
                        onHide={() => this.props.close(1)}
                        className="ModalGraficoRelatorio"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                Relatório
                            <div style={{ marginLeft: 'auto', marginRight: 40, display:'flex', 'alignItems': 'center' }}>
                                    <label style={{margin: 0}}>Escala</label>
                                    <input style={{ marginLeft: 10, marginRight: 10, width: 50, height: 30, display: 'inline', padding: '8px 5px' }} class="form-control" type="number" onChange={this.handleInput} />
                                    <button class="btn btn-sm" onClick={this.handleScale}>Ok</button>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body
                            ref={el => (this.componentRef = el)}
                        >
                            <div id="gerarPDFGrafico">
                                <div id="cabecalhoTexto">
                                    <img src={reactLogo} id="logo" />
                                    <p>Relatório Dimensional {this.state.id}</p>
                                    <p></p>
                                </div>
                                <div id="cabecalho">
                                    <div id="inside">
                                        <p>Data: </p>
                                        <p>{this.state.data}</p>
                                    </div>
                                    <div id="inside">
                                        <p>Rosca: </p>
                                        <p>Ø {this.state.roscaNominal} mm</p>
                                    </div>
                                    <div id="inside">
                                        <p>Cilindro: </p>
                                        <p>Ø {this.state.cilindroNominal} mm</p>
                                    </div>
                                    <div id="inside">
                                        <p>Empresa: </p>
                                        <p>{this.state.empresa}</p>
                                    </div>
                                    <div id="inside">
                                        <p>Produto: </p>
                                        <p>{this.state.produto}</p>
                                    </div>
                                    <div className="no-print" id="inside">
                                        <p>Observações: </p>
                                        <p>{this.state.observacao}</p>
                                    </div>
                                    {this.state.ordem_servico !== null ?
                                        <div id="inside">
                                            <p>Ordem de serviço: </p>
                                            <p>{this.state.ordem_servico}</p>
                                        </div>
                                        : null}
                                </div>
                                <div className="legenda">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p>Cabeçote</p>
                                        <ArrowBackIcon id="icon" />
                                    </div>
                                    <p>Comprimento (mm)</p>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <ArrowForwardIcon id="icon" />
                                        <p>Alimentação</p>
                                    </div>
                                </div>
                                {this.state.cilindroDuplo && this.state.roscaDupla ?
                                    <>
                                        <CanvasJSChart options={this.state.options} />
                                        <CanvasJSChart options={this.state.options2} />
                                    </>
                                    :
                                    <CanvasJSChart options={this.state.options} />

                                }

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactToPrint documentTitle={this.pdfNaame()} content={() => this.componentRef}>
                                <Button onClick ={() => this.props.close(1)} variant="primary">Cancelar</Button>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <Button onClick={handlePrint}>Gerar PDF</Button>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </Modal.Footer>
                    </Modal>
                    :
                    <BarLoader
                        css={override}
                        sizeUnit={'px'}
                        size={60}
                        color={'#123b7a'}
                        loading={!this.state.loadArray}
                    />
                }
            </>
        )

    }

}