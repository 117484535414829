import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import api from "../../services/api";
import BootstrapTable from "react-bootstrap-table-next";
import paginator from "react-bootstrap-table2-paginator";
import ModalGrafico from "./Modais/ModalGraficoRelatorio";
import ModalDados from "./Modais/ModalDadosRelatorio";
import ModalUnirRelatorio from "./Modais/ModalUnirRelatorio";
import Header from "./Header/HeadConsultar";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import ModalNominal from "./Modais/ModalNominal";
import { Button } from "reactstrap/lib";
import { Checkbox } from 'semantic-ui-react';
import { Redirect } from "react-router-dom";
const { SearchBar, ClearSearchButton } = Search;
class RelatorioDimensional extends Component {
  state = {
    dadosTabela: [],
    show: false,
    idCadastro: "",
    showModalDados: false,
    screen: 'LIST',
    carregou: false,
    showModalUnirRecuperacao: false,
    dadosModalUnir: "",
    shownModalNominal: false,
    measuredData: null,
    reportsToUnion: [],
    enableUnion: false
  };
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
  }

  async componentDidMount() {
    const r = await api.get("/report/consultarRelatorios");
    this.setState({ dadosTabela: r.data, carregou: true });
  }

  async showModal(r, i) {
    if (i == 1) await this.setState({ show: true, idCadastro: r.id_cadastro });
    if (i == 2)
      await this.setState({ showModalDados: true, idCadastro: r.id_cadastro });
  }

  async close(op) {
    if (op == 1) this.setState({ show: false });
    if (op == 2) this.setState({ showModalDados: false });
    if (op == 3) this.setState({ showModalUnirRecuperacao: false });
  }

  async deleteReport(r) {
    var confirm = window.confirm("Deseja deletar esta etapa?");
    if (confirm) {
      await api.post("/report/deleteRelatorio", {
        id_cadastro: r.id_cadastro,
      });
    }
    alert("Etapa removida com sucesso!")
  }

  option = (op) => {
    this.setState({ screen: op });
  };

  unirRecuperacaoModal(row) {
    this.setState({ showModalUnirRecuperacao: true, dadosModalUnir: row });
  }

  onClickUnionReport(idCadaster) {
    const { reportsToUnion } = this.state;
    const existsIdCadaster = reportsToUnion.some(id => idCadaster === id);

    if (existsIdCadaster) {
      const filterReports = reportsToUnion.filter(id => idCadaster !== id);
      return this.setState({ reportsToUnion: filterReports });
    }

    if (reportsToUnion.length > 1) {
      return alert('Não é permitido selecionar mais que 2 relatórios!');
    }


    if (!existsIdCadaster) {
      return this.setState({ reportsToUnion: [...reportsToUnion, idCadaster] });
    }

  }

  async onSaveUnion() {
    if (window.confirm("Você tem certeza que deseja unir estes relatórios?")) {
      await api.post("/report/unirCadatros", {
        novoCadastro: this.state.reportsToUnion[0],
        oldCadastro: this.state.reportsToUnion[1]
      })

      alert("Relatórios unidos com sucesso!")
    }
  }

  render() {
    const botoes = (cell, row, rowIndex, formatExtraData) => {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {formatExtraData.screen === "LIST" ? (
            <>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Gráfico do Relatório"
                onClick={() => this.showModal(row, 1)}
                className="fa fa-bar-chart"
                style={{
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: "pointer",
                  color: "rgb(31, 77, 255)",
                }}
              />

              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Excluir Relatório"
                onClick={() => this.deleteReport(row)}
                className="fa fa-trash"
                style={{
                  color: "#c5152f",
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
              />
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Inserir Relatório na OS"
                onClick={() => this.unirRecuperacaoModal(row)}
                className="fa fa-bars"
                style={{
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
              />
              {formatExtraData.enableUnion &&
                <Checkbox
                        checked={formatExtraData.reportsToUnion.length ?
                      formatExtraData.reportsToUnion.some(unionReportId => unionReportId === row.id_cadastro) ?
                        true : false : false}
                        onChange={() => this.onClickUnionReport(row.id_cadastro)}
                      />
                
              }
            </>
          ) : (
            <>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Editar Relatório"
                onClick={() => this.showModal(row, 2)}
                className="fa fa-list-alt"
                style={{
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
              />
              <i
                title="Ajuste de Relatório"
                onClick={() =>
                  this.setState({ shownModalNominal: true, measuredData: row })}
                className="fa fa-edit"
                style={{
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: "pointer",
                  color: "rgb(64, 255, 110)",
                }}
              />
            </>
          )}
        </div>
      );
    };

    const columns = [
      {
        dataField: "id_cadastro",
        text: "Código do Relatório",
        headerAlign: "center",
      },
      {
        dataField: "empresa",
        text: "Empresa",
        headerAlign: "center",
      },
      {
        dataField: "rosca_nominal",
        text: "Rosca",
        headerAlign: "center",
      },
      {
        dataField: "cilindro_nominal",
        text: "Cilindro",
        headerAlign: "center",
      },
      {
        dataField: "data",
        text: "Data",
        headerAlign: "center",
      },
      {
        dataField: "button",
        text: "Ações",
        formatter: botoes,
        formatExtraData: { screen: this.state.screen, reportsToUnion: this.state.reportsToUnion, enableUnion: this.state.enableUnion },
        headerAlign: "center",
      },
    ];

    if (localStorage.getItem('login') == 'on') { 
    return (
      <>
        <Header option={this.option} onEnableUnion={() => this.setState({ enableUnion: !this.state.enableUnion })} />
        <div style={{ marginTop: "3%", marginBottom: "10%" }}>
          <ModalGrafico
            show={this.state.show}
            close={this.close}
            id={this.state.idCadastro}
            backdrop="static"
            keyboard={false}
          />
          <ModalUnirRelatorio
            show={this.state.showModalUnirRecuperacao}
            close={this.close}
            id={this.state.idCadastro}
            dados={this.state.dadosModalUnir}
            backdrop="static"
            keyboard={false}
          />
          <ModalDados
            show={this.state.showModalDados}
            close={this.close}
            id={this.state.idCadastro}
            backdrop="static"
            keyboard={false}
          />
          <ModalNominal
            show={this.state.shownModalNominal}
            close={() => {
              this.setState({ shownModalNominal: false })
              this.componentDidMount()
              }}
            dados={this.state.measuredData}
            backdrop="static"
            keyboard={false}
          />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Relatórios"
                  content={
                    <div style={{ textAlign: "center" }}>
                      <ToolkitProvider
                        keyField="id"
                        data={this.state.dadosTabela || []}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div style={{ textAlign: "center" }}>
                            <SearchBar
                              placeholder="Pesquisar"
                              id="search_txt"
                              style={{ width: 470, marginRight: 10 }}
                              {...props.searchProps}
                            />
                            <ClearSearchButton
                              text="Limpar"
                              {...props.searchProps}
                            />
                            <BootstrapTable
                              pagination={paginator()}
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                      {this.state.enableUnion &&
                        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginRight: 50 }}>
                          {this.state.reportsToUnion.length === 2 &&
                            <Button onClick={() => this.onSaveUnion()} style={{ width: 100, background: '#36b336', borderColor: 'transparent' }}>
                              <p style={{ color: 'white', padding: 0, margin: 0, }}>Salvar</p>
                            </Button>
                          }
                          <Button
                            onClick={() => this.setState({ enableUnion: false })}
                            style={{ width: 100, marginLeft: 10, background: '#f94040', borderColor: 'transparent' }}><p style={{ color: 'white', padding: 0, margin: 0, }}>Cancelar</p></Button>
                        </div>
                      }
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>

        </div>
      </>
    );
  } else {
    return (
      <Redirect to="/" from="" />
    )
  }
}
}

export default RelatorioDimensional;
