import React from 'react'
import SelectRN from 'react-select';



const Select = (props) =>{
    const styles ={
        control: (base, state) => ({
            ...base,
            background: "transparent",
            borderColor: "#888888"
        }),
        menu: (base, state) => ({
            ...base,
            background: "#364044",
        
        }),
        option: (base, state) => ({
            ...base,
            background: state.isFocused ? '#5C5C5C':"#364044" ,
            color: "#f0f0f0"
        })
    }

    return <SelectRN styles={styles} {...props}/> 
}

export default Select