import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { useState } from 'react';
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import '../assets/css/recuperacao.css';
import Background from '../assets/img/loginImage.jpg';
import api from '../services/api';
const labelOffset = -6

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundColor: '#414141'
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//teste
export default function SignInSide(props) {
  const classes = useStyles();

  const [email_usuario, setEmail] = useState('');
  const [senha_usuario, setSenha] = useState('');

  async function login(event) {
    if (event != undefined) {
      if (event.key === 'Enter') {
        const res = await api.post('/posts/buscar', { email: email_usuario, senha: senha_usuario })

        if (res.data.length == 1) {
          localStorage.setItem('login', 'on');
          localStorage.setItem('usuario', JSON.stringify(res.data[0]));
          props.history.push({ pathname: "/admin/dashboard" });
        }
        else alert('Login/senha invalidos');
      }
    } else {
      const res = await api.post('/posts/buscar', { email: email_usuario, senha: senha_usuario });
      if (res.data.length == 1) {
        localStorage.setItem('login', 'on');
        localStorage.setItem('usuario', JSON.stringify(res.data[0]));
        props.history.push({ pathname: "/admin/dashboard" });
      }
      else alert('Login/senha inválidos');
    }

  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5}  elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Login Roscan
          </Typography>
          <form className={classes.form} noValidate method="POST" encType="application/json" action="//http:localhost:3333/login/verify">
          <FormGroup>
            <ControlLabel>Login/Usuário</ControlLabel>
            <FormControl 
              onChange={e => setEmail(e.target.value)} 
              id="email_usuario"  
              name="email" 
              value={email_usuario} 
              autoComplete="email"
              autoFocus
            />
        </FormGroup>
          <FormGroup>
            <ControlLabel>Senha</ControlLabel>
            <FormControl 
              onChange={e => setSenha(e.target.value)} 
              id="password"  
              name="senha_usuario" 
              value={senha_usuario}
              autoComplete="current-password"
              type="password"
            />
        </FormGroup>
<div>
        <Button
                            bsStyle="info"
                            onClick={() => login()}
                            btnFill
                            fill
                           style={{width: '100%'}}
                          >
                            Entrar
                      </Button>
</div>
       
            
          </form>
        </div>
      </Grid>
    </Grid>
  );
}