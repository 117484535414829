import React, { Component } from "react";
import {
    Navbar,
    NavItem,
    Nav,
    NavDropdown,
    MenuItem,
} from 'react-bootstrap';
class UserProfile extends Component {

    sair() {
        localStorage.removeItem('login');
        localStorage.removeItem('usuario');
        this.props.props.history.push({ pathname: '/login' });
    }

    render() {
        return (
            <div>
                <Navbar fluid>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <a>Configurações</a>
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            <NavDropdown
                                eventKey={2}
                                title="Ações"
                                id="basic-nav-dropdown-right"
                            >
                                <MenuItem
                                    onClick={() =>
                                        this.props.action(1)
                                    }
                                    eventKey={2.1}
                                >
                                    Meu perfil
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        this.props.action(2)
                                    }
                                    eventKey={2.1}
                                >
                                    Inserir usuário
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        this.props.action(3)
                                    }
                                    eventKey={2.1}
                                >
                                    Listar usuários
                                </MenuItem>
                            </NavDropdown>
                            <NavItem eventKey={3} href="#" onClick={() => this.sair()} >
                                Sair
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default UserProfile;
