import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from '../../../services/api';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';

export default class Modais extends Component {
    state = {
        nominalCilindro: '',
        nominalRosca: '',
        id_cadastro: '',
        desg: '',
        tipo: 0,
        ids: ''
    }

    componentWillReceiveProps(props) {
        if (props.dados != undefined) {
            this.setState({
                nominalCilindro: props.dados.cilindro_nominal,
                nominalRosca: props.dados.rosca_nominal,
                id_cadastro: props.dados.id_cadastro,
            })
        }

    }

    handleChange(e, op) {
        if (op == 1)
            this.setState({ nominalCilindro: e.target.value, tipo: 1 });
        if (op == 2)
            this.setState({ nominalRosca: e.target.value, tipo: 2 });
    }


    async callType(op) {
        const r = await api.post("/report/buscarValores", {
            id_cadastro: this.state.id_cadastro,
            id_tipo_rosca: op
        })

        return r.data;
    }

    async editar() {
        if (this.state.tipo != 0) {

            //para cálculo do Cilindros
            // if (this.state.tipo == 1) {
            const cilindro = await this.callType(1);
            if (cilindro != 0) {
                cilindro.map(item => {
                    if (this.state.nominalRosca < item.diam) {
                        this.setState({
                            desg: [...this.state.desg,
                            {
                                id_relatorio: item.id_relatorio,
                                desg: ((parseFloat(item.diam)) - parseFloat(this.state.nominalCilindro)).toFixed(2)
                            }],
                        });
                    }
                })
            }
            await api.put('/report/attNominal', {
                id_cadastro: this.state.id_cadastro,
                nominalCilindro: this.state.nominalCilindro,
                nominalRosca: this.state.nominalRosca,
                tipo: 1
            })
            //Atualização de desgaste comentada devido a informação faltante
            // await api.put('/report/attDesgaste', {
            //     desgaste: this.state.desg,
            // })
            // }

            //para cálculo de rosca
            // if (this.state.tipo == 2) {
            const rosca = await this.callType(2)
            if (rosca != 0) {
                rosca.map(item => {
                    if (this.state.nominalRosca > item.diam) {
                        this.setState({
                            desg: [...this.state.desg,
                            {
                                id_relatorio: item.id_relatorio,
                                desg: ((parseFloat(this.state.nominalRosca) - parseFloat(item.diam))).toFixed(2)
                            }]
                        });
                    }
                })
            }
            await api.put('/report/attNominal', {
                id_cadastro: this.state.id_cadastro,
                nominalRosca: this.state.nominalRosca,
                nominalCilindro: this.state.nominalCilindro,
                tipo: 2
            })
            //Atualização de desgaste comentada devido a informação faltante
            // await api.put('/report/attDesgaste', {
            //     desgaste: this.state.desg,
            // })
            // }
        }

        if (this.editar){
            alert("Relatório ajustado com sucesso!");
              return 
           }  
        // this.props.close(2);
        
    }

    render() {
        const self = this;
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={() => this.props.close(2)}
                    className="ModalGraficoRelatorio"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>Ajuste de Relatório</Modal.Header>
                    <Modal.Body
                    >
                        <div >
                            <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                    {
                                        label: "Nominal Rosca",
                                        type: "text",
                                        bsClass: "form-control",
                                        defaultValue: self.state.nominalRosca,
                                        onChange: ((e) => self.handleChange(e, 2))
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                    {
                                        label: "Nominal Cilindro",
                                        type: "text",
                                        bsClass: "form-control",
                                        defaultValue: self.state.nominalCilindro,
                                        onChange: ((e) => self.handleChange(e, 1))
                                    }
                                ]}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.close(2)} variant="primary">Cancelar</Button>
                        <Button onClick={() => this.editar()} variant="primary">Editar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
