import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormInputs } from 'components/FormInputs/FormInputs.jsx';
import React, { Component } from 'react';
import moment from 'moment';
import { Button, Col, ControlLabel, FormGroup, Modal, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Select from 'components/Select';
import CheckBox from '@material-ui/core/Checkbox';
import { Checkbox } from 'semantic-ui-react';
import api from 'services/api';
import { clienteService } from 'services/clientes';
import { css } from '@emotion/core';
import './editar.css';
import { BarLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;

export default class EditarRecuperacao extends Component {
    state = {
        tipo_template:'',
        ordem_servico: '',
        idrecuperacao: '',
        tipo: '',
        arrayCategorias: '',
        arrayTempoPos: '',
        arrayEditarTempos: [],
        info: '',
        checked: [false, false, false, false, false, false, false, false, false, false, false, false],
        checkedTrue: [true, true, true, true, true, true, true, true, true, true, true, true],
        finalizar: false,
        brunimento: false,
        nitretacao: false,
        emenda: false,
        desempenamento: false,
        nano_ceramica: false,
        cromagem_descromagem: false,
        conica: 0,
        cliente: [],
        clienteSelecionado: null,
        codigo: null,
        comprimento_recuperado: null,
        comprimento_total:null,
        diametro: null,
        diametro_menor: null,
        diametro_maior: null,
        folga: null,
        previsao: '',
        nf_entrada: null,
        nf_saida: null,
        data_final: '',
        checkedA: true,
        checkedB: false,
        loading: true
    }

    constructor(props) {
        super(props)

        this.ordem_servico = this.ordem_servico.bind(this);
        this.cliente = this.cliente.bind(this);
        this.tipo = this.tipo.bind(this);
        this.info = this.info.bind(this);
    }

    async limparDados() {
        await this.setState({
            arrayTempoPos: '',
            arrayCategorias: '',
            bPre: false,
            bPos: false,
            arrayEditarTempos: [],
            checked: [false, false, false, false, false, false, false, false, false, false, false, false],
            checkedTrue: [true, true, true, true, true, true, true, true, true, true, true, true],
            finalizar: false,
        })
    }

    //Função que captura o resultado do diametro e comprimento total com base no arquivo recuperacao.js e monta o template pré configurado para o modal que este arquivo representa.
    montarTemplateTipo(tipo,diametro, comprimento_total, diametro_menor, diametro_maior, conica) {
        let tipo_template = '';
        let diametro_ = diametro === null ? '-' : diametro;
        let comprimento_total_ = comprimento_total === null ? '-' : comprimento_total;
        let diametro_menor_ = diametro_menor === null ? '-' : diametro_menor;
        let diametro_maior_ = diametro_maior === null ? '-' : diametro_maior;
        let conica_ = conica === null ? 0 : conica;
          
          tipo_template =  tipo === 'Rosca Dupla' ? 
          conica_ === 1 ? `Dupla Rosca Cônica ${diametro_menor_} / ${diametro_maior_} ` :
            `Dupla Rosca ${diametro_} LD ${comprimento_total_ === '-' || diametro_ === '-' ? '-' : Math.round(parseFloat(comprimento_total_)/parseFloat(diametro_))}` : 
            tipo === 'Mono Rosca' ? `Mono Rosca ${diametro_} LD ${comprimento_total_ === '-' || diametro_ === '-' ? '-' : Math.round(parseFloat(comprimento_total_)/parseFloat(diametro_))}` : tipo
           
        console.log('Template montada: ', tipo_template, conica_)
        return tipo_template;
      }


    async componentDidMount() {
        this.limparDados();
        
        if (typeof this.props.itemSelecionado === 'object') {
          let tipo_template = this.montarTemplateTipo(
            this.props.itemSelecionado.tipo,
            this.props.itemSelecionado.diametro,
            this.props.itemSelecionado.comprimento_total,
            this.props.itemSelecionado.diametro_menor,
            this.props.itemSelecionado.diametro_maior,
            this.props.itemSelecionado.conica
          );
      
          // Conversão da data_final
          const dataFinalString = this.props.itemSelecionado.data_final;
          const dataFinalFormatada = moment(dataFinalString, 'DD/MM/YYYY').format('YYYY-MM-DD');
      
          // Conversão da previsao
          const previsaoString = this.props.itemSelecionado.previsao;
          const previsaoFormatada = moment(previsaoString, 'DD/MM/YYYY').format('YYYY-MM-DD');
      
          await this.setState({
            brunimento: this.props.itemSelecionado.brunimento === 1 ? true : false,
            nitretacao: this.props.itemSelecionado.nitretacao === 1 ? true : false,
            emenda: this.props.itemSelecionado.emenda === 1 ? true : false,
            desempenamento: this.props.itemSelecionado.desempenamento === 1 ? true : false,
            nano_ceramica: this.props.itemSelecionado.nano_ceramica === 1 ? true : false,
            cromagem_descromagem: this.props.itemSelecionado.cromagem_descromagem === 1 ? true : false,
            conica: this.props.itemSelecionado.conica,
            clienteSelecionado: this.props.itemSelecionado.clienteId ? { value: this.props.itemSelecionado.clienteId, label: this.props.itemSelecionado.cliente } : null,
            idrecuperacao: this.props.itemSelecionado.idrecuperacao,
            ordem_servico: this.props.itemSelecionado.ordem_servico,
            cliente: this.props.itemSelecionado.clienteId ? null : this.props.itemSelecionado.cliente,
            tipo: this.props.itemSelecionado.tipo,
            tipo_template: tipo_template,
            info: this.props.itemSelecionado.info,
            codigo: this.props.itemSelecionado.codigo,
            comprimento_recuperado: this.props.itemSelecionado.comprimento_recuperado,
            comprimento_total: this.props.itemSelecionado.comprimento_total,
            diametro: this.props.itemSelecionado.diametro,
            diametro_menor: this.props.itemSelecionado.diametro_menor,
            diametro_maior: this.props.itemSelecionado.diametro_maior,
            folga: this.props.itemSelecionado.folga,
            previsao: previsaoFormatada,
            nf_entrada: this.props.itemSelecionado.nf_entrada,
            nf_saida: this.props.itemSelecionado.nf_saida,
            data_final: dataFinalFormatada
          });
      
          await this.temposPre();
        }
      
        const clientes = await clienteService.get();
        const formatedClientes = await clientes.data.map(item => ({ value: item.id, label: item.nome }));
      
        this.setState({
          clientes: formatedClientes,
          loading: false
        });
      }

    //Seta o modal "Preencher Gráfico" na Consulta de Recuperação
    async preencherGrafico(data, pos, data2, idPre, idPos, s) {

        if (data) {
            data = data.replace(".", ":");
        } else {
            data = '00:00'
        }

        const state = this.state;
        if (pos == 0) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Soldar Ponteira', tempo: data, id: idPre, etapa: 0 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Soldar Ponteira', tempo2: data2, id: idPos, etapa: 0, status: s }],

            })
        }
        if (pos == 1) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Alinhamento', tempo: data, id: idPre, etapa: 1 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Alinhamento', tempo2: data2, id: idPos, etapa: 1, status: s }],

            })
        }
        if (pos == 2) {
            await this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Usinagem Desbaste', tempo: data, id: idPre, etapa: 2 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Usinagem Desbaste', tempo2: data2, id: idPos, etapa: 2, status: s }],

            })
        }
        if (pos == 3) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Solda Almofada', tempo: data, id: idPre, etapa: 3 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Solda Almofada', tempo2: data2, id: idPos, etapa: 3, status: s }],

            })
        }
        if (pos == 4) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Usinagem Almofada', tempo: data, id: idPre, etapa: 4 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Usinagem Almofada', tempo2: data2, id: idPos, etapa: 4, status: s }],

            })
        }
        if (pos == 5) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Solda Revestimento', tempo: data, id: idPre, etapa: 5 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Solda Revestimento', tempo2: data2, id: idPos, etapa: 5, status: s }],

            })
        }
        if (pos == 6) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Usinagem Revestimento', tempo: data, id: idPre, etapa: 6 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Usinagem Revestimento', tempo2: data2, id: idPos, etapa: 6, status: s }],

            })
        }
        if (pos == 7) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Lixamento', tempo: data, id: idPre, etapa: 7 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Lixamento', tempo2: data2, id: idPos, etapa: 7, status: s }],

            })
        }
        if (pos == 8) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Desbate', tempo: data, id: idPre, etapa: 8 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Desbate', tempo2: data2, id: idPos, etapa: 8, status: s }],

            })
        }
        if (pos == 9) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Polimento', tempo: data, id: idPre, etapa: 9 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Polimento', tempo2: data2, id: idPos, etapa: 9, status: s }],

            })
        }
        if (pos == 10) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Usinagem Final', tempo: data, id: idPre, etapa: 10 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Usinagem Final', tempo2: data2, id: idPos, etapa: 10, status: s }],

            })
        }
        if (pos == 11) {
            this.setState({
                arrayCategorias: [...state.arrayCategorias, { nome: 'Retrabalho', tempo: data, id: idPre, etapa: 11 }],
                arrayTempoPos: [...state.arrayTempoPos, { nome: 'Retrabalho', tempo2: data2, id: idPos, etapa: 11, status: s }],

            })
        }

    }


    async finalizarOS() {
        let contAux = [];

        console.log("array tempos pos: ", this.state.arrayTempoPos);

        for (let i = 0; i < this.state.arrayTempoPos.length; i++) {
            if (this.state.arrayTempoPos[i].etapa == 0) {
                const e0 = await api.post('/recuperacao/buscarStatusZero', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e0.data == 1) {
                    contAux.push(true);
                }
                else
                    contAux.push(false);
            }

            if (this.state.arrayTempoPos[i].etapa == 1) {
                const e1 = await api.post('/recuperacao/buscarStatusPrimeira', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e1.data == 1) {
                    contAux.push(true);
                }
                else
                    contAux.push(false);
            }

            if (this.state.arrayTempoPos[i].etapa == 2) {
                const e2 = await api.post('/recuperacao/buscarStatusSegunda', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e2.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 3) {
                const e3 = await api.post('/recuperacao/buscarStatusTerceira', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e3.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 4) {
                const e4 = await api.post('/recuperacao/buscarStatusQuarta', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e4.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 5) {
                const e5 = await api.post('/recuperacao/buscarStatusQuinta', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e5.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 6) {
                const e6 = await api.post('/recuperacao/buscarStatusSexta', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e6.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 7) {
                const e7 = await api.post('/recuperacao/buscarStatusSetima', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e7.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 8) {
                const e8 = await api.post('/recuperacao/buscarStatusOitava', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e8.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 9) {
                const e9 = await api.post('/recuperacao/buscarStatusNona', {
                    id: this.state.arrayTempoPos[i].id
                })
                if (e9.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
            if (this.state.arrayTempoPos[i].etapa == 10) {
                const e10 = await api.post('/recuperacao/buscarStatusDecima', {
                    id: this.state.arrayTempoPos[i].id
                })

                if (e10.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }

            if (this.state.arrayTempoPos[i].etapa == 11) {
                const e11 = await api.post('/recuperacao/buscarStatusDecimaPrimeira', {
                    id: this.state.arrayTempoPos[i].id
                })

                if (e11.data == 1) {
                    contAux.push(true);
                }
                else {
                    contAux.push(false);
                }
            }
        }

        const finish = contAux.filter(item => item === false);


        if (!finish.length) {
            await api.post('/recuperacao/attStatusFimEtapaRecuperacao', {
                idrecuperacao: this.state.idrecuperacao
            })
        }
    }

    async temposPre() {
        let id = null;
        id = this.state.idrecuperacao;

        const response = await api.post('/recuperacao/buscarTemposPre', {
            idrecuperacao: id
        })
        const data = response.data;

        const response2 = await api.post('/recuperacao/buscarTempoTotal', {
            idrecuperacao: id
        })
        const data2 = response2.data;

        if (data2 !== 0) {
            data.forEach(obj => {
                data2.forEach(obj2 => {
                    for (let i = 0; i < 12; i++) {
                        if (obj[`t${i}`] != null || obj2[`t${i}`] != null)
                            //data, pos, data2, idPre, idPos, s
                            this.preencherGrafico(obj[`t${i}`], i, obj2[`t${i}`], obj[`id${i}`], obj2[`id${i}`], obj2[`s${i}`]);
                    }
                })
            })
        }
        this.setState({ loading: false })

    }


    async componentWillReceiveProps(props) {
        await this.limparDados()
        if (typeof props.itemSelecionado === 'object') {
            let tipo_template =  this.montarTemplateTipo(
                this.props.itemSelecionado.tipo,
                this.props.itemSelecionado.diametro,
                this.props.itemSelecionado.comprimento_total,
                this.props.itemSelecionado.diametro_menor,
                this.props.itemSelecionado.diametro_maior,
                this.props.itemSelecionado.conica
            )
            const previsaoFormatada = moment(props.itemSelecionado.previsao, 'DD/MM/YYYY').format('YYYY-MM-DD');
            const dataFinalFormatada = moment(props.itemSelecionado.data_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
            this.setState({
                brunimento: props.itemSelecionado.brunimento === 1 ? true : false,
                nitretacao: props.itemSelecionado.nitretacao === 1 ? true : false,
                emenda: this.props.itemSelecionado.emenda === 1 ? true : false,
                desempenamento: this.props.itemSelecionado.desempenamento === 1 ? true : false,
                nano_ceramica: this.props.itemSelecionado.nano_ceramica === 1 ? true : false,
                cromagem_descromagem: this.props.itemSelecionado.cromagem_descromagem === 1 ? true : false,
                clienteSelecionado: props.itemSelecionado.clienteId ? { value: props.itemSelecionado.clienteId, label: props.itemSelecionado.cliente } : null,
                idrecuperacao: props.itemSelecionado.idrecuperacao,
                ordem_servico: props.itemSelecionado.ordem_servico,
                cliente: props.itemSelecionado.clienteId ? null : props.itemSelecionado.cliente,
                tipo: props.itemSelecionado.tipo,
                tipo_template: tipo_template,
                info: props.itemSelecionado.info,
                codigo: props.itemSelecionado.codigo,
                comprimento_recuperado: props.itemSelecionado.comprimento_recuperado,
                comprimento_total: props.itemSelecionado.comprimento_total,
                conica: props.itemSelecionado.conica,
                diametro: props.itemSelecionado.diametro,
                diametro_menor: props.itemSelecionado.diametro_menor,
                diametro_maior: props.itemSelecionado.diametro_maior,
                folga: props.itemSelecionado.folga,
                previsao: previsaoFormatada,
                nf_entrada: props.itemSelecionado.nf_entrada,
                nf_saida: props.itemSelecionado.nf_saida,
                data_final: dataFinalFormatada
            })
            await this.temposPre()
        }

    }

    bPos() {
        if (this.state.arrayEditarTempos.length >= 1) {
            alert("Clique em editar para salvar as alterações")
            return 0;
        }
        this.setState({ bPos: true, bPre: false, arrayEditarTempos: [], tempo: 'tempo2', finalizar: !this.state.finalizar });
    }

    bPre() {
        if (this.state.arrayEditarTempos.length >= 1) {
            alert("Clique em editar para salvar as alterações")
            return 0;
        }
        this.setState({ bPos: false, bPre: true, arrayEditarTempos: [], tempo: 'tempo', finalizar: !this.state.finalizar });
    }

    finish() {
        this.setState({ finalizar: true, bPos: false, bPre: false })
    }

    async editarDados() {
        //editar ordem de serviço, cliente, tipo, info
        await api.post('/recuperacao/editarDados', {
          ordem_servico: this.state.ordem_servico,
          cliente: this.state.cliente,
          clienteId: this.state.clienteSelecionado ? this.state.clienteSelecionado.value : null,
          tipo: this.state.tipo,
          info: this.state.info,
          idrecuperacao: this.state.idrecuperacao,
          nitretacao: this.state.nitretacao,
          brunimento: this.state.brunimento,
          emenda: this.state.emenda,
          desempenamento: this.state.desempenamento,
          nano_ceramica: this.state.nano_ceramica,
          cromagem_descromagem: this.state.cromagem_descromagem,
          codigo: this.state.codigo,
          previsao: moment(this.state.previsao).format("DD/MM/YYYY"),
          nf_entrada: this.state.nf_entrada,
          nf_saida: this.state.nf_saida,
          data_final: moment(this.state.data_final).format("DD/MM/YYYY"),
          diametro: String(this.state.diametro).replace(',', '.'),
          diametro_menor: String(this.state.diametro_menor).replace(',', '.'),
          diametro_maior: String(this.state.diametro_maior).replace(',', '.'),
          comprimento_recuperado: String(this.state.comprimento_recuperado).replace(',', '.'),
          comprimento_total: String(this.state.comprimento_total).replace(',', '.'),
          folga: String(this.state.folga).replace(',', '.')
        });

        if (this.editarDados){
            alert("OS editada com sucesso!");
              return 
           }  
    }

    async ordem_servico(event) {
        await this.setState({ ordem_servico: event.target.value });
    }
    async cliente(event) {
        await this.setState({ cliente: event.target.value });
    }
    async tipo(event) {
        await this.setState({ tipo: event.target.value });
    }
    async info(event) {
        await this.setState({ info: event.target.value });
    }
    async mudar(v) {
        this.setState({ info: v.target.value })
    }

    async finalizarEtapa(id, etapa, status) {
        await api.post('/posts/cadastrarStatusFimEtapa', {
            id_etapa: id,
            etapa: etapa,
            status_fim_etapa: status
        })
    }

    async ativarEtapa(etapa, id) {
        await api.post('/recuperacao/ativarEtapa', {
            id: id,
            etapa: etapa
        })
    }

    async verificarEtapa(etapa, id, index) {
        const r = await api.post("/recuperacao/verificarEtapa", {
            etapa: etapa,
            id: id
        })

        const status = r.data[0].status_fim_etapa;
        if (status == 1) {
            var confirm = window.confirm("Deseja ativar esta etapa?");
            if (confirm == true) {
                this.ativarEtapa(etapa, id);
                const { checkedTrue } = this.state;
                for (let i = 0; i < 12; i++) {
                    if (index == i) {
                        this.state.checkedTrue[index] = !this.state.checkedTrue[index];
                        this.setState({ checkedTrue });
                        return true;
                    }
                }
            } else {
                return status;
            }
        }
        else {
            return status;
        }
    }

    handleChangeStatus = async (etapa, id, index, status) => {
        var r = await this.verificarEtapa(etapa, id, index);
        if (r == 0) {
            var confirm = window.confirm("Deseja finalizar esta etapa?");
            if (confirm == true) {
                await this.finalizarEtapa(id, etapa, status);
                this.finalizarOS();
                const { checked } = this.state;
                for (let i = 0; i < 12; i++) {
                    if (index == i) {
                        this.state.checked[index] = !this.state.checked[index];
                        this.setState({ checked });
                    }
                }
            } else {
                return 0;
            }
        }
    }

    onAfterSaveCell = async (row, cellName, cellValue) => {
        if (this.state.tempo == "tempo2") {
            await api.post("/recuperacao/editarTempo", {
                id: cellValue.id,
                tempo: cellValue.tempo2,
                etapa: cellValue.etapa
            })
        }
    }

    onAfterSaveCellPre = async (row, cellName, cellValue) => {
        await api.post("/recuperacao/editarTempoPre", {
            id: cellValue.id,
            tempo: cellValue.tempo,
            etapa: cellValue.etapa
        })
    }

    handleDetalhes(label) {
        if (label === 'Brunimento') {
                this.setState({
                    brunimento: !this.state.brunimento,
                })
            
        }
        if (label === 'Nitretação') {
            this.setState({
                nitretacao: !this.state.nitretacao
            })
        }
        if (label === 'Emenda') {
            this.setState({
                emenda: !this.state.emenda
            })
        }
        if (label === 'Desempenamento') {
            this.setState({
                desempenamento: !this.state.desempenamento
            })
        }
        if (label === 'Aplicação Nano Cerâmica') {
            this.setState({
                nano_ceramica: !this.state.nano_ceramica
            })
        }
        if (label === 'Cromagem/Descromagem') {
            this.setState({
                cromagem_descromagem: !this.state.cromagem_descromagem
            })
        }
    }

    handleInput = (event) => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    }

    handleChange(op) {

        console.log("array rosca dupla: ", this.props.arrayRoscaDupla)

        this.props.handleItemSelecionado(this.props.arrayRoscaDupla[op]);
        this.setState({
            idrecuperacao: this.props.arrayRoscaDupla[op].idrecuperacao,
            arrayTempoPos: '',
            arrayEditarTempos: [],
            arrayCategorias: '',
            loading: true,
        })

        if (op == 0) {
            this.setState({ checkedA: !this.state.checkedA, checkedB: false, });
        }
        if (op == 1) {
            this.setState({ checkedA: false, checkedB: !this.state.checkedB });
        }
    }

    defaultValueEdit = () => {

    }


    render() {
        const self = this.props;
        const t = this;
        const state = this.state;

        const botoes = (cell, row, rowIndex) => {
            return (
                <div>
                    <Checkbox
                        checked={[true, false]}
                        color="primary"
                    />
                </div>
            );
        };
        const columns = [{
            dataField: 'nome',
            text: 'Nome',
            headerAlign: 'center'
        },
        {
            dataField: state.tempo,
            text: 'Tempo',
            headerAlign: 'center'
        },
        {
            dataField: 'etapa',
            text: 'Etapa',
            headerAlign: 'center',
            edit: false
        },

        ];

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.onAfterSaveCell  // a hook for after saving cell
        };

        const cellEditPropPre = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.onAfterSaveCellPre  // a hook for after saving cell
        };


        return (
            <div>
                <Modal
                    show={self.open}
                    onHide={() => self.close('nitretacao')}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                    className="modal-editarRecuperacao"
                >
                    <Modal.Header closeButton><p style={{ fontSize: 16, marginBottom: 0, fontWeight: 'bold' }}>Ordem de Serviço: {state.ordem_servico}</p>
                    </Modal.Header>
                    <Modal.Body
                    >
                        {this.props.tipoRosca === 'dupla' ?
                            <div style={{ textAlign: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <CheckBox
                                            checked={this.state.checkedA}
                                            onChange={() => this.handleChange(0)}
                                            value="checkedA"
                                            color="primary"
                                            type="radio"
                                            id="checar"
                                        />
                                    }
                                    label={<p style={{fontSize:'14px', margin:'10px',fontWeight:'bold'}}>Lado Esquerdo</p>}
                                />
                                <FormControlLabel
                                    control={
                                        <CheckBox
                                            checked={this.state.checkedB}
                                            onChange={() => this.handleChange(1)}
                                            value="checkedB"
                                            color="primary"
                                            type="radio"
                                            id="checar"
                                        />
                                    }
                                    label={<p style={{fontSize:'14px', margin:'10px',fontWeight:'bold'}}>Lado Direito</p>}
                                />
                            </div>
                            : null}
                        {!this.state.loading ?
                            <>

                                <Row>
                                <Col md={6} style={{ width:'100%', display:'grid', gridTemplateColumns:'repeat(5, 1fr)', gap:'2%', marginTop:'2%'  }}>
                                    {this.props.itemSelecionado.clienteId ? (
                                        <>
                                            <FormGroup>
                                                <ControlLabel>Clientes</ControlLabel>
                                                <Select
                                                    placeholder="Selecione o cliente"
                                                    onChange={(event) => this.setState({ clienteSelecionado: event })}
                                                    value={this.state.clienteSelecionado}
                                                    options={this.state.clientes}
                                                />
                                            </FormGroup>
                                        </>
                                    ) : (
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Cliente',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        value: this.state.cliente,
                                                        onChange: (event) => this.setState({ cliente: event.target.value })
                                                    }
                                                ]}
                                            />
                                        )}
                                        <div style={{ display: this.state.conica  === 1 ? 'none': 'block' }}>
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Diamêtro',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: self.itemSelecionado.diametro,
                                                        name: 'diametro',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />
                                            </div>
                                            <div style={{ display: this.state.conica === 1 ? 'flex' : 'none', gap: '1rem' }}>
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Diamêtro Menor',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: self.itemSelecionado.diametro_menor,
                                                        name: 'diametro_menor',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Diamêtro Maior',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: self.itemSelecionado.diametro_maior,
                                                        name: 'diametro_maior',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />
                                            </div>
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Nº Orçamento',
                                                        type: 'number',
                                                        bsClass: 'form-control',
                                                        defaultValue: self.itemSelecionado.codigo,
                                                        name: 'codigo',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />
                                            
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Comprimento Total',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: self.itemSelecionado.comprimento_total,
                                                        name: 'comprimento_total',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />

                                            
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Previsão',
                                                        type: 'date',
                                                        bsClass: 'form-control',
                                                        defaultValue: this.state.previsao,
                                                        name: 'previsao',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />

                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Comprimento Recuperado',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: self.itemSelecionado.comprimento_recuperado,
                                                        name: 'comprimento_recuperado',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />

                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'NF Entrada',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: this.state.nf_entrada,
                                                        name: 'nf_entrada',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />

                                            
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'NF Saída',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: this.state.nf_saida,
                                                        name: 'nf_saida',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />

                                            
                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Data Saída',
                                                        type: 'date',
                                                        bsClass: 'form-control',
                                                        defaultValue: this.state.data_final,
                                                        name: 'data_final',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />

                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Folga',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue: self.itemSelecionado.folga,
                                                        name: 'folga',
                                                        onChange: this.handleInput
                                                    },
                                                ]}
                                            />

                                            <FormInputs
                                                ncols={['col-md-12']}
                                                properties={[
                                                    {
                                                        label: 'Tipo',
                                                        type: 'text',
                                                        bsClass: 'form-control',
                                                        defaultValue:this.state.tipo_template,
                                                        disabled:true
                                                        //onChange: this.tipo
                                                    },
                                                ]}
                                            />
                                </Col>
                                </Row>
                                
                                <Row>
                                    <Col md="12">
                                        <label>Informações Gerais</label>
                                        <textarea class="form-control" style={{ width: '100%', resize: 'vertical' }} value={this.state.info} onChange={val => this.mudar(val)}></textarea>
                                    </Col>
                                </Row>
                                <div className='blocoServicos' style={{ display: 'grid', gridTemplateColumns:'repeat(6, 1fr)', placeItems: 'center center' }}>
                                    <Checkbox
                                        style={{marginTop: 10, display:'grid'  }}
                                        label='Brunimento'
                                        onClick={() => this.handleDetalhes('Brunimento')}
                                        checked={this.state.brunimento}
                                    />
                                    <Checkbox
                                        style={{marginTop: 10, display:'grid'}}
                                        label='Nitretação'
                                        onClick={() => this.handleDetalhes('Nitretação')}
                                        checked={this.state.nitretacao}
                                    />
                                    <Checkbox
                                        style={{ marginTop: 10, display:'grid' }}
                                        label='Emenda'
                                        onClick={() => this.handleDetalhes('Emenda')}
                                        checked={this.state.emenda}
                                    />
                                    <Checkbox
                                        style={{ marginTop: 10, display:'grid'}}
                                        label='Desempenamento'
                                        onClick={() => this.handleDetalhes('Desempenamento')}
                                        checked={this.state.desempenamento}
                                    />
                                    <Checkbox
                                        style={{ marginTop: 10, display:'grid'  }}
                                        label='Aplicação Nano Cerâmica'
                                        onClick={() => this.handleDetalhes('Aplicação Nano Cerâmica')}
                                        checked={this.state.nano_ceramica}
                                    />
                                    <Checkbox
                                        style={{ marginTop: 10, display:'grid'  }}
                                        label='Cromagem/Descromagem'
                                        onClick={() => this.handleDetalhes('Cromagem/Descromagem')}
                                        checked={this.state.cromagem_descromagem}
                                    />
                                </div>
                                <div style={{ textAlign: 'center', display:'grid', gridTemplateColumns:'repeat(3, 1fr)', placeItems:'center center' }}>
                                    <Button onClick={() => this.bPre()} className="bPre">Tempos Pré</Button>
                                    <Button onClick={() => this.bPos()} className="bPre2">Tempos Pós</Button>
                                    <Button onClick={() => this.finish()} className="bPre2">Finalizar</Button>
                                </div>
                                <div className="line" />
                                <div>
                                    {this.state.bPre && !this.state.bPos ?
                                        <div style={{ textAlign: 'center' }}>
                                            <ToolkitProvider
                                                keyField="id"
                                                data={this.state.arrayCategorias}
                                                columns={columns}
                                            >
                                                {props => (
                                                    <BootstrapTable
                                                        pagination={paginator()}
                                                        cellEdit={cellEditFactory(cellEditPropPre)}
                                                        {...props.baseProps}
                                                    />
                                                )}

                                            </ToolkitProvider>
                                        </div>
                                        : !this.state.bPre && this.state.bPos ?
                                            <div style={{ textAlign: 'center' }}>
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={this.state.arrayTempoPos}
                                                    columns={columns}
                                                >
                                                    {props => (
                                                        <BootstrapTable
                                                            pagination={paginator()}
                                                            cellEdit={cellEditFactory(cellEditProp)}
                                                            {...props.baseProps}
                                                        />
                                                    )}

                                                </ToolkitProvider>
                                            </div>
                                            : this.state.finalizar ?
                                                <div  style={{ textAlign: 'center' }}>
                                                    <div className='blocoLabel' style={{ display:'grid', gridTemplateColumns:'repeat(5, 1fr)'}}>
                                                        {this.state.arrayTempoPos != '' ?
                                                            this.state.arrayTempoPos.map((item, index) => {
                                                                if (index <= 4) {
                                                                    return (
                                                                        <div>
                                                                            <p style={{ fontSize: 12 }}>{item.nome}</p>
                                                                            {item.status == 1 ?
                                                                                <FormControlLabel
                                                                                    style={{ marginLeft: 2 }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={this.state.checkedTrue[index]}
                                                                                            color="primary"
                                                                                            onClick={() => this.handleChangeStatus(item.etapa, item.id, index, this.state.checkedTrue[index])}
                                                                                        />
                                                                                    }
                                                                                    label="Finalizada"
                                                                                />
                                                                                :
                                                                                <FormControlLabel
                                                                                    style={{ marginLeft: 2 }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={this.state.checked[index]}
                                                                                            onClick={() => this.handleChangeStatus(item.etapa, item.id, index, this.state.checkedTrue[index])}
                                                                                            color="primary"
                                                                                        />
                                                                                    }
                                                                                    label="Finalizar"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        null
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                        }
                                                    </div>
                                                    <div style={{ display:'grid', gridTemplateColumns:'repeat(5, 1fr)'}}>
                                                        {this.state.arrayTempoPos != '' ?
                                                            this.state.arrayTempoPos.map((item, index) => {
                                                                if (index >= 5) {
                                                                    return (
                                                                        <div className='blocoLabel'>
                                                                            <p style={{ fontSize: 12 }}>{item.nome}</p>
                                                                            {item.status == 1 ?
                                                                                <FormControlLabel
                                                                                    style={{ marginLeft: 2 }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={this.state.checkedTrue[index]}
                                                                                            color="primary"
                                                                                            onClick={() => this.handleChangeStatus(item.etapa, item.id, index, this.state.checkedTrue[index])}
                                                                                        />
                                                                                    }
                                                                                    label="Finalizada"
                                                                                    class="checkbox"
                                                                                />
                                                                                :
                                                                                <FormControlLabel
                                                                                    style={{ marginLeft: 2 }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={this.state.checked[index]}
                                                                                            onClick={() => this.handleChangeStatus(item.etapa, item.id, index, this.state.checkedTrue[index])}
                                                                                            color="primary"
                                                                                            style={{marginTop: '0%'}}
                                                                                        />
                                                                                    }
                                                                                    label="Finalizar"
                                                                                />}
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        null
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                    }
                                </div>
                            </>
                            : <BarLoader
                                css={override}
                                sizeUnit={'px'}
                                size={60}
                                color={'#123b7a'}
                                loading={this.state.loading}
                            />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => self.close()}>Fechar</Button>
                        <Button variant="primary" onClick={() => this.editarDados()} >Editar</Button>
                    </Modal.Footer>
                </Modal>
            </div >
        )
    }
}