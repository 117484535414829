import React, { Component } from 'react'
import {
    Modal,
    Button,
} from 'react-bootstrap';
import api from '../../../services/api';

export default class ModalDeletar extends Component {

    async deletarUsuario(){
        await api.post("/config/deleteUsuario",{
            id_usuario: this.props.deletar
        })
        this.props.close();
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.props.open}
                    onHide={() => this.props.close()}
                    size="sm"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>Alerta!</Modal.Header>
                    <Modal.Body
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ marginTop: 50, marginBottom: 50 }}>
                            <p>Deseja deletar este usuário?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.close()} variant="primary">Cancelar</Button>
                        <Button onClick={() => this.deletarUsuario()} variant="primary">Sim</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
