import FormInputs from 'components/FormInputs/FormInputs'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { clienteService } from 'services/clientes'

const ModalCadastro = ({ open, onClose, clienteId }) => {
  const [form, setForm] = useState({
    cod_cliente:null,
    nome: null,
    cidade: null,
    estado: null
  })

  const onChange = useCallback(
    (event) => {
      const { value, name } = event.target
      form[name] = value
      setForm({ ...form })
    },
    [form]
  )

  const onSubmit = useCallback(async () => {
    const body = {
      cod_cliente: form.cod_cliente,
      nome: form.nome,
      cidade: form.cidade,
      estado: form.estado
    }
    try {
      if (clienteId) {
        await clienteService.put(clienteId, body)
      } else {
        await clienteService.post(body)
      }
      onClose(true)
    } catch (error) {
      alert(error.response.data.message)
    }
  }, [form])

  const onInit = useCallback(async () => {
    if (clienteId) {
      try {
        const response = await clienteService.getById(clienteId)
        setForm({
          cod_cliente: response.data.cod_cliente,
          nome: response.data.nome,
          cidade: response.data.cidade,
          estado: response.data.estado
        })
      } catch (error) {}
    }
  }, [clienteId])

  useEffect(() => {
    onInit()
  }, [])

  return (
    <Modal show={open} onHide={() => onClose(false)} className="ModalGraficoRelatorio"
    backdrop="static"
    keyboard={false}>
      <Modal.Header closeButton>Cadastro do Cliente</Modal.Header>
      <Modal.Body>
        <FormInputs
          ncols={['col-md-6', 'col-md-6']}
          properties={[
            {
              label: 'Código do Cliente',
              name: 'cod_cliente',
              type: 'number',
              bsClass: 'form-control',
              onChange: (event) => onChange(event),
              value: form.cod_cliente
            },
            {
              label: 'Nome',
              name: 'nome',
              type: 'text',
              bsClass: 'form-control',
              onChange: (event) => onChange(event),
              value: form.nome
            }
          ]}
        />
        <FormInputs
          ncols={['col-md-6', 'col-md-6']}
          properties={[
            {
              label: 'Cidade',
              name: 'cidade',
              type: 'text',
              bsClass: 'form-control',
              onChange: (event) => onChange(event),
              value: form.cidade
            },
            {
              label: 'Estado',
              name: 'estado',
              type: 'text',
              bsClass: 'form-control',
              onChange: (event) => onChange(event),
              value: form.estado
            }
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose(false)} variant="primary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} variant="primary">
          {clienteId ? 'Editar' : 'Salvar'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
ModalCadastro.defaultProps = {
  clienteId: 'a'
}
export default ModalCadastro
