import React from "react";
import {
    Navbar, NavItem, Nav, NavDropdown, MenuItem
} from 'react-bootstrap';

const Header = ({ onAdd }) => (
    <Navbar fluid>
    <Navbar.Header>
        <Navbar.Brand>
            <a href="#pablo" style={{textTransform: 'uppercase'}}>Clientes</a>
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => {}} />
    </Navbar.Header>
    <Navbar.Collapse>

        <Nav pullRight>
            <NavDropdown
                eventKey={2}
                title="Ações"
                id="basic-nav-dropdown-right"
            >
                <MenuItem
                    eventKey={2.1}
                    onClick={() => onAdd(true)}
                >
                    Adicionar Cliente
                </MenuItem>
            </NavDropdown>
            <NavItem eventKey={3} href="#" >
                Sair
            </NavItem>
        </Nav>
    </Navbar.Collapse>
</Navbar>
)
export default Header